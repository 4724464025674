<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="flex justify-center mt-[20vh]">
    <div class="w-[800px]">
      <div class="bg-search-title-icon bg-contain h-[56px] w-[112px] mb-[16px]"></div>
      <div class="text-[#2d2d2d] text-[36px] leading-[44px] font-semibold select-none mb-[24px]">你好，{{ userStore.user.userName }}<br/>请问有什么可以帮助你？</div>
      <SearchInput class="mb-[32px]" @search="handleSearch"/>
      <div class="flex items-center mb-[18px]">
        <div class="bg-help-icon bg-contain  h-[24px] w-[24px] mr-[8px]"></div>
        <div class="text-fontgy1 text-[16px] font-semibold">发现好问题</div>
        <div class="flex items-center text-fontgy3 text-[14px] cursor-pointer ml-[16px]"
          @click="() => { page_number = total > 5 * page_number ? page_number + 1 : 1; GetCommonQuestion()}">
          <t-icon name="refresh" class="mr-[8px]"/>换一换
        </div>
      </div>
      <div class="flex flex-wrap">
        <div v-for="(item,index) in commonQuestion" :key="index" @click="clickCommonQuestion(item)"
        class="hover:shadow-search-focus transition-all rounded-[8px] leading-[26px] text-[14px] bg-white mb-[12px] mr-[12px] h-[42px] py-[8px] px-[16px] cursor-pointer">
        {{item.question}}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SearchInput from '@/components/SearchInput.vue';
import { useUserStore } from '@/stores/user';
import { onMounted,ref } from 'vue';
import { getCommonQuestion } from '@/network/requests';
import { reportData,GetWebGlobalSessionId } from '@/utils/utils'
import type { commonQues } from './type';
import { useRouter } from 'vue-router';

const userStore = useUserStore();
const page_number = ref(1);
const total = ref(-1)
const commonQuestion = ref<commonQues[]>([]);
const router = useRouter();
  
const GetCommonQuestion = () => {
  getCommonQuestion({pageNumber: page_number.value, pageSize: 5, projId: userStore.user.projId}).then((res) => {
    if (!res.code && res.data) {
      commonQuestion.value = res.data.questions || [];
      total.value = res.data.total || 0;
    } else {
      commonQuestion.value = [];
      total.value=0
    }
  })
}

const handleSearch = (keyword) => {
  router.push({ name: 'searchResult', query: {keyword,from:'home'}})
}

const clickCommonQuestion = async (ques: commonQues) => {
  window.open(ques.url)
  reportData( [
    { key: 'event_type', value: 'search_home_click_common_question' },
    { key: 'event_content', value: '搜索首页-点击常见问题' },
    { key: 'question', value: ques.question },
  ])
}

onMounted(() => { 
  GetCommonQuestion()
  GetWebGlobalSessionId(userStore.user.projId)
})
</script>