<!-- eslint-disable vue/multi-word-component-names -->
<template>
 <div class="flex items-start cursor-pointer hover:bg-[#F2F7FF] py-[16px]" @click="openArticle">
  <div class="bg-file-icon bg-contain w-[24px] h-[24px] mr-[8px] shrink-0"></div>
  <div class="text-[#000000e6] leading-[22px] text-[14px] w-[708px]">
    <div class="overflow-hidden text-ellipsis whitespace-nowrap font-semibold height-light" v-html="article.title"></div>
    <div class="line-clamp-2 text-ellipsis break-all mt-[4px] mb-[12px] height-light" v-html="article.fragment"></div>
    <div class="text-[#00000066] text-[12px] leading-[20px]">
      <span class="mr-[24px]">更新时间：{{article.modify_time||article.create_time}}</span>
      <span class="mr-[24px]">{{article.count_comment}}次评论</span>
      <span>{{article.count_view}}次浏览</span>
    </div>
  </div>
</div>
</template>

<script lang="ts" setup>
import type { articleInfo } from './type';
import { reportData } from '@/utils/utils'

const props = defineProps<{
  keyword: string,
  article: articleInfo,
}>();

const openArticle = async () => {
  window.open(props.article.href);
  reportData( [
    { key: 'event_type', value: 'search_iwiki_result_click' },
    { key: 'event_content', value: '搜索结果页-点击iwiki搜索结果' },
    { key: 'question', value: props.keyword },
    { key: 'answer', value: props.article.title },
  ])
}

</script>
<style>
.height-light span{
  color: #0052D9!important;
}
</style>