import { ref, computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { LoginRsp_Data } from '@/gen/proxy_pb';
import { PartialMessage } from '@bufbuild/protobuf';
import RpcService from '@/network/services';
import { useChatStore } from './chat';

type User = PartialMessage<LoginRsp_Data>;

export const useUserStore = defineStore('user', () => {
  const { chatInstance } = storeToRefs(useChatStore());

  // State
  const userRef = ref<User>({});

  // Getters
  const user = computed(() => userRef.value)

  // Actions
  /**
   * Login and return user info
   * @param code 
   * @returns User
   */
  async function login(code: string): Promise<User> {
    const rsp = await RpcService.login({ code });
    userRef.value = rsp.data;

    return user.value;
  }
  /**
   * Logout both tai and TUIChat
   */
  async function logout() {
    if (chatInstance.value) await chatInstance.value.logout();
    logoutTai();
  }

  /**
   * Logout tai only
   */
  async function logoutTai() {
    const rsp = await RpcService.logout({});
    userRef.value = {};
    window.location.replace(rsp.data.url);
  }

  return { user, login, logout, logoutTai }
})
