<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <RateComp ref="rateCompRef" :handleRejectTicket="handleRejectTicket" :handleResolveTicket="handleResolveTicket"></RateComp>
</template>
<script lang="ts" setup>
const initRuleConfig = {
  '1': { options: [] },
  '2': { options: [] },
  '3': { options: [] },
  '4': { options: [] },
  '5': { options: [] },
};

import RateComp from '@/components/Rate/index.vue';
import { JSONToObject } from '@/TUIKit/utils';
import { defineProps, computed, watch, ref } from 'vue';
import { IMessageModel } from '@tencentcloud/chat-uikit-engine';
import { PartialMessage } from '@bufbuild/protobuf';
import { GetLinkageRuleConfigReq } from '@/gen/product_without_validate_pb';
import { MessagePlugin } from 'tdesign-vue-next';
import { getLinkageRules, getTicketDetail, doAction, queryImActionInfo, uploadImActionInfo } from '@/network/requests';
import { contentFromString, contentJSONToString } from '@/components/richText/utils';

interface RateMsgPayload {
  ticket_id: number,
  proj_id: number,
  prod_id: number,
  ticket_stauts: string,
  is_auto_settle: boolean,
}

interface IProps {
  message: IMessageModel
}

const props = defineProps<IProps>()
const payload = computed(() => JSONToObject(props.message?.payload?.data) as RateMsgPayload)

const ruleConfig = ref(JSON.parse(JSON.stringify(initRuleConfig)));
const ticketStatus = ref('');
const ticketId = ref<number | null>();
const errorMsg = ref('');

const resolveFormData = ref<{ rate: number | null, tags: string[], reason: string }>({
  rate: null,
  tags: [],
  reason: ''
});
const rejectFormData = ref<{ reason: string }>({
  reason: ''
});

const rateCompRef = ref();
const init = async (cfg: PartialMessage<GetLinkageRuleConfigReq> & { msgId: string }) => {
  if (!payload.value.ticket_id || !payload.value.proj_id || !cfg.msgId) {
    MessagePlugin.error('满意度评价组件缺少payload');
    errorMsg.value = '满意度评价组件缺少payload';
    rateCompRef.value.init({
      errorMsg: errorMsg.value
    })
  }

  ticketId.value = payload.value.ticket_id;

  try {
    const queryImActionInfoRsp = await queryImActionInfo({
      msgId: [cfg.msgId],
      projId: cfg.projId
    })
    // if (queryImActionInfoRsp.data.length === 0) {
    //   uploadImActionInfo({
    //     msg_id: props.message.ID,
    //     projId: payload.value.proj_id,
    //     ticketId: payload.value.ticket_id,
    //     action: 'pre_settlement',
    //     ticketType: 'event',
    //   });
    // }
    const imAction = queryImActionInfoRsp.data[0];
    if (imAction && imAction.msg_id === cfg.msgId && imAction.post_body && JSON.parse(imAction.post_body).action_fields && JSON.parse(imAction.post_body).action !== 'pre_settlement') {
      try {
        const imActionObj = JSON.parse(imAction.post_body);

        const actionFields = JSON.parse(imActionObj?.action_fields);
        if (imActionObj.is_auto) {
          rateCompRef.value.init({
            errorMsg: errorMsg.value,
            settlementData: resolveFormData.value,
            ticketStatus: 'auto_settlement',
            ticketId: ticketId.value,
          })
        } else if (imActionObj.action === 'settle_event' || imActionObj.action === 'settlement') {
          resolveFormData.value = {
            rate: actionFields.satisfaction_score,
            tags: actionFields.satisfaction_tag_self_define,
            reason: contentJSONToString(actionFields.satisfaction_content)
          };
          rateCompRef.value.init({
            errorMsg: errorMsg.value,
            settlementData: resolveFormData.value,
            ticketStatus: 'settlement',
            ticketId: ticketId.value,
          })
          return;
        } else if (imActionObj.action === 'reject_settle_event') {
          rejectFormData.value = {
            reason: contentJSONToString(actionFields.reply_description)
          };
          rateCompRef.value.init({
            errorMsg: errorMsg.value,
            settlementData: rejectFormData.value,
            ticketStatus: 'rejected',
            ticketId: ticketId.value,
          })
          return;
        } else {
          rateCompRef.value.init({
            errorMsg: errorMsg.value,
            settlementData: resolveFormData.value,
            ticketStatus: actionFields.ticket_status,
            ticketId: ticketId.value,
          })
          return;
        }
      } catch (error) {
        console.log('error', error);
        rateCompRef.value.init({
          errorMsg: 'IM ACTION 记录错误',
          settlementData: resolveFormData.value,
          ticketStatus: ticketStatus.value,
          ticketId: ticketId.value,
        })
        return;
      }
    } else {
      try {
        const ticketDetailRsp = await getTicketDetail({ ticketId: payload.value.ticket_id, projId: payload.value.proj_id });
        if (ticketDetailRsp.code !== 0) errorMsg.value = '获取工单信息失败';
        ticketStatus.value = ticketDetailRsp.status;
        ticketId.value = payload.value.ticket_id;
        const ticketData = JSON.parse(ticketDetailRsp.data);

        // if (ticketStatus.value === 'rejected' || ticketStatus.value === 'reject_settle') {
        //   rateCompRef.value.init({
        //     errorMsg: errorMsg.value,
        //     settlementData: resolveFormData.value,
        //     ticketStatus: ticketStatus.value,
        //     ticketId: ticketId.value,
        //   })
        //   return;
        // }

        // 只有申请结单状态(pre_settlement)才展示评价
        if (ticketStatus.value === 'pre_settlement') {
          const rsp = await getLinkageRules(cfg)

          const ruleMapInit = {};
          const ruleConfigInit = JSON.parse(JSON.stringify(initRuleConfig));
          const fulfillRule = rsp.data;
          
          if (fulfillRule) fulfillRule.rules.forEach(r => {
            r.conditions.condition.forEach(cond => {
              if (cond.source === 'satisfaction_score' && cond.operation === 'equal') {
                ruleMapInit[`${r.id}`] = r;
                const value = parseInt(cond.value);
                ruleConfigInit[value] = {
                  ...r.fulfill,
                  id: r.id
                };
                ruleConfigInit[value].options = r.fulfill.options.map(o => {
                  return {
                    satisfactionGroup: o.satisfaction_group,
                    satisfactionTags: o.satisfaction_tags.map(k => {
                      return {
                        label: k,
                        value: parseInt(`${new Date().getTime()}${Math.random() * 999}`),
                        chosen: false,
                      };
                    })
                  };
                });
              }
            });
          });
          ruleConfig.value = ruleConfigInit;

          rateCompRef.value.init({
            errorMsg: errorMsg.value,
            settlementData: resolveFormData.value,
            ticketStatus: ticketStatus.value,
            ticketId: ticketId.value,
            ruleConfig: ruleConfig.value,
          })
          uploadImActionInfo({
            msg_id: props.message.ID,
            projId: payload.value.proj_id,
            ticketId: payload.value.ticket_id,
            action: 'pre_settlement',
            ticketType: 'event',
          });
        } else if (ticketStatus.value === 'settlement' && ticketData instanceof Array) {
          const ticketInfo = ticketData.find(i => i.category_name === '工单信息');
          const basicInfo = ticketData.find(i => i.category_name === '基本信息');
          const categoryData = ticketInfo.category_data;
          const basicCategoryData = basicInfo?.category_data;
          if (categoryData && categoryData instanceof Array && basicCategoryData && basicCategoryData instanceof Array) {
            const rate = categoryData.find(i => i.eng_name === 'satisfaction_score').value;
            const tags = categoryData.find(i => i.eng_name === 'satisfaction_tag_self_define').value || [];
            const reason = contentJSONToString(categoryData.find(i => i.eng_name === 'satisfaction_content').value);
            const isAutoValue = basicCategoryData.find(i => i.eng_name === 'auto_settle').value;
            const isAuto = isAutoValue instanceof Array && isAutoValue[0] === '是';
            resolveFormData.value = {
              rate,
              tags,
              reason,
            };
            rateCompRef.value.init({
              errorMsg: errorMsg.value,
              settlementData: resolveFormData.value,
              ticketStatus: ticketStatus.value,
              ticketId: ticketId.value,
              // ruleConfig: ruleConfig.value,
            });
            uploadImActionInfo({
              msg_id: props.message.ID,
              projId: payload.value.proj_id,
              ticketId: payload.value.ticket_id,
              action: 'settlement',
              ticketType: 'event',
              actionFields: JSON.stringify({
                satisfaction_content: JSON.stringify(contentFromString(resolveFormData.value.reason)),
                satisfaction_score: resolveFormData.value.rate,
                satisfaction_tag_self_define: resolveFormData.value.tags,
              }),
              is_auto: isAuto,
            });
          }
        } else if (ticketStatus.value === 'reject_settle') {
          const ticketInfo = ticketData.find(i => i.category_name === '工单信息');
          const categoryData = ticketInfo.category_data;
          if (categoryData && categoryData instanceof Array) {
            const reason = contentJSONToString(categoryData.find(i => i.eng_name === 'reply_description').value);
            rejectFormData.value = {
              reason,
            };
            rateCompRef.value.init({
              errorMsg: errorMsg.value,
              settlementData: rejectFormData.value,
              ticketStatus: ticketStatus.value,
              ticketId: ticketId.value,
              // ruleConfig: ruleConfig.value,
            });
            uploadImActionInfo({
              msg_id: props.message.ID,
              projId: payload.value.proj_id,
              ticketId: payload.value.ticket_id,
              action: 'reject_settle',
              ticketType: 'event',
              actionFields: JSON.stringify({
                reply_description: JSON.stringify(contentFromString(rejectFormData.value.reason)),
              })
            });
          }
        } else {
          errorMsg.value = '工单已流转到其他状态，IT Service继续为您服务！'
          rateCompRef.value.init({
            errorMsg: errorMsg.value,
            settlementData: resolveFormData.value,
            ticketStatus: ticketStatus.value,
            ticketId: ticketId.value,
          })
          return;
        }
      } catch (error) {
        console.log('errorr', error)
        rateCompRef.value.init({
          errorMsg: "网络错误，请重试",
          settlementData: resolveFormData.value,
          ticketStatus: ticketStatus.value,
          ticketId: ticketId.value,
          ruleConfig: ruleConfig.value,
        })
      }
    }
  } catch (error) {
    console.log('error', error);
    MessagePlugin.error('满意度存储错误');
    errorMsg.value = '网络错误，请稍后再试';
    rateCompRef.value.init({
      errorMsg: errorMsg.value
    })
  }
}

watch(() => payload.value, (val, oldVal) => {
  if (val) {
    init({
      msgId: props.message.ID,
      projId: val.proj_id,
      prodId: val.prod_id,
      engName: 'satisfaction_tag_self_define',
      ticketType: 'event',
    });
  }
}, {
  immediate: true,
  deep: true
})

const handleResolveTicket = async (evaluationRichText: string, satisfactionScore: number, satisfactionTagSelfDefine: string[]) => {
  const doActionRsp = await doAction({
    projId: payload.value.proj_id,
    ticketId: payload.value.ticket_id,
    action: 'settle_event',
    ticketType: 'event',
    actionFields: JSON.stringify({
      satisfaction_content: evaluationRichText,
      satisfaction_score: satisfactionScore,
      satisfaction_tag_self_define: satisfactionTagSelfDefine
    })
  })
  if (doActionRsp.code === 0) {
    uploadImActionInfo({
      msg_id: props.message.ID,
      projId: payload.value.proj_id,
      ticketId: payload.value.ticket_id,
      action: 'settle_event',
      ticketType: 'event',
      actionFields: JSON.stringify({
        satisfaction_content: evaluationRichText,
        satisfaction_score: satisfactionScore,
        satisfaction_tag_self_define: satisfactionTagSelfDefine
      })
    })
  }
  return doActionRsp;
};

const handleRejectTicket = async (contentRichText: string) => {
  const doActionRsp = await doAction({
    projId: payload.value.proj_id,
    ticketId: payload.value.ticket_id,
    action: 'reject_settle_event',
    ticketType: 'event',
    actionFields: JSON.stringify({
      reply_description: contentRichText,
    }),
  })
  if (doActionRsp.code === 0) {
    uploadImActionInfo({
      msg_id: props.message.ID,
      projId: payload.value.proj_id,
      ticketId: payload.value.ticket_id,
      action: 'reject_settle_event',
      ticketType: 'event',
      actionFields: JSON.stringify({
        reply_description: contentRichText,
      }),
    })
  }
  return doActionRsp;
};
</script>