<script setup lang="ts">
import ChatPanel from '@/components/ChatPanel.vue';
import { onMounted, ref } from 'vue';

const chatPanel = ref();
onMounted(() => {
  chatPanel.value.show();
});
</script>

<template>
  <div class="w-full flex-1 min-h-0">
    <ChatPanel ref="chatPanel"></ChatPanel>
  </div>
</template>