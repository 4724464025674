// protobuf 文件参考:
// - https://developers.google.com/protocol-buffers/

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file product_without_validate.proto (package trpc.teg_devops.servicedesk_product, syntax proto3)
/* eslint-disable */
// @ts-nocheck

// package分成三级 trpc.app.server，app是一个业务项目分类，server是具体的进程服务名

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProjListReq
 */
export class ProjListReq extends Message<ProjListReq> {
  constructor(data?: PartialMessage<ProjListReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProjListReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProjListReq {
    return new ProjListReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProjListReq {
    return new ProjListReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProjListReq {
    return new ProjListReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProjListReq | PlainMessage<ProjListReq> | undefined, b: ProjListReq | PlainMessage<ProjListReq> | undefined): boolean {
    return proto3.util.equals(ProjListReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProjListRsp
 */
export class ProjListRsp extends Message<ProjListRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.ProjListRsp.Data data = 3;
   */
  data?: ProjListRsp_Data;

  constructor(data?: PartialMessage<ProjListRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProjListRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: ProjListRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProjListRsp {
    return new ProjListRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProjListRsp {
    return new ProjListRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProjListRsp {
    return new ProjListRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ProjListRsp | PlainMessage<ProjListRsp> | undefined, b: ProjListRsp | PlainMessage<ProjListRsp> | undefined): boolean {
    return proto3.util.equals(ProjListRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProjListRsp.Item
 */
export class ProjListRsp_Item extends Message<ProjListRsp_Item> {
  /**
   * 项目ID
   *
   * @generated from field: uint32 id = 1;
   */
  id = 0;

  /**
   * 项目名称
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * 是否有该项目的权限
   *
   * @generated from field: bool has_permission = 3;
   */
  hasPermission = false;

  /**
   * @generated from field: string workspace_id = 4;
   */
  workspaceId = "";

  /**
   * 事件工单度量看板 url
   *
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.ProjListRsp.Item.TicketMetricUrl ticket_metric = 5;
   */
  ticketMetric: ProjListRsp_Item_TicketMetricUrl[] = [];

  constructor(data?: PartialMessage<ProjListRsp_Item>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProjListRsp.Item";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "has_permission", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "workspace_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "ticket_metric", kind: "message", T: ProjListRsp_Item_TicketMetricUrl, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProjListRsp_Item {
    return new ProjListRsp_Item().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProjListRsp_Item {
    return new ProjListRsp_Item().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProjListRsp_Item {
    return new ProjListRsp_Item().fromJsonString(jsonString, options);
  }

  static equals(a: ProjListRsp_Item | PlainMessage<ProjListRsp_Item> | undefined, b: ProjListRsp_Item | PlainMessage<ProjListRsp_Item> | undefined): boolean {
    return proto3.util.equals(ProjListRsp_Item, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProjListRsp.Item.TicketMetricUrl
 */
export class ProjListRsp_Item_TicketMetricUrl extends Message<ProjListRsp_Item_TicketMetricUrl> {
  /**
   * @generated from field: string label = 1;
   */
  label = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  constructor(data?: PartialMessage<ProjListRsp_Item_TicketMetricUrl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProjListRsp.Item.TicketMetricUrl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProjListRsp_Item_TicketMetricUrl {
    return new ProjListRsp_Item_TicketMetricUrl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProjListRsp_Item_TicketMetricUrl {
    return new ProjListRsp_Item_TicketMetricUrl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProjListRsp_Item_TicketMetricUrl {
    return new ProjListRsp_Item_TicketMetricUrl().fromJsonString(jsonString, options);
  }

  static equals(a: ProjListRsp_Item_TicketMetricUrl | PlainMessage<ProjListRsp_Item_TicketMetricUrl> | undefined, b: ProjListRsp_Item_TicketMetricUrl | PlainMessage<ProjListRsp_Item_TicketMetricUrl> | undefined): boolean {
    return proto3.util.equals(ProjListRsp_Item_TicketMetricUrl, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProjListRsp.Data
 */
export class ProjListRsp_Data extends Message<ProjListRsp_Data> {
  /**
   * @generated from field: int32 recent_proj = 1;
   */
  recentProj = 0;

  /**
   * 项目列表
   *
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.ProjListRsp.Item items = 2;
   */
  items: ProjListRsp_Item[] = [];

  constructor(data?: PartialMessage<ProjListRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProjListRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recent_proj", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "items", kind: "message", T: ProjListRsp_Item, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProjListRsp_Data {
    return new ProjListRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProjListRsp_Data {
    return new ProjListRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProjListRsp_Data {
    return new ProjListRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: ProjListRsp_Data | PlainMessage<ProjListRsp_Data> | undefined, b: ProjListRsp_Data | PlainMessage<ProjListRsp_Data> | undefined): boolean {
    return proto3.util.equals(ProjListRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.MostRecentProjReq
 */
export class MostRecentProjReq extends Message<MostRecentProjReq> {
  /**
   * @generated from field: int32 proj_id = 1;
   */
  projId = 0;

  constructor(data?: PartialMessage<MostRecentProjReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.MostRecentProjReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MostRecentProjReq {
    return new MostRecentProjReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MostRecentProjReq {
    return new MostRecentProjReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MostRecentProjReq {
    return new MostRecentProjReq().fromJsonString(jsonString, options);
  }

  static equals(a: MostRecentProjReq | PlainMessage<MostRecentProjReq> | undefined, b: MostRecentProjReq | PlainMessage<MostRecentProjReq> | undefined): boolean {
    return proto3.util.equals(MostRecentProjReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.MostRecentProjRsp
 */
export class MostRecentProjRsp extends Message<MostRecentProjRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<MostRecentProjRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.MostRecentProjRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MostRecentProjRsp {
    return new MostRecentProjRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MostRecentProjRsp {
    return new MostRecentProjRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MostRecentProjRsp {
    return new MostRecentProjRsp().fromJsonString(jsonString, options);
  }

  static equals(a: MostRecentProjRsp | PlainMessage<MostRecentProjRsp> | undefined, b: MostRecentProjRsp | PlainMessage<MostRecentProjRsp> | undefined): boolean {
    return proto3.util.equals(MostRecentProjRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductListReq
 */
export class ProductListReq extends Message<ProductListReq> {
  /**
   * 项目id
   *
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  constructor(data?: PartialMessage<ProductListReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductListReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductListReq {
    return new ProductListReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductListReq {
    return new ProductListReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductListReq {
    return new ProductListReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProductListReq | PlainMessage<ProductListReq> | undefined, b: ProductListReq | PlainMessage<ProductListReq> | undefined): boolean {
    return proto3.util.equals(ProductListReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductListRsp
 */
export class ProductListRsp extends Message<ProductListRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.ProductListRsp.Data data = 3;
   */
  data?: ProductListRsp_Data;

  constructor(data?: PartialMessage<ProductListRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductListRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: ProductListRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductListRsp {
    return new ProductListRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductListRsp {
    return new ProductListRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductListRsp {
    return new ProductListRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ProductListRsp | PlainMessage<ProductListRsp> | undefined, b: ProductListRsp | PlainMessage<ProductListRsp> | undefined): boolean {
    return proto3.util.equals(ProductListRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductListRsp.Data
 */
export class ProductListRsp_Data extends Message<ProductListRsp_Data> {
  /**
   * 总数
   *
   * @generated from field: uint32 total = 1;
   */
  total = 0;

  /**
   * 产品列表
   *
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.ProductListRsp.Item items = 2;
   */
  items: ProductListRsp_Item[] = [];

  constructor(data?: PartialMessage<ProductListRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductListRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "items", kind: "message", T: ProductListRsp_Item, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductListRsp_Data {
    return new ProductListRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductListRsp_Data {
    return new ProductListRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductListRsp_Data {
    return new ProductListRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: ProductListRsp_Data | PlainMessage<ProductListRsp_Data> | undefined, b: ProductListRsp_Data | PlainMessage<ProductListRsp_Data> | undefined): boolean {
    return proto3.util.equals(ProductListRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductListRsp.Item
 */
export class ProductListRsp_Item extends Message<ProductListRsp_Item> {
  /**
   * 产品ID
   *
   * @generated from field: uint32 id = 1;
   */
  id = 0;

  /**
   * 产品名称
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * 创建时间
   *
   * @generated from field: string create_at = 3;
   */
  createAt = "";

  /**
   * 创建人
   *
   * @generated from field: string creator = 4;
   */
  creator = "";

  constructor(data?: PartialMessage<ProductListRsp_Item>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductListRsp.Item";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "create_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "creator", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductListRsp_Item {
    return new ProductListRsp_Item().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductListRsp_Item {
    return new ProductListRsp_Item().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductListRsp_Item {
    return new ProductListRsp_Item().fromJsonString(jsonString, options);
  }

  static equals(a: ProductListRsp_Item | PlainMessage<ProductListRsp_Item> | undefined, b: ProductListRsp_Item | PlainMessage<ProductListRsp_Item> | undefined): boolean {
    return proto3.util.equals(ProductListRsp_Item, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserProfileReq
 */
export class UserProfileReq extends Message<UserProfileReq> {
  /**
   * 项目id
   *
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: string user_name = 2;
   */
  userName = "";

  constructor(data?: PartialMessage<UserProfileReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserProfileReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserProfileReq {
    return new UserProfileReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserProfileReq {
    return new UserProfileReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserProfileReq {
    return new UserProfileReq().fromJsonString(jsonString, options);
  }

  static equals(a: UserProfileReq | PlainMessage<UserProfileReq> | undefined, b: UserProfileReq | PlainMessage<UserProfileReq> | undefined): boolean {
    return proto3.util.equals(UserProfileReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductGetByProjReq
 */
export class ProductGetByProjReq extends Message<ProductGetByProjReq> {
  /**
   * 项目id
   *
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  constructor(data?: PartialMessage<ProductGetByProjReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductGetByProjReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductGetByProjReq {
    return new ProductGetByProjReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductGetByProjReq {
    return new ProductGetByProjReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductGetByProjReq {
    return new ProductGetByProjReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProductGetByProjReq | PlainMessage<ProductGetByProjReq> | undefined, b: ProductGetByProjReq | PlainMessage<ProductGetByProjReq> | undefined): boolean {
    return proto3.util.equals(ProductGetByProjReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductGetByProjRsp
 */
export class ProductGetByProjRsp extends Message<ProductGetByProjRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.ProductGetByProjRsp.Data data = 3;
   */
  data?: ProductGetByProjRsp_Data;

  constructor(data?: PartialMessage<ProductGetByProjRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductGetByProjRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: ProductGetByProjRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductGetByProjRsp {
    return new ProductGetByProjRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductGetByProjRsp {
    return new ProductGetByProjRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductGetByProjRsp {
    return new ProductGetByProjRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ProductGetByProjRsp | PlainMessage<ProductGetByProjRsp> | undefined, b: ProductGetByProjRsp | PlainMessage<ProductGetByProjRsp> | undefined): boolean {
    return proto3.util.equals(ProductGetByProjRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductGetByProjRsp.Data
 */
export class ProductGetByProjRsp_Data extends Message<ProductGetByProjRsp_Data> {
  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.ProductInfoV2 items = 3;
   */
  items: ProductInfoV2[] = [];

  constructor(data?: PartialMessage<ProductGetByProjRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductGetByProjRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "items", kind: "message", T: ProductInfoV2, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductGetByProjRsp_Data {
    return new ProductGetByProjRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductGetByProjRsp_Data {
    return new ProductGetByProjRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductGetByProjRsp_Data {
    return new ProductGetByProjRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: ProductGetByProjRsp_Data | PlainMessage<ProductGetByProjRsp_Data> | undefined, b: ProductGetByProjRsp_Data | PlainMessage<ProductGetByProjRsp_Data> | undefined): boolean {
    return proto3.util.equals(ProductGetByProjRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductInfo
 */
export class ProductInfo extends Message<ProductInfo> {
  /**
   * @generated from field: uint32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: uint32 parent_id = 2;
   */
  parentId = 0;

  /**
   * @generated from field: uint32 proj_id = 3;
   */
  projId = 0;

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * @generated from field: string cs = 5;
   */
  cs = "";

  /**
   * @generated from field: bool cs_enabled = 6;
   */
  csEnabled = false;

  /**
   * @generated from field: string om = 7;
   */
  om = "";

  /**
   * @generated from field: bool om_enabled = 8;
   */
  omEnabled = false;

  /**
   * @generated from field: string prod = 9;
   */
  prod = "";

  /**
   * @generated from field: bool prod_enabled = 10;
   */
  prodEnabled = false;

  /**
   * @generated from field: string rd = 11;
   */
  rd = "";

  /**
   * @generated from field: bool rd_enabled = 12;
   */
  rdEnabled = false;

  /**
   * @generated from field: string description = 13;
   */
  description = "";

  /**
   * @generated from field: string duty_admin = 14;
   */
  dutyAdmin = "";

  /**
   * @generated from field: string leader = 15;
   */
  leader = "";

  /**
   * @generated from field: string director = 16;
   */
  director = "";

  /**
   * @generated from field: string gm = 17;
   */
  gm = "";

  /**
   * @generated from field: string creator = 18;
   */
  creator = "";

  /**
   * @generated from field: string create_at = 19;
   */
  createAt = "";

  /**
   * @generated from field: string modifier = 20;
   */
  modifier = "";

  /**
   * @generated from field: string update_at = 21;
   */
  updateAt = "";

  /**
   * @generated from field: string status = 22;
   */
  status = "";

  /**
   * @generated from field: string supplement_template = 23;
   */
  supplementTemplate = "";

  /**
   * @generated from field: uint32 dept_id = 25;
   */
  deptId = 0;

  /**
   * @generated from field: string dept_name = 26;
   */
  deptName = "";

  /**
   * @generated from field: string helper_id = 27;
   */
  helperId = "";

  /**
   * @generated from field: string msg_helper_id = 28;
   */
  msgHelperId = "";

  /**
   * @generated from field: uint32 cs_prod_id = 29;
   */
  csProdId = 0;

  /**
   * @generated from field: bool auto_response_after_transfer = 30;
   */
  autoResponseAfterTransfer = false;

  /**
   * @generated from field: bool auto_create_ticket = 31;
   */
  autoCreateTicket = false;

  /**
   * @generated from field: string schedule_info = 32;
   */
  scheduleInfo = "";

  /**
   * @generated from field: bool auto_response_pending = 33;
   */
  autoResponsePending = false;

  constructor(data?: PartialMessage<ProductInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "parent_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "cs", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "cs_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "om", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "om_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "prod", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "prod_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "rd", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "rd_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "duty_admin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "leader", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "director", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "gm", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "creator", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "create_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "modifier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "update_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "supplement_template", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "dept_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 26, name: "dept_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "helper_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28, name: "msg_helper_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 29, name: "cs_prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 30, name: "auto_response_after_transfer", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 31, name: "auto_create_ticket", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 32, name: "schedule_info", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 33, name: "auto_response_pending", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductInfo {
    return new ProductInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductInfo {
    return new ProductInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductInfo {
    return new ProductInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ProductInfo | PlainMessage<ProductInfo> | undefined, b: ProductInfo | PlainMessage<ProductInfo> | undefined): boolean {
    return proto3.util.equals(ProductInfo, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProdDirInfo
 */
export class ProdDirInfo extends Message<ProdDirInfo> {
  /**
   * @generated from field: uint32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string tcloudproddir = 2;
   */
  tcloudproddir = "";

  constructor(data?: PartialMessage<ProdDirInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProdDirInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "tcloudproddir", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProdDirInfo {
    return new ProdDirInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProdDirInfo {
    return new ProdDirInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProdDirInfo {
    return new ProdDirInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ProdDirInfo | PlainMessage<ProdDirInfo> | undefined, b: ProdDirInfo | PlainMessage<ProdDirInfo> | undefined): boolean {
    return proto3.util.equals(ProdDirInfo, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.HelperStatusReq
 */
export class HelperStatusReq extends Message<HelperStatusReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  constructor(data?: PartialMessage<HelperStatusReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.HelperStatusReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HelperStatusReq {
    return new HelperStatusReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HelperStatusReq {
    return new HelperStatusReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HelperStatusReq {
    return new HelperStatusReq().fromJsonString(jsonString, options);
  }

  static equals(a: HelperStatusReq | PlainMessage<HelperStatusReq> | undefined, b: HelperStatusReq | PlainMessage<HelperStatusReq> | undefined): boolean {
    return proto3.util.equals(HelperStatusReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SetHelperStatusReq
 */
export class SetHelperStatusReq extends Message<SetHelperStatusReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: repeated string online_service_id = 2;
   */
  onlineServiceId: string[] = [];

  /**
   * @generated from field: repeated string offline_service_id = 3;
   */
  offlineServiceId: string[] = [];

  constructor(data?: PartialMessage<SetHelperStatusReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SetHelperStatusReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "online_service_id", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "offline_service_id", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetHelperStatusReq {
    return new SetHelperStatusReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetHelperStatusReq {
    return new SetHelperStatusReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetHelperStatusReq {
    return new SetHelperStatusReq().fromJsonString(jsonString, options);
  }

  static equals(a: SetHelperStatusReq | PlainMessage<SetHelperStatusReq> | undefined, b: SetHelperStatusReq | PlainMessage<SetHelperStatusReq> | undefined): boolean {
    return proto3.util.equals(SetHelperStatusReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SetHelperStatusRsp
 */
export class SetHelperStatusRsp extends Message<SetHelperStatusRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<SetHelperStatusRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SetHelperStatusRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetHelperStatusRsp {
    return new SetHelperStatusRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetHelperStatusRsp {
    return new SetHelperStatusRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetHelperStatusRsp {
    return new SetHelperStatusRsp().fromJsonString(jsonString, options);
  }

  static equals(a: SetHelperStatusRsp | PlainMessage<SetHelperStatusRsp> | undefined, b: SetHelperStatusRsp | PlainMessage<SetHelperStatusRsp> | undefined): boolean {
    return proto3.util.equals(SetHelperStatusRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.HelperStatusRsp
 */
export class HelperStatusRsp extends Message<HelperStatusRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.HelperStatusRsp.Data data = 3;
   */
  data: HelperStatusRsp_Data[] = [];

  constructor(data?: PartialMessage<HelperStatusRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.HelperStatusRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: HelperStatusRsp_Data, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HelperStatusRsp {
    return new HelperStatusRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HelperStatusRsp {
    return new HelperStatusRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HelperStatusRsp {
    return new HelperStatusRsp().fromJsonString(jsonString, options);
  }

  static equals(a: HelperStatusRsp | PlainMessage<HelperStatusRsp> | undefined, b: HelperStatusRsp | PlainMessage<HelperStatusRsp> | undefined): boolean {
    return proto3.util.equals(HelperStatusRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.HelperStatusRsp.Data
 */
export class HelperStatusRsp_Data extends Message<HelperStatusRsp_Data> {
  /**
   * @generated from field: string service_id = 1;
   */
  serviceId = "";

  /**
   * @generated from field: string helper_name = 2;
   */
  helperName = "";

  /**
   * @generated from field: string status = 3;
   */
  status = "";

  constructor(data?: PartialMessage<HelperStatusRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.HelperStatusRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "service_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "helper_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HelperStatusRsp_Data {
    return new HelperStatusRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HelperStatusRsp_Data {
    return new HelperStatusRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HelperStatusRsp_Data {
    return new HelperStatusRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: HelperStatusRsp_Data | PlainMessage<HelperStatusRsp_Data> | undefined, b: HelperStatusRsp_Data | PlainMessage<HelperStatusRsp_Data> | undefined): boolean {
    return proto3.util.equals(HelperStatusRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserLastOnlineHelperReq
 */
export class UserLastOnlineHelperReq extends Message<UserLastOnlineHelperReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  constructor(data?: PartialMessage<UserLastOnlineHelperReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserLastOnlineHelperReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserLastOnlineHelperReq {
    return new UserLastOnlineHelperReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserLastOnlineHelperReq {
    return new UserLastOnlineHelperReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserLastOnlineHelperReq {
    return new UserLastOnlineHelperReq().fromJsonString(jsonString, options);
  }

  static equals(a: UserLastOnlineHelperReq | PlainMessage<UserLastOnlineHelperReq> | undefined, b: UserLastOnlineHelperReq | PlainMessage<UserLastOnlineHelperReq> | undefined): boolean {
    return proto3.util.equals(UserLastOnlineHelperReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserLastOnlineHelperRsp
 */
export class UserLastOnlineHelperRsp extends Message<UserLastOnlineHelperRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated string service_id = 3;
   */
  serviceId: string[] = [];

  constructor(data?: PartialMessage<UserLastOnlineHelperRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserLastOnlineHelperRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "service_id", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserLastOnlineHelperRsp {
    return new UserLastOnlineHelperRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserLastOnlineHelperRsp {
    return new UserLastOnlineHelperRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserLastOnlineHelperRsp {
    return new UserLastOnlineHelperRsp().fromJsonString(jsonString, options);
  }

  static equals(a: UserLastOnlineHelperRsp | PlainMessage<UserLastOnlineHelperRsp> | undefined, b: UserLastOnlineHelperRsp | PlainMessage<UserLastOnlineHelperRsp> | undefined): boolean {
    return proto3.util.equals(UserLastOnlineHelperRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductInfoV2
 */
export class ProductInfoV2 extends Message<ProductInfoV2> {
  /**
   * @generated from field: uint32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: uint32 parent_id = 2;
   */
  parentId = 0;

  /**
   * @generated from field: uint32 proj_id = 3;
   */
  projId = 0;

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * @generated from field: string cs = 5;
   */
  cs = "";

  /**
   * @generated from field: bool cs_enabled = 6;
   */
  csEnabled = false;

  /**
   * @generated from field: string om = 7;
   */
  om = "";

  /**
   * @generated from field: bool om_enabled = 8;
   */
  omEnabled = false;

  /**
   * @generated from field: string prod = 9;
   */
  prod = "";

  /**
   * @generated from field: bool prod_enabled = 10;
   */
  prodEnabled = false;

  /**
   * @generated from field: string rd = 11;
   */
  rd = "";

  /**
   * @generated from field: bool rd_enabled = 12;
   */
  rdEnabled = false;

  /**
   * @generated from field: string description = 13;
   */
  description = "";

  /**
   * @generated from field: string duty_admin = 14;
   */
  dutyAdmin = "";

  /**
   * @generated from field: string leader = 15;
   */
  leader = "";

  /**
   * @generated from field: string director = 16;
   */
  director = "";

  /**
   * @generated from field: string gm = 17;
   */
  gm = "";

  /**
   * @generated from field: string creator = 18;
   */
  creator = "";

  /**
   * @generated from field: string create_at = 19;
   */
  createAt = "";

  /**
   * @generated from field: string modifier = 20;
   */
  modifier = "";

  /**
   * @generated from field: string update_at = 21;
   */
  updateAt = "";

  /**
   * @generated from field: string status = 22;
   */
  status = "";

  /**
   * @generated from field: string supplement_template = 23;
   */
  supplementTemplate = "";

  /**
   * @generated from field: uint32 dept_id = 25;
   */
  deptId = 0;

  /**
   * @generated from field: string dept_name = 26;
   */
  deptName = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.ProdDirInfo prod_tcloudproddirs = 27;
   */
  prodTcloudproddirs: ProdDirInfo[] = [];

  /**
   * @generated from field: string helper_id = 28;
   */
  helperId = "";

  /**
   * @generated from field: string msg_helper_id = 29;
   */
  msgHelperId = "";

  /**
   * @generated from field: uint32 cs_prod_id = 30;
   */
  csProdId = 0;

  /**
   * @generated from field: bool auto_create_ticket = 31;
   */
  autoCreateTicket = false;

  /**
   * @generated from field: string schedule_info = 32;
   */
  scheduleInfo = "";

  /**
   * @generated from field: bool auto_response_pending = 33;
   */
  autoResponsePending = false;

  /**
   * @generated from field: bool auto_transfer = 34;
   */
  autoTransfer = false;

  /**
   * @generated from field: bool auto_response_after_transfer = 35;
   */
  autoResponseAfterTransfer = false;

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.Helper helper = 36;
   */
  helper?: Helper;

  /**
   * @generated from field: string msg_helper = 37;
   */
  msgHelper = "";

  /**
   * @generated from field: string helper_operate = 38;
   */
  helperOperate = "";

  /**
   * @generated from field: repeated string nickname = 39;
   */
  nickname: string[] = [];

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.RoleInfo role_list = 40;
   */
  roleList: RoleInfo[] = [];

  constructor(data?: PartialMessage<ProductInfoV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductInfoV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "parent_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "cs", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "cs_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "om", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "om_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "prod", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "prod_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "rd", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "rd_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "duty_admin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "leader", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "director", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "gm", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "creator", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "create_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "modifier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "update_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "supplement_template", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "dept_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 26, name: "dept_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "prod_tcloudproddirs", kind: "message", T: ProdDirInfo, repeated: true },
    { no: 28, name: "helper_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 29, name: "msg_helper_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 30, name: "cs_prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 31, name: "auto_create_ticket", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 32, name: "schedule_info", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 33, name: "auto_response_pending", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 34, name: "auto_transfer", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 35, name: "auto_response_after_transfer", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 36, name: "helper", kind: "message", T: Helper },
    { no: 37, name: "msg_helper", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 38, name: "helper_operate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 39, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 40, name: "role_list", kind: "message", T: RoleInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductInfoV2 {
    return new ProductInfoV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductInfoV2 {
    return new ProductInfoV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductInfoV2 {
    return new ProductInfoV2().fromJsonString(jsonString, options);
  }

  static equals(a: ProductInfoV2 | PlainMessage<ProductInfoV2> | undefined, b: ProductInfoV2 | PlainMessage<ProductInfoV2> | undefined): boolean {
    return proto3.util.equals(ProductInfoV2, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.RoleInfo
 */
export class RoleInfo extends Message<RoleInfo> {
  /**
   * @generated from field: string role_code = 1;
   */
  roleCode = "";

  /**
   * @generated from field: string role_name = 2;
   */
  roleName = "";

  /**
   * @generated from field: uint32 role_type = 3;
   */
  roleType = 0;

  /**
   * @generated from field: string position = 4;
   */
  position = "";

  /**
   * @generated from field: bool enabled = 5;
   */
  enabled = false;

  /**
   * @generated from field: bool valid = 6;
   */
  valid = false;

  /**
   * @generated from field: string group_code = 7;
   */
  groupCode = "";

  constructor(data?: PartialMessage<RoleInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.RoleInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role_type", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "position", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "group_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RoleInfo {
    return new RoleInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RoleInfo {
    return new RoleInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RoleInfo {
    return new RoleInfo().fromJsonString(jsonString, options);
  }

  static equals(a: RoleInfo | PlainMessage<RoleInfo> | undefined, b: RoleInfo | PlainMessage<RoleInfo> | undefined): boolean {
    return proto3.util.equals(RoleInfo, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.Helper
 */
export class Helper extends Message<Helper> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string cname = 2;
   */
  cname = "";

  /**
   * @generated from field: string corpid = 3;
   */
  corpid = "";

  /**
   * @generated from field: string corpsecret = 4;
   */
  corpsecret = "";

  /**
   * @generated from field: string token = 5;
   */
  token = "";

  /**
   * @generated from field: string service_id = 6;
   */
  serviceId = "";

  /**
   * @generated from field: string encodingaeskey = 7;
   */
  encodingaeskey = "";

  /**
   * @generated from field: string uin = 8;
   */
  uin = "";

  constructor(data?: PartialMessage<Helper>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.Helper";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "corpid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "corpsecret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "service_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "encodingaeskey", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "uin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Helper {
    return new Helper().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Helper {
    return new Helper().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Helper {
    return new Helper().fromJsonString(jsonString, options);
  }

  static equals(a: Helper | PlainMessage<Helper> | undefined, b: Helper | PlainMessage<Helper> | undefined): boolean {
    return proto3.util.equals(Helper, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserProfileRsp
 */
export class UserProfileRsp extends Message<UserProfileRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.UserProfileRsp.Data data = 3;
   */
  data?: UserProfileRsp_Data;

  constructor(data?: PartialMessage<UserProfileRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserProfileRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: UserProfileRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserProfileRsp {
    return new UserProfileRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserProfileRsp {
    return new UserProfileRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserProfileRsp {
    return new UserProfileRsp().fromJsonString(jsonString, options);
  }

  static equals(a: UserProfileRsp | PlainMessage<UserProfileRsp> | undefined, b: UserProfileRsp | PlainMessage<UserProfileRsp> | undefined): boolean {
    return proto3.util.equals(UserProfileRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserProfileRsp.Data
 */
export class UserProfileRsp_Data extends Message<UserProfileRsp_Data> {
  /**
   * @generated from field: string user = 1;
   */
  user = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.UserProfileRsp.Item items = 2;
   */
  items: UserProfileRsp_Item[] = [];

  constructor(data?: PartialMessage<UserProfileRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserProfileRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "items", kind: "message", T: UserProfileRsp_Item, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserProfileRsp_Data {
    return new UserProfileRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserProfileRsp_Data {
    return new UserProfileRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserProfileRsp_Data {
    return new UserProfileRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: UserProfileRsp_Data | PlainMessage<UserProfileRsp_Data> | undefined, b: UserProfileRsp_Data | PlainMessage<UserProfileRsp_Data> | undefined): boolean {
    return proto3.util.equals(UserProfileRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserProfileRsp.Item
 */
export class UserProfileRsp_Item extends Message<UserProfileRsp_Item> {
  /**
   * 角色代码
   *
   * @generated from field: string role_code = 1;
   */
  roleCode = "";

  /**
   * 产品ID
   *
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string role_name = 3;
   */
  roleName = "";

  /**
   * @generated from field: uint32 role_type = 4;
   */
  roleType = 0;

  /**
   * @generated from field: string position = 5;
   */
  position = "";

  constructor(data?: PartialMessage<UserProfileRsp_Item>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserProfileRsp.Item";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "role_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "role_type", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "position", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserProfileRsp_Item {
    return new UserProfileRsp_Item().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserProfileRsp_Item {
    return new UserProfileRsp_Item().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserProfileRsp_Item {
    return new UserProfileRsp_Item().fromJsonString(jsonString, options);
  }

  static equals(a: UserProfileRsp_Item | PlainMessage<UserProfileRsp_Item> | undefined, b: UserProfileRsp_Item | PlainMessage<UserProfileRsp_Item> | undefined): boolean {
    return proto3.util.equals(UserProfileRsp_Item, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CsProductQueryReq
 */
export class CsProductQueryReq extends Message<CsProductQueryReq> {
  /**
   * 项目id
   *
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  constructor(data?: PartialMessage<CsProductQueryReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CsProductQueryReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CsProductQueryReq {
    return new CsProductQueryReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CsProductQueryReq {
    return new CsProductQueryReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CsProductQueryReq {
    return new CsProductQueryReq().fromJsonString(jsonString, options);
  }

  static equals(a: CsProductQueryReq | PlainMessage<CsProductQueryReq> | undefined, b: CsProductQueryReq | PlainMessage<CsProductQueryReq> | undefined): boolean {
    return proto3.util.equals(CsProductQueryReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CsProductQueryRsp
 */
export class CsProductQueryRsp extends Message<CsProductQueryRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: int32 count = 3;
   */
  count = 0;

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.ProductInfo data = 4;
   */
  data: ProductInfo[] = [];

  constructor(data?: PartialMessage<CsProductQueryRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CsProductQueryRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "data", kind: "message", T: ProductInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CsProductQueryRsp {
    return new CsProductQueryRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CsProductQueryRsp {
    return new CsProductQueryRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CsProductQueryRsp {
    return new CsProductQueryRsp().fromJsonString(jsonString, options);
  }

  static equals(a: CsProductQueryRsp | PlainMessage<CsProductQueryRsp> | undefined, b: CsProductQueryRsp | PlainMessage<CsProductQueryRsp> | undefined): boolean {
    return proto3.util.equals(CsProductQueryRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductCreateReq
 */
export class ProductCreateReq extends Message<ProductCreateReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string creator = 3;
   */
  creator = "";

  constructor(data?: PartialMessage<ProductCreateReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductCreateReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "creator", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductCreateReq {
    return new ProductCreateReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductCreateReq {
    return new ProductCreateReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductCreateReq {
    return new ProductCreateReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProductCreateReq | PlainMessage<ProductCreateReq> | undefined, b: ProductCreateReq | PlainMessage<ProductCreateReq> | undefined): boolean {
    return proto3.util.equals(ProductCreateReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductCreateRsp
 */
export class ProductCreateRsp extends Message<ProductCreateRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: string data = 3;
   */
  data = "";

  constructor(data?: PartialMessage<ProductCreateRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductCreateRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductCreateRsp {
    return new ProductCreateRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductCreateRsp {
    return new ProductCreateRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductCreateRsp {
    return new ProductCreateRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ProductCreateRsp | PlainMessage<ProductCreateRsp> | undefined, b: ProductCreateRsp | PlainMessage<ProductCreateRsp> | undefined): boolean {
    return proto3.util.equals(ProductCreateRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductGetReq
 */
export class ProductGetReq extends Message<ProductGetReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  constructor(data?: PartialMessage<ProductGetReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductGetReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductGetReq {
    return new ProductGetReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductGetReq {
    return new ProductGetReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductGetReq {
    return new ProductGetReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProductGetReq | PlainMessage<ProductGetReq> | undefined, b: ProductGetReq | PlainMessage<ProductGetReq> | undefined): boolean {
    return proto3.util.equals(ProductGetReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductGetRsp
 */
export class ProductGetRsp extends Message<ProductGetRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.ProductInfo data = 3;
   */
  data?: ProductInfo;

  constructor(data?: PartialMessage<ProductGetRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductGetRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: ProductInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductGetRsp {
    return new ProductGetRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductGetRsp {
    return new ProductGetRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductGetRsp {
    return new ProductGetRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ProductGetRsp | PlainMessage<ProductGetRsp> | undefined, b: ProductGetRsp | PlainMessage<ProductGetRsp> | undefined): boolean {
    return proto3.util.equals(ProductGetRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserRoleCheckReq
 */
export class UserRoleCheckReq extends Message<UserRoleCheckReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string user = 3;
   */
  user = "";

  /**
   * @generated from field: string role_code = 4;
   */
  roleCode = "";

  constructor(data?: PartialMessage<UserRoleCheckReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserRoleCheckReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "role_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRoleCheckReq {
    return new UserRoleCheckReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRoleCheckReq {
    return new UserRoleCheckReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRoleCheckReq {
    return new UserRoleCheckReq().fromJsonString(jsonString, options);
  }

  static equals(a: UserRoleCheckReq | PlainMessage<UserRoleCheckReq> | undefined, b: UserRoleCheckReq | PlainMessage<UserRoleCheckReq> | undefined): boolean {
    return proto3.util.equals(UserRoleCheckReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserRoleCheckRsp
 */
export class UserRoleCheckRsp extends Message<UserRoleCheckRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.UserRoleCheckRsp.Data data = 3;
   */
  data?: UserRoleCheckRsp_Data;

  constructor(data?: PartialMessage<UserRoleCheckRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserRoleCheckRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: UserRoleCheckRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRoleCheckRsp {
    return new UserRoleCheckRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRoleCheckRsp {
    return new UserRoleCheckRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRoleCheckRsp {
    return new UserRoleCheckRsp().fromJsonString(jsonString, options);
  }

  static equals(a: UserRoleCheckRsp | PlainMessage<UserRoleCheckRsp> | undefined, b: UserRoleCheckRsp | PlainMessage<UserRoleCheckRsp> | undefined): boolean {
    return proto3.util.equals(UserRoleCheckRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserRoleCheckRsp.Data
 */
export class UserRoleCheckRsp_Data extends Message<UserRoleCheckRsp_Data> {
  /**
   * @generated from field: bool ok = 1;
   */
  ok = false;

  /**
   * @generated from field: bool cs_prod = 2;
   */
  csProd = false;

  constructor(data?: PartialMessage<UserRoleCheckRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserRoleCheckRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ok", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "cs_prod", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRoleCheckRsp_Data {
    return new UserRoleCheckRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRoleCheckRsp_Data {
    return new UserRoleCheckRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRoleCheckRsp_Data {
    return new UserRoleCheckRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: UserRoleCheckRsp_Data | PlainMessage<UserRoleCheckRsp_Data> | undefined, b: UserRoleCheckRsp_Data | PlainMessage<UserRoleCheckRsp_Data> | undefined): boolean {
    return proto3.util.equals(UserRoleCheckRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.TcloudproddirGetReq
 */
export class TcloudproddirGetReq extends Message<TcloudproddirGetReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 id = 2;
   */
  id = 0;

  constructor(data?: PartialMessage<TcloudproddirGetReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.TcloudproddirGetReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TcloudproddirGetReq {
    return new TcloudproddirGetReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TcloudproddirGetReq {
    return new TcloudproddirGetReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TcloudproddirGetReq {
    return new TcloudproddirGetReq().fromJsonString(jsonString, options);
  }

  static equals(a: TcloudproddirGetReq | PlainMessage<TcloudproddirGetReq> | undefined, b: TcloudproddirGetReq | PlainMessage<TcloudproddirGetReq> | undefined): boolean {
    return proto3.util.equals(TcloudproddirGetReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.TcloudproddirGetRsp
 */
export class TcloudproddirGetRsp extends Message<TcloudproddirGetRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.TcloudproddirGetRsp.Data data = 3;
   */
  data?: TcloudproddirGetRsp_Data;

  constructor(data?: PartialMessage<TcloudproddirGetRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.TcloudproddirGetRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: TcloudproddirGetRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TcloudproddirGetRsp {
    return new TcloudproddirGetRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TcloudproddirGetRsp {
    return new TcloudproddirGetRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TcloudproddirGetRsp {
    return new TcloudproddirGetRsp().fromJsonString(jsonString, options);
  }

  static equals(a: TcloudproddirGetRsp | PlainMessage<TcloudproddirGetRsp> | undefined, b: TcloudproddirGetRsp | PlainMessage<TcloudproddirGetRsp> | undefined): boolean {
    return proto3.util.equals(TcloudproddirGetRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.TcloudproddirGetRsp.Data
 */
export class TcloudproddirGetRsp_Data extends Message<TcloudproddirGetRsp_Data> {
  /**
   * @generated from field: uint32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: uint32 proj_id = 2;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 3;
   */
  prodId = 0;

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  constructor(data?: PartialMessage<TcloudproddirGetRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.TcloudproddirGetRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TcloudproddirGetRsp_Data {
    return new TcloudproddirGetRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TcloudproddirGetRsp_Data {
    return new TcloudproddirGetRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TcloudproddirGetRsp_Data {
    return new TcloudproddirGetRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: TcloudproddirGetRsp_Data | PlainMessage<TcloudproddirGetRsp_Data> | undefined, b: TcloudproddirGetRsp_Data | PlainMessage<TcloudproddirGetRsp_Data> | undefined): boolean {
    return proto3.util.equals(TcloudproddirGetRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductUpdateReq
 */
export class ProductUpdateReq extends Message<ProductUpdateReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.ProductInfoV2 data = 3;
   */
  data?: ProductInfoV2;

  /**
   * @generated from field: string device = 4;
   */
  device = "";

  constructor(data?: PartialMessage<ProductUpdateReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductUpdateReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "data", kind: "message", T: ProductInfoV2 },
    { no: 4, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductUpdateReq {
    return new ProductUpdateReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductUpdateReq {
    return new ProductUpdateReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductUpdateReq {
    return new ProductUpdateReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProductUpdateReq | PlainMessage<ProductUpdateReq> | undefined, b: ProductUpdateReq | PlainMessage<ProductUpdateReq> | undefined): boolean {
    return proto3.util.equals(ProductUpdateReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductUpdateRsp
 */
export class ProductUpdateRsp extends Message<ProductUpdateRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<ProductUpdateRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductUpdateRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductUpdateRsp {
    return new ProductUpdateRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductUpdateRsp {
    return new ProductUpdateRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductUpdateRsp {
    return new ProductUpdateRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ProductUpdateRsp | PlainMessage<ProductUpdateRsp> | undefined, b: ProductUpdateRsp | PlainMessage<ProductUpdateRsp> | undefined): boolean {
    return proto3.util.equals(ProductUpdateRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductAddReq
 */
export class ProductAddReq extends Message<ProductAddReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.ProductInfoV2 data = 2;
   */
  data?: ProductInfoV2;

  /**
   * @generated from field: string device = 3;
   */
  device = "";

  constructor(data?: PartialMessage<ProductAddReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductAddReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "data", kind: "message", T: ProductInfoV2 },
    { no: 3, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductAddReq {
    return new ProductAddReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductAddReq {
    return new ProductAddReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductAddReq {
    return new ProductAddReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProductAddReq | PlainMessage<ProductAddReq> | undefined, b: ProductAddReq | PlainMessage<ProductAddReq> | undefined): boolean {
    return proto3.util.equals(ProductAddReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductAddRsp
 */
export class ProductAddRsp extends Message<ProductAddRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<ProductAddRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductAddRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductAddRsp {
    return new ProductAddRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductAddRsp {
    return new ProductAddRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductAddRsp {
    return new ProductAddRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ProductAddRsp | PlainMessage<ProductAddRsp> | undefined, b: ProductAddRsp | PlainMessage<ProductAddRsp> | undefined): boolean {
    return proto3.util.equals(ProductAddRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductQueueUpdateReq
 */
export class ProductQueueUpdateReq extends Message<ProductQueueUpdateReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: repeated string duty_admin = 3;
   */
  dutyAdmin: string[] = [];

  /**
   * @generated from field: repeated string cs = 4;
   */
  cs: string[] = [];

  /**
   * @generated from field: repeated string om = 5;
   */
  om: string[] = [];

  /**
   * @generated from field: repeated string prod = 6;
   */
  prod: string[] = [];

  /**
   * @generated from field: repeated string rd = 7;
   */
  rd: string[] = [];

  constructor(data?: PartialMessage<ProductQueueUpdateReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductQueueUpdateReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "duty_admin", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "cs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "om", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "prod", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "rd", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductQueueUpdateReq {
    return new ProductQueueUpdateReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductQueueUpdateReq {
    return new ProductQueueUpdateReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductQueueUpdateReq {
    return new ProductQueueUpdateReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProductQueueUpdateReq | PlainMessage<ProductQueueUpdateReq> | undefined, b: ProductQueueUpdateReq | PlainMessage<ProductQueueUpdateReq> | undefined): boolean {
    return proto3.util.equals(ProductQueueUpdateReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductQueueUpdateRsp
 */
export class ProductQueueUpdateRsp extends Message<ProductQueueUpdateRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<ProductQueueUpdateRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductQueueUpdateRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductQueueUpdateRsp {
    return new ProductQueueUpdateRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductQueueUpdateRsp {
    return new ProductQueueUpdateRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductQueueUpdateRsp {
    return new ProductQueueUpdateRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ProductQueueUpdateRsp | PlainMessage<ProductQueueUpdateRsp> | undefined, b: ProductQueueUpdateRsp | PlainMessage<ProductQueueUpdateRsp> | undefined): boolean {
    return proto3.util.equals(ProductQueueUpdateRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveFieldsReq
 */
export class SaveFieldsReq extends Message<SaveFieldsReq> {
  /**
   * @generated from field: int32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: int32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: bool is_all = 3;
   */
  isAll = false;

  /**
   * @generated from field: string ticket_type = 4;
   */
  ticketType = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.FieldInfo data = 5;
   */
  data: FieldInfo[] = [];

  constructor(data?: PartialMessage<SaveFieldsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveFieldsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "is_all", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "data", kind: "message", T: FieldInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveFieldsReq {
    return new SaveFieldsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveFieldsReq {
    return new SaveFieldsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveFieldsReq {
    return new SaveFieldsReq().fromJsonString(jsonString, options);
  }

  static equals(a: SaveFieldsReq | PlainMessage<SaveFieldsReq> | undefined, b: SaveFieldsReq | PlainMessage<SaveFieldsReq> | undefined): boolean {
    return proto3.util.equals(SaveFieldsReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveFieldsRsp
 */
export class SaveFieldsRsp extends Message<SaveFieldsRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<SaveFieldsRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveFieldsRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveFieldsRsp {
    return new SaveFieldsRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveFieldsRsp {
    return new SaveFieldsRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveFieldsRsp {
    return new SaveFieldsRsp().fromJsonString(jsonString, options);
  }

  static equals(a: SaveFieldsRsp | PlainMessage<SaveFieldsRsp> | undefined, b: SaveFieldsRsp | PlainMessage<SaveFieldsRsp> | undefined): boolean {
    return proto3.util.equals(SaveFieldsRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ColumnInfoReq
 */
export class ColumnInfoReq extends Message<ColumnInfoReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string action = 3;
   */
  action = "";

  /**
   * @generated from field: string sys_type = 4;
   */
  sysType = "";

  /**
   * @generated from field: string ticket_type = 5;
   */
  ticketType = "";

  /**
   * @generated from field: bool get_all_option = 6;
   */
  getAllOption = false;

  constructor(data?: PartialMessage<ColumnInfoReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ColumnInfoReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "action", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sys_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "get_all_option", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ColumnInfoReq {
    return new ColumnInfoReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ColumnInfoReq {
    return new ColumnInfoReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ColumnInfoReq {
    return new ColumnInfoReq().fromJsonString(jsonString, options);
  }

  static equals(a: ColumnInfoReq | PlainMessage<ColumnInfoReq> | undefined, b: ColumnInfoReq | PlainMessage<ColumnInfoReq> | undefined): boolean {
    return proto3.util.equals(ColumnInfoReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ColumnInfoRsp
 */
export class ColumnInfoRsp extends Message<ColumnInfoRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.FieldInfo data = 3;
   */
  data: FieldInfo[] = [];

  constructor(data?: PartialMessage<ColumnInfoRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ColumnInfoRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: FieldInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ColumnInfoRsp {
    return new ColumnInfoRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ColumnInfoRsp {
    return new ColumnInfoRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ColumnInfoRsp {
    return new ColumnInfoRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ColumnInfoRsp | PlainMessage<ColumnInfoRsp> | undefined, b: ColumnInfoRsp | PlainMessage<ColumnInfoRsp> | undefined): boolean {
    return proto3.util.equals(ColumnInfoRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.FieldInfo
 */
export class FieldInfo extends Message<FieldInfo> {
  /**
   * @generated from field: string eng_name = 1;
   */
  engName = "";

  /**
   * @generated from field: string chn_name = 2;
   */
  chnName = "";

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  /**
   * @generated from field: string sys_type = 4;
   */
  sysType = "";

  /**
   * @generated from field: repeated string action = 5;
   */
  action: string[] = [];

  /**
   * @generated from field: bool required = 6;
   */
  required = false;

  /**
   * @generated from field: string option = 7;
   */
  option = "";

  /**
   * @generated from field: string default = 8;
   */
  default = "";

  /**
   * @generated from field: int32 max_length = 9;
   */
  maxLength = 0;

  /**
   * @generated from field: string placeholder = 10;
   */
  placeholder = "";

  /**
   * @generated from field: bool allow_multiple = 11;
   */
  allowMultiple = false;

  /**
   * @generated from field: string editable = 12;
   */
  editable = "";

  /**
   * @generated from field: string errors = 13;
   */
  errors = "";

  /**
   * @generated from field: int32 sort_no = 14;
   */
  sortNo = 0;

  /**
   * @generated from field: string map_to_column = 15;
   */
  mapToColumn = "";

  /**
   * @generated from field: int32 visible_level = 16;
   */
  visibleLevel = 0;

  /**
   * @generated from field: uint32 prod_id = 17;
   */
  prodId = 0;

  /**
   * @generated from field: bool enable = 18;
   */
  enable = false;

  /**
   * @generated from field: bool is_sync = 19;
   */
  isSync = false;

  /**
   * @generated from field: bool must_have_default_value = 20;
   */
  mustHaveDefaultValue = false;

  /**
   * @generated from field: string ticket_type = 21;
   */
  ticketType = "";

  /**
   * @generated from field: string category_name = 22;
   */
  categoryName = "";

  /**
   * @generated from field: uint32 category_id = 23;
   */
  categoryId = 0;

  /**
   * @generated from field: repeated string category_option = 24;
   */
  categoryOption: string[] = [];

  /**
   * @generated from field: bool is_default_string = 25;
   */
  isDefaultString = false;

  /**
   * @generated from field: string field_rule = 26;
   */
  fieldRule = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.FieldInfo.SmartGenerate smart_generate = 27;
   */
  smartGenerate?: FieldInfo_SmartGenerate;

  constructor(data?: PartialMessage<FieldInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.FieldInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "chn_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sys_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "action", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "required", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "option", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "default", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "max_length", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "placeholder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "allow_multiple", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "editable", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "errors", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "sort_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "map_to_column", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "visible_level", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 17, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 18, name: "enable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "is_sync", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "must_have_default_value", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "category_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "category_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 24, name: "category_option", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 25, name: "is_default_string", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 26, name: "field_rule", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "smart_generate", kind: "message", T: FieldInfo_SmartGenerate },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FieldInfo {
    return new FieldInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FieldInfo {
    return new FieldInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FieldInfo {
    return new FieldInfo().fromJsonString(jsonString, options);
  }

  static equals(a: FieldInfo | PlainMessage<FieldInfo> | undefined, b: FieldInfo | PlainMessage<FieldInfo> | undefined): boolean {
    return proto3.util.equals(FieldInfo, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.FieldInfo.SmartGenerate
 */
export class FieldInfo_SmartGenerate extends Message<FieldInfo_SmartGenerate> {
  /**
   * @generated from field: bool enabled = 1;
   */
  enabled = false;

  /**
   * @generated from field: string prompt = 2;
   */
  prompt = "";

  constructor(data?: PartialMessage<FieldInfo_SmartGenerate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.FieldInfo.SmartGenerate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "prompt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FieldInfo_SmartGenerate {
    return new FieldInfo_SmartGenerate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FieldInfo_SmartGenerate {
    return new FieldInfo_SmartGenerate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FieldInfo_SmartGenerate {
    return new FieldInfo_SmartGenerate().fromJsonString(jsonString, options);
  }

  static equals(a: FieldInfo_SmartGenerate | PlainMessage<FieldInfo_SmartGenerate> | undefined, b: FieldInfo_SmartGenerate | PlainMessage<FieldInfo_SmartGenerate> | undefined): boolean {
    return proto3.util.equals(FieldInfo_SmartGenerate, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.FieldQueryReq
 */
export class FieldQueryReq extends Message<FieldQueryReq> {
  /**
   * @generated from field: string type = 1;
   */
  type = "";

  /**
   * @generated from field: string chn_name = 2;
   */
  chnName = "";

  constructor(data?: PartialMessage<FieldQueryReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.FieldQueryReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "chn_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FieldQueryReq {
    return new FieldQueryReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FieldQueryReq {
    return new FieldQueryReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FieldQueryReq {
    return new FieldQueryReq().fromJsonString(jsonString, options);
  }

  static equals(a: FieldQueryReq | PlainMessage<FieldQueryReq> | undefined, b: FieldQueryReq | PlainMessage<FieldQueryReq> | undefined): boolean {
    return proto3.util.equals(FieldQueryReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.FieldQueryRsp
 */
export class FieldQueryRsp extends Message<FieldQueryRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.FieldInfo data = 3;
   */
  data: FieldInfo[] = [];

  constructor(data?: PartialMessage<FieldQueryRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.FieldQueryRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: FieldInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FieldQueryRsp {
    return new FieldQueryRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FieldQueryRsp {
    return new FieldQueryRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FieldQueryRsp {
    return new FieldQueryRsp().fromJsonString(jsonString, options);
  }

  static equals(a: FieldQueryRsp | PlainMessage<FieldQueryRsp> | undefined, b: FieldQueryRsp | PlainMessage<FieldQueryRsp> | undefined): boolean {
    return proto3.util.equals(FieldQueryRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SyncToOtherProdReq
 */
export class SyncToOtherProdReq extends Message<SyncToOtherProdReq> {
  /**
   * @generated from field: int32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: int32 from_prod_id = 2;
   */
  fromProdId = 0;

  /**
   * @generated from field: repeated int32 to_prod_id = 3;
   */
  toProdId: number[] = [];

  /**
   * @generated from field: string ticket_type = 4;
   */
  ticketType = "";

  constructor(data?: PartialMessage<SyncToOtherProdReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SyncToOtherProdReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "from_prod_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "to_prod_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 4, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncToOtherProdReq {
    return new SyncToOtherProdReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncToOtherProdReq {
    return new SyncToOtherProdReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncToOtherProdReq {
    return new SyncToOtherProdReq().fromJsonString(jsonString, options);
  }

  static equals(a: SyncToOtherProdReq | PlainMessage<SyncToOtherProdReq> | undefined, b: SyncToOtherProdReq | PlainMessage<SyncToOtherProdReq> | undefined): boolean {
    return proto3.util.equals(SyncToOtherProdReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SyncToOtherProdRsp
 */
export class SyncToOtherProdRsp extends Message<SyncToOtherProdRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<SyncToOtherProdRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SyncToOtherProdRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SyncToOtherProdRsp {
    return new SyncToOtherProdRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SyncToOtherProdRsp {
    return new SyncToOtherProdRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SyncToOtherProdRsp {
    return new SyncToOtherProdRsp().fromJsonString(jsonString, options);
  }

  static equals(a: SyncToOtherProdRsp | PlainMessage<SyncToOtherProdRsp> | undefined, b: SyncToOtherProdRsp | PlainMessage<SyncToOtherProdRsp> | undefined): boolean {
    return proto3.util.equals(SyncToOtherProdRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UpdateScheduleReq
 */
export class UpdateScheduleReq extends Message<UpdateScheduleReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.UpdateScheduleReq.Data data = 2;
   */
  data: UpdateScheduleReq_Data[] = [];

  constructor(data?: PartialMessage<UpdateScheduleReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UpdateScheduleReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "data", kind: "message", T: UpdateScheduleReq_Data, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateScheduleReq {
    return new UpdateScheduleReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateScheduleReq {
    return new UpdateScheduleReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateScheduleReq {
    return new UpdateScheduleReq().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateScheduleReq | PlainMessage<UpdateScheduleReq> | undefined, b: UpdateScheduleReq | PlainMessage<UpdateScheduleReq> | undefined): boolean {
    return proto3.util.equals(UpdateScheduleReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UpdateScheduleReq.Data
 */
export class UpdateScheduleReq_Data extends Message<UpdateScheduleReq_Data> {
  /**
   * @generated from field: uint32 schedule_id = 1;
   */
  scheduleId = 0;

  /**
   * @generated from field: string user = 2;
   */
  user = "";

  /**
   * @generated from field: string day = 3;
   */
  day = "";

  /**
   * add/delete
   *
   * @generated from field: string op = 4;
   */
  op = "";

  constructor(data?: PartialMessage<UpdateScheduleReq_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UpdateScheduleReq.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "schedule_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "day", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "op", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateScheduleReq_Data {
    return new UpdateScheduleReq_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateScheduleReq_Data {
    return new UpdateScheduleReq_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateScheduleReq_Data {
    return new UpdateScheduleReq_Data().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateScheduleReq_Data | PlainMessage<UpdateScheduleReq_Data> | undefined, b: UpdateScheduleReq_Data | PlainMessage<UpdateScheduleReq_Data> | undefined): boolean {
    return proto3.util.equals(UpdateScheduleReq_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryScheduleReq
 */
export class QueryScheduleReq extends Message<QueryScheduleReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 schedule_id = 2;
   */
  scheduleId = 0;

  /**
   * @generated from field: string begin = 3;
   */
  begin = "";

  /**
   * @generated from field: string end = 4;
   */
  end = "";

  /**
   * @generated from field: string user = 5;
   */
  user = "";

  constructor(data?: PartialMessage<QueryScheduleReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryScheduleReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "schedule_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "begin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "end", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryScheduleReq {
    return new QueryScheduleReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryScheduleReq {
    return new QueryScheduleReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryScheduleReq {
    return new QueryScheduleReq().fromJsonString(jsonString, options);
  }

  static equals(a: QueryScheduleReq | PlainMessage<QueryScheduleReq> | undefined, b: QueryScheduleReq | PlainMessage<QueryScheduleReq> | undefined): boolean {
    return proto3.util.equals(QueryScheduleReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryScheduleRsp
 */
export class QueryScheduleRsp extends Message<QueryScheduleRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.QueryScheduleRsp.Data data = 3;
   */
  data: QueryScheduleRsp_Data[] = [];

  constructor(data?: PartialMessage<QueryScheduleRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryScheduleRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: QueryScheduleRsp_Data, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryScheduleRsp {
    return new QueryScheduleRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryScheduleRsp {
    return new QueryScheduleRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryScheduleRsp {
    return new QueryScheduleRsp().fromJsonString(jsonString, options);
  }

  static equals(a: QueryScheduleRsp | PlainMessage<QueryScheduleRsp> | undefined, b: QueryScheduleRsp | PlainMessage<QueryScheduleRsp> | undefined): boolean {
    return proto3.util.equals(QueryScheduleRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryScheduleRsp.Data
 */
export class QueryScheduleRsp_Data extends Message<QueryScheduleRsp_Data> {
  /**
   * @generated from field: uint32 schedule_id = 1;
   */
  scheduleId = 0;

  /**
   * @generated from field: string user = 2;
   */
  user = "";

  /**
   * @generated from field: string schedule_at = 3;
   */
  scheduleAt = "";

  constructor(data?: PartialMessage<QueryScheduleRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryScheduleRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "schedule_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "schedule_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryScheduleRsp_Data {
    return new QueryScheduleRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryScheduleRsp_Data {
    return new QueryScheduleRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryScheduleRsp_Data {
    return new QueryScheduleRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: QueryScheduleRsp_Data | PlainMessage<QueryScheduleRsp_Data> | undefined, b: QueryScheduleRsp_Data | PlainMessage<QueryScheduleRsp_Data> | undefined): boolean {
    return proto3.util.equals(QueryScheduleRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UpdateScheduleRsp
 */
export class UpdateScheduleRsp extends Message<UpdateScheduleRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<UpdateScheduleRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UpdateScheduleRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateScheduleRsp {
    return new UpdateScheduleRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateScheduleRsp {
    return new UpdateScheduleRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateScheduleRsp {
    return new UpdateScheduleRsp().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateScheduleRsp | PlainMessage<UpdateScheduleRsp> | undefined, b: UpdateScheduleRsp | PlainMessage<UpdateScheduleRsp> | undefined): boolean {
    return proto3.util.equals(UpdateScheduleRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProjectIDByServiceIDReq
 */
export class ProjectIDByServiceIDReq extends Message<ProjectIDByServiceIDReq> {
  /**
   * @generated from field: string service_id = 1;
   */
  serviceId = "";

  constructor(data?: PartialMessage<ProjectIDByServiceIDReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProjectIDByServiceIDReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "service_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProjectIDByServiceIDReq {
    return new ProjectIDByServiceIDReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProjectIDByServiceIDReq {
    return new ProjectIDByServiceIDReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProjectIDByServiceIDReq {
    return new ProjectIDByServiceIDReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProjectIDByServiceIDReq | PlainMessage<ProjectIDByServiceIDReq> | undefined, b: ProjectIDByServiceIDReq | PlainMessage<ProjectIDByServiceIDReq> | undefined): boolean {
    return proto3.util.equals(ProjectIDByServiceIDReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProjectIDByServiceIDRsp
 */
export class ProjectIDByServiceIDRsp extends Message<ProjectIDByServiceIDRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: uint32 proj_id = 3;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 4;
   */
  prodId = 0;

  constructor(data?: PartialMessage<ProjectIDByServiceIDRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProjectIDByServiceIDRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProjectIDByServiceIDRsp {
    return new ProjectIDByServiceIDRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProjectIDByServiceIDRsp {
    return new ProjectIDByServiceIDRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProjectIDByServiceIDRsp {
    return new ProjectIDByServiceIDRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ProjectIDByServiceIDRsp | PlainMessage<ProjectIDByServiceIDRsp> | undefined, b: ProjectIDByServiceIDRsp | PlainMessage<ProjectIDByServiceIDRsp> | undefined): boolean {
    return proto3.util.equals(ProjectIDByServiceIDRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QualityAdminListReq
 */
export class QualityAdminListReq extends Message<QualityAdminListReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  constructor(data?: PartialMessage<QualityAdminListReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QualityAdminListReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QualityAdminListReq {
    return new QualityAdminListReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QualityAdminListReq {
    return new QualityAdminListReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QualityAdminListReq {
    return new QualityAdminListReq().fromJsonString(jsonString, options);
  }

  static equals(a: QualityAdminListReq | PlainMessage<QualityAdminListReq> | undefined, b: QualityAdminListReq | PlainMessage<QualityAdminListReq> | undefined): boolean {
    return proto3.util.equals(QualityAdminListReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QualityAdminListRsp
 */
export class QualityAdminListRsp extends Message<QualityAdminListRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.QualityAdminListRsp.Data data = 3;
   */
  data: QualityAdminListRsp_Data[] = [];

  constructor(data?: PartialMessage<QualityAdminListRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QualityAdminListRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: QualityAdminListRsp_Data, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QualityAdminListRsp {
    return new QualityAdminListRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QualityAdminListRsp {
    return new QualityAdminListRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QualityAdminListRsp {
    return new QualityAdminListRsp().fromJsonString(jsonString, options);
  }

  static equals(a: QualityAdminListRsp | PlainMessage<QualityAdminListRsp> | undefined, b: QualityAdminListRsp | PlainMessage<QualityAdminListRsp> | undefined): boolean {
    return proto3.util.equals(QualityAdminListRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QualityAdminListRsp.Data
 */
export class QualityAdminListRsp_Data extends Message<QualityAdminListRsp_Data> {
  /**
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * @generated from field: uint32 user_id = 2;
   */
  userId = 0;

  /**
   * @generated from field: uint32 proj_id = 3;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 4;
   */
  prodId = 0;

  /**
   * @generated from field: string prod_name = 5;
   */
  prodName = "";

  /**
   * @generated from field: string create_at = 6;
   */
  createAt = "";

  constructor(data?: PartialMessage<QualityAdminListRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QualityAdminListRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "prod_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "create_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QualityAdminListRsp_Data {
    return new QualityAdminListRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QualityAdminListRsp_Data {
    return new QualityAdminListRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QualityAdminListRsp_Data {
    return new QualityAdminListRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: QualityAdminListRsp_Data | PlainMessage<QualityAdminListRsp_Data> | undefined, b: QualityAdminListRsp_Data | PlainMessage<QualityAdminListRsp_Data> | undefined): boolean {
    return proto3.util.equals(QualityAdminListRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QualityAdminUpdateReq
 */
export class QualityAdminUpdateReq extends Message<QualityAdminUpdateReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: repeated string admins = 3;
   */
  admins: string[] = [];

  constructor(data?: PartialMessage<QualityAdminUpdateReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QualityAdminUpdateReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "admins", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QualityAdminUpdateReq {
    return new QualityAdminUpdateReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QualityAdminUpdateReq {
    return new QualityAdminUpdateReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QualityAdminUpdateReq {
    return new QualityAdminUpdateReq().fromJsonString(jsonString, options);
  }

  static equals(a: QualityAdminUpdateReq | PlainMessage<QualityAdminUpdateReq> | undefined, b: QualityAdminUpdateReq | PlainMessage<QualityAdminUpdateReq> | undefined): boolean {
    return proto3.util.equals(QualityAdminUpdateReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QualityAdminUpdateRsp
 */
export class QualityAdminUpdateRsp extends Message<QualityAdminUpdateRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<QualityAdminUpdateRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QualityAdminUpdateRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QualityAdminUpdateRsp {
    return new QualityAdminUpdateRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QualityAdminUpdateRsp {
    return new QualityAdminUpdateRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QualityAdminUpdateRsp {
    return new QualityAdminUpdateRsp().fromJsonString(jsonString, options);
  }

  static equals(a: QualityAdminUpdateRsp | PlainMessage<QualityAdminUpdateRsp> | undefined, b: QualityAdminUpdateRsp | PlainMessage<QualityAdminUpdateRsp> | undefined): boolean {
    return proto3.util.equals(QualityAdminUpdateRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.HelperConfigCheckReq
 */
export class HelperConfigCheckReq extends Message<HelperConfigCheckReq> {
  /**
   * @generated from field: string before_name = 1;
   */
  beforeName = "";

  /**
   * @generated from field: string after_name = 2;
   */
  afterName = "";

  /**
   * @generated from field: string cname = 3;
   */
  cname = "";

  /**
   * @generated from field: string corpid = 4;
   */
  corpid = "";

  /**
   * @generated from field: string corpsecret = 5;
   */
  corpsecret = "";

  /**
   * @generated from field: string encodingaeskey = 6;
   */
  encodingaeskey = "";

  /**
   * @generated from field: string token = 7;
   */
  token = "";

  /**
   * @generated from field: string before_service_id = 8;
   */
  beforeServiceId = "";

  /**
   * @generated from field: string after_service_id = 9;
   */
  afterServiceId = "";

  /**
   * @generated from field: string uin = 10;
   */
  uin = "";

  constructor(data?: PartialMessage<HelperConfigCheckReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.HelperConfigCheckReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "before_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "after_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "corpid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "corpsecret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "encodingaeskey", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "before_service_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "after_service_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "uin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HelperConfigCheckReq {
    return new HelperConfigCheckReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HelperConfigCheckReq {
    return new HelperConfigCheckReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HelperConfigCheckReq {
    return new HelperConfigCheckReq().fromJsonString(jsonString, options);
  }

  static equals(a: HelperConfigCheckReq | PlainMessage<HelperConfigCheckReq> | undefined, b: HelperConfigCheckReq | PlainMessage<HelperConfigCheckReq> | undefined): boolean {
    return proto3.util.equals(HelperConfigCheckReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.HelperConfigCheckRsp
 */
export class HelperConfigCheckRsp extends Message<HelperConfigCheckRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<HelperConfigCheckRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.HelperConfigCheckRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HelperConfigCheckRsp {
    return new HelperConfigCheckRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HelperConfigCheckRsp {
    return new HelperConfigCheckRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HelperConfigCheckRsp {
    return new HelperConfigCheckRsp().fromJsonString(jsonString, options);
  }

  static equals(a: HelperConfigCheckRsp | PlainMessage<HelperConfigCheckRsp> | undefined, b: HelperConfigCheckRsp | PlainMessage<HelperConfigCheckRsp> | undefined): boolean {
    return proto3.util.equals(HelperConfigCheckRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.HelperConfigListReq
 */
export class HelperConfigListReq extends Message<HelperConfigListReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  constructor(data?: PartialMessage<HelperConfigListReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.HelperConfigListReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HelperConfigListReq {
    return new HelperConfigListReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HelperConfigListReq {
    return new HelperConfigListReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HelperConfigListReq {
    return new HelperConfigListReq().fromJsonString(jsonString, options);
  }

  static equals(a: HelperConfigListReq | PlainMessage<HelperConfigListReq> | undefined, b: HelperConfigListReq | PlainMessage<HelperConfigListReq> | undefined): boolean {
    return proto3.util.equals(HelperConfigListReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.HelperConfigListRsp
 */
export class HelperConfigListRsp extends Message<HelperConfigListRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.HelperConfigListRsp.HelperConfigItem data = 3;
   */
  data: HelperConfigListRsp_HelperConfigItem[] = [];

  constructor(data?: PartialMessage<HelperConfigListRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.HelperConfigListRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: HelperConfigListRsp_HelperConfigItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HelperConfigListRsp {
    return new HelperConfigListRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HelperConfigListRsp {
    return new HelperConfigListRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HelperConfigListRsp {
    return new HelperConfigListRsp().fromJsonString(jsonString, options);
  }

  static equals(a: HelperConfigListRsp | PlainMessage<HelperConfigListRsp> | undefined, b: HelperConfigListRsp | PlainMessage<HelperConfigListRsp> | undefined): boolean {
    return proto3.util.equals(HelperConfigListRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.HelperConfigListRsp.HelperConfigItem
 */
export class HelperConfigListRsp_HelperConfigItem extends Message<HelperConfigListRsp_HelperConfigItem> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string service_id = 2;
   */
  serviceId = "";

  /**
   * @generated from field: string corpid = 3;
   */
  corpid = "";

  /**
   * @generated from field: string corpsecret = 4;
   */
  corpsecret = "";

  /**
   * @generated from field: string encodingaeskey = 5;
   */
  encodingaeskey = "";

  /**
   * @generated from field: string token = 6;
   */
  token = "";

  /**
   * @generated from field: string cname = 7;
   */
  cname = "";

  /**
   * @generated from field: string uin = 8;
   */
  uin = "";

  constructor(data?: PartialMessage<HelperConfigListRsp_HelperConfigItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.HelperConfigListRsp.HelperConfigItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "service_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "corpid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "corpsecret", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "encodingaeskey", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "cname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "uin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HelperConfigListRsp_HelperConfigItem {
    return new HelperConfigListRsp_HelperConfigItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HelperConfigListRsp_HelperConfigItem {
    return new HelperConfigListRsp_HelperConfigItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HelperConfigListRsp_HelperConfigItem {
    return new HelperConfigListRsp_HelperConfigItem().fromJsonString(jsonString, options);
  }

  static equals(a: HelperConfigListRsp_HelperConfigItem | PlainMessage<HelperConfigListRsp_HelperConfigItem> | undefined, b: HelperConfigListRsp_HelperConfigItem | PlainMessage<HelperConfigListRsp_HelperConfigItem> | undefined): boolean {
    return proto3.util.equals(HelperConfigListRsp_HelperConfigItem, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.T2000QueryRsp
 */
export class T2000QueryRsp extends Message<T2000QueryRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.ProductInfo data = 4;
   */
  data?: ProductInfo;

  constructor(data?: PartialMessage<T2000QueryRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.T2000QueryRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "data", kind: "message", T: ProductInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): T2000QueryRsp {
    return new T2000QueryRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): T2000QueryRsp {
    return new T2000QueryRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): T2000QueryRsp {
    return new T2000QueryRsp().fromJsonString(jsonString, options);
  }

  static equals(a: T2000QueryRsp | PlainMessage<T2000QueryRsp> | undefined, b: T2000QueryRsp | PlainMessage<T2000QueryRsp> | undefined): boolean {
    return proto3.util.equals(T2000QueryRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UploadFileReq
 */
export class UploadFileReq extends Message<UploadFileReq> {
  constructor(data?: PartialMessage<UploadFileReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UploadFileReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadFileReq {
    return new UploadFileReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadFileReq {
    return new UploadFileReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadFileReq {
    return new UploadFileReq().fromJsonString(jsonString, options);
  }

  static equals(a: UploadFileReq | PlainMessage<UploadFileReq> | undefined, b: UploadFileReq | PlainMessage<UploadFileReq> | undefined): boolean {
    return proto3.util.equals(UploadFileReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UploadFileRsp
 */
export class UploadFileRsp extends Message<UploadFileRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.UploadFileRsp.UploadFileRspData data = 3;
   */
  data?: UploadFileRsp_UploadFileRspData;

  constructor(data?: PartialMessage<UploadFileRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UploadFileRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: UploadFileRsp_UploadFileRspData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadFileRsp {
    return new UploadFileRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadFileRsp {
    return new UploadFileRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadFileRsp {
    return new UploadFileRsp().fromJsonString(jsonString, options);
  }

  static equals(a: UploadFileRsp | PlainMessage<UploadFileRsp> | undefined, b: UploadFileRsp | PlainMessage<UploadFileRsp> | undefined): boolean {
    return proto3.util.equals(UploadFileRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UploadFileRsp.UploadFileRspData
 */
export class UploadFileRsp_UploadFileRspData extends Message<UploadFileRsp_UploadFileRspData> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * 原文件名。web端显示的文件名。下载自动生成的文件名
   *
   * @generated from field: string alt = 2;
   */
  alt = "";

  constructor(data?: PartialMessage<UploadFileRsp_UploadFileRspData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UploadFileRsp.UploadFileRspData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "alt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadFileRsp_UploadFileRspData {
    return new UploadFileRsp_UploadFileRspData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadFileRsp_UploadFileRspData {
    return new UploadFileRsp_UploadFileRspData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadFileRsp_UploadFileRspData {
    return new UploadFileRsp_UploadFileRspData().fromJsonString(jsonString, options);
  }

  static equals(a: UploadFileRsp_UploadFileRspData | PlainMessage<UploadFileRsp_UploadFileRspData> | undefined, b: UploadFileRsp_UploadFileRspData | PlainMessage<UploadFileRsp_UploadFileRspData> | undefined): boolean {
    return proto3.util.equals(UploadFileRsp_UploadFileRspData, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductGetByHelperIDReq
 */
export class ProductGetByHelperIDReq extends Message<ProductGetByHelperIDReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: string helper_id = 2;
   */
  helperId = "";

  constructor(data?: PartialMessage<ProductGetByHelperIDReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductGetByHelperIDReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "helper_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductGetByHelperIDReq {
    return new ProductGetByHelperIDReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductGetByHelperIDReq {
    return new ProductGetByHelperIDReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductGetByHelperIDReq {
    return new ProductGetByHelperIDReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProductGetByHelperIDReq | PlainMessage<ProductGetByHelperIDReq> | undefined, b: ProductGetByHelperIDReq | PlainMessage<ProductGetByHelperIDReq> | undefined): boolean {
    return proto3.util.equals(ProductGetByHelperIDReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetFileReq
 */
export class GetFileReq extends Message<GetFileReq> {
  constructor(data?: PartialMessage<GetFileReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetFileReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFileReq {
    return new GetFileReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFileReq {
    return new GetFileReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFileReq {
    return new GetFileReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetFileReq | PlainMessage<GetFileReq> | undefined, b: GetFileReq | PlainMessage<GetFileReq> | undefined): boolean {
    return proto3.util.equals(GetFileReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetFileRsp
 */
export class GetFileRsp extends Message<GetFileRsp> {
  constructor(data?: PartialMessage<GetFileRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetFileRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFileRsp {
    return new GetFileRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFileRsp {
    return new GetFileRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFileRsp {
    return new GetFileRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetFileRsp | PlainMessage<GetFileRsp> | undefined, b: GetFileRsp | PlainMessage<GetFileRsp> | undefined): boolean {
    return proto3.util.equals(GetFileRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductAddRoleReq
 */
export class ProductAddRoleReq extends Message<ProductAddRoleReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: repeated uint32 prod_list = 2;
   */
  prodList: number[] = [];

  /**
   * @generated from field: repeated string name_list = 3;
   */
  nameList: string[] = [];

  /**
   * @generated from field: repeated string role_list = 4;
   */
  roleList: string[] = [];

  /**
   * @generated from field: string device = 5;
   */
  device = "";

  constructor(data?: PartialMessage<ProductAddRoleReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductAddRoleReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_list", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 3, name: "name_list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "role_list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductAddRoleReq {
    return new ProductAddRoleReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductAddRoleReq {
    return new ProductAddRoleReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductAddRoleReq {
    return new ProductAddRoleReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProductAddRoleReq | PlainMessage<ProductAddRoleReq> | undefined, b: ProductAddRoleReq | PlainMessage<ProductAddRoleReq> | undefined): boolean {
    return proto3.util.equals(ProductAddRoleReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductAddRoleRsp
 */
export class ProductAddRoleRsp extends Message<ProductAddRoleRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<ProductAddRoleRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductAddRoleRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductAddRoleRsp {
    return new ProductAddRoleRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductAddRoleRsp {
    return new ProductAddRoleRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductAddRoleRsp {
    return new ProductAddRoleRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ProductAddRoleRsp | PlainMessage<ProductAddRoleRsp> | undefined, b: ProductAddRoleRsp | PlainMessage<ProductAddRoleRsp> | undefined): boolean {
    return proto3.util.equals(ProductAddRoleRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductBatchDeleteRoleReq
 */
export class ProductBatchDeleteRoleReq extends Message<ProductBatchDeleteRoleReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: repeated string name_list = 4;
   */
  nameList: string[] = [];

  /**
   * @generated from field: string device = 3;
   */
  device = "";

  constructor(data?: PartialMessage<ProductBatchDeleteRoleReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductBatchDeleteRoleReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "name_list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductBatchDeleteRoleReq {
    return new ProductBatchDeleteRoleReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductBatchDeleteRoleReq {
    return new ProductBatchDeleteRoleReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductBatchDeleteRoleReq {
    return new ProductBatchDeleteRoleReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProductBatchDeleteRoleReq | PlainMessage<ProductBatchDeleteRoleReq> | undefined, b: ProductBatchDeleteRoleReq | PlainMessage<ProductBatchDeleteRoleReq> | undefined): boolean {
    return proto3.util.equals(ProductBatchDeleteRoleReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductBatchDeleteRoleRsp
 */
export class ProductBatchDeleteRoleRsp extends Message<ProductBatchDeleteRoleRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<ProductBatchDeleteRoleRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductBatchDeleteRoleRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductBatchDeleteRoleRsp {
    return new ProductBatchDeleteRoleRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductBatchDeleteRoleRsp {
    return new ProductBatchDeleteRoleRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductBatchDeleteRoleRsp {
    return new ProductBatchDeleteRoleRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ProductBatchDeleteRoleRsp | PlainMessage<ProductBatchDeleteRoleRsp> | undefined, b: ProductBatchDeleteRoleRsp | PlainMessage<ProductBatchDeleteRoleRsp> | undefined): boolean {
    return proto3.util.equals(ProductBatchDeleteRoleRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserRoleQueryReq
 */
export class UserRoleQueryReq extends Message<UserRoleQueryReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: repeated string name_list = 3;
   */
  nameList: string[] = [];

  /**
   * @generated from field: string role = 4;
   */
  role = "";

  /**
   * @generated from field: string start_time = 5;
   */
  startTime = "";

  /**
   * @generated from field: string end_time = 6;
   */
  endTime = "";

  /**
   * @generated from field: uint32 page = 7;
   */
  page = 0;

  /**
   * @generated from field: uint32 size = 8;
   */
  size = 0;

  /**
   * 操作端
   *
   * @generated from field: string device = 9;
   */
  device = "";

  constructor(data?: PartialMessage<UserRoleQueryReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserRoleQueryReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "name_list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "role", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "start_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "end_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "page", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 8, name: "size", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 9, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRoleQueryReq {
    return new UserRoleQueryReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRoleQueryReq {
    return new UserRoleQueryReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRoleQueryReq {
    return new UserRoleQueryReq().fromJsonString(jsonString, options);
  }

  static equals(a: UserRoleQueryReq | PlainMessage<UserRoleQueryReq> | undefined, b: UserRoleQueryReq | PlainMessage<UserRoleQueryReq> | undefined): boolean {
    return proto3.util.equals(UserRoleQueryReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserRoleQueryRsp
 */
export class UserRoleQueryRsp extends Message<UserRoleQueryRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.UserRoleQueryRsp.Data data = 3;
   */
  data?: UserRoleQueryRsp_Data;

  constructor(data?: PartialMessage<UserRoleQueryRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserRoleQueryRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: UserRoleQueryRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRoleQueryRsp {
    return new UserRoleQueryRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRoleQueryRsp {
    return new UserRoleQueryRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRoleQueryRsp {
    return new UserRoleQueryRsp().fromJsonString(jsonString, options);
  }

  static equals(a: UserRoleQueryRsp | PlainMessage<UserRoleQueryRsp> | undefined, b: UserRoleQueryRsp | PlainMessage<UserRoleQueryRsp> | undefined): boolean {
    return proto3.util.equals(UserRoleQueryRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserRoleQueryRsp.Statis
 */
export class UserRoleQueryRsp_Statis extends Message<UserRoleQueryRsp_Statis> {
  /**
   * @generated from field: uint32 all = 1;
   */
  all = 0;

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.UserRoleQueryRsp.Statis.Item item = 2;
   */
  item: UserRoleQueryRsp_Statis_Item[] = [];

  constructor(data?: PartialMessage<UserRoleQueryRsp_Statis>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserRoleQueryRsp.Statis";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "all", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "item", kind: "message", T: UserRoleQueryRsp_Statis_Item, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRoleQueryRsp_Statis {
    return new UserRoleQueryRsp_Statis().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRoleQueryRsp_Statis {
    return new UserRoleQueryRsp_Statis().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRoleQueryRsp_Statis {
    return new UserRoleQueryRsp_Statis().fromJsonString(jsonString, options);
  }

  static equals(a: UserRoleQueryRsp_Statis | PlainMessage<UserRoleQueryRsp_Statis> | undefined, b: UserRoleQueryRsp_Statis | PlainMessage<UserRoleQueryRsp_Statis> | undefined): boolean {
    return proto3.util.equals(UserRoleQueryRsp_Statis, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserRoleQueryRsp.Statis.Item
 */
export class UserRoleQueryRsp_Statis_Item extends Message<UserRoleQueryRsp_Statis_Item> {
  /**
   * @generated from field: string role_name = 1;
   */
  roleName = "";

  /**
   * @generated from field: uint32 total = 2;
   */
  total = 0;

  constructor(data?: PartialMessage<UserRoleQueryRsp_Statis_Item>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserRoleQueryRsp.Statis.Item";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "total", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRoleQueryRsp_Statis_Item {
    return new UserRoleQueryRsp_Statis_Item().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRoleQueryRsp_Statis_Item {
    return new UserRoleQueryRsp_Statis_Item().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRoleQueryRsp_Statis_Item {
    return new UserRoleQueryRsp_Statis_Item().fromJsonString(jsonString, options);
  }

  static equals(a: UserRoleQueryRsp_Statis_Item | PlainMessage<UserRoleQueryRsp_Statis_Item> | undefined, b: UserRoleQueryRsp_Statis_Item | PlainMessage<UserRoleQueryRsp_Statis_Item> | undefined): boolean {
    return proto3.util.equals(UserRoleQueryRsp_Statis_Item, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserRoleQueryRsp.Data
 */
export class UserRoleQueryRsp_Data extends Message<UserRoleQueryRsp_Data> {
  /**
   * @generated from field: uint32 total = 1;
   */
  total = 0;

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.UserRoleQueryRsp.Statis statis = 2;
   */
  statis?: UserRoleQueryRsp_Statis;

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.UserRole role_list = 3;
   */
  roleList: UserRole[] = [];

  constructor(data?: PartialMessage<UserRoleQueryRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserRoleQueryRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "statis", kind: "message", T: UserRoleQueryRsp_Statis },
    { no: 3, name: "role_list", kind: "message", T: UserRole, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRoleQueryRsp_Data {
    return new UserRoleQueryRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRoleQueryRsp_Data {
    return new UserRoleQueryRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRoleQueryRsp_Data {
    return new UserRoleQueryRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: UserRoleQueryRsp_Data | PlainMessage<UserRoleQueryRsp_Data> | undefined, b: UserRoleQueryRsp_Data | PlainMessage<UserRoleQueryRsp_Data> | undefined): boolean {
    return proto3.util.equals(UserRoleQueryRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserRole
 */
export class UserRole extends Message<UserRole> {
  /**
   * @generated from field: repeated uint32 id = 1;
   */
  id: number[] = [];

  /**
   * @generated from field: string user_name = 2;
   */
  userName = "";

  /**
   * @generated from field: uint32 role_id = 3;
   */
  roleId = 0;

  /**
   * @generated from field: repeated string role_code = 4;
   */
  roleCode: string[] = [];

  /**
   * @generated from field: uint32 proj_id = 5;
   */
  projId = 0;

  /**
   * @generated from field: string proj_name = 6;
   */
  projName = "";

  /**
   * @generated from field: uint32 prod_id = 7;
   */
  prodId = 0;

  /**
   * @generated from field: string prod_name = 8;
   */
  prodName = "";

  /**
   * @generated from field: string create_at = 9;
   */
  createAt = "";

  /**
   * @generated from field: string creator = 10;
   */
  creator = "";

  constructor(data?: PartialMessage<UserRole>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserRole";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 2, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "role_code", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "proj_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 8, name: "prod_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "create_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "creator", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRole {
    return new UserRole().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRole {
    return new UserRole().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRole {
    return new UserRole().fromJsonString(jsonString, options);
  }

  static equals(a: UserRole | PlainMessage<UserRole> | undefined, b: UserRole | PlainMessage<UserRole> | undefined): boolean {
    return proto3.util.equals(UserRole, a, b);
  }
}

/**
 * message ProductEditRoleReq{
 *  uint32 proj_id = 1;
 *  uint32 staff_id = 2;
 *  uint32 prod_id = 3;
 *  string name = 4;
 *  string role = 5;
 *  string create_at = 6;
 *  string creator = 7;
 *  string device = 8;
 * }
 *
 * @generated from message trpc.teg_devops.servicedesk_product.ProductBatchEditRoleReq
 */
export class ProductBatchEditRoleReq extends Message<ProductBatchEditRoleReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   *  repeated uint32 staff_id = 2;
   *
   * @generated from field: uint32 prod_id = 3;
   */
  prodId = 0;

  /**
   * @generated from field: repeated string name = 4;
   */
  name: string[] = [];

  /**
   * @generated from field: repeated string role = 5;
   */
  role: string[] = [];

  /**
   * @generated from field: string create_at = 6;
   */
  createAt = "";

  /**
   * @generated from field: string creator = 7;
   */
  creator = "";

  /**
   * @generated from field: string device = 8;
   */
  device = "";

  constructor(data?: PartialMessage<ProductBatchEditRoleReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductBatchEditRoleReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "role", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "create_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "creator", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductBatchEditRoleReq {
    return new ProductBatchEditRoleReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductBatchEditRoleReq {
    return new ProductBatchEditRoleReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductBatchEditRoleReq {
    return new ProductBatchEditRoleReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProductBatchEditRoleReq | PlainMessage<ProductBatchEditRoleReq> | undefined, b: ProductBatchEditRoleReq | PlainMessage<ProductBatchEditRoleReq> | undefined): boolean {
    return proto3.util.equals(ProductBatchEditRoleReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductBatchEditRoleRsp
 */
export class ProductBatchEditRoleRsp extends Message<ProductBatchEditRoleRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<ProductBatchEditRoleRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductBatchEditRoleRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductBatchEditRoleRsp {
    return new ProductBatchEditRoleRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductBatchEditRoleRsp {
    return new ProductBatchEditRoleRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductBatchEditRoleRsp {
    return new ProductBatchEditRoleRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ProductBatchEditRoleRsp | PlainMessage<ProductBatchEditRoleRsp> | undefined, b: ProductBatchEditRoleRsp | PlainMessage<ProductBatchEditRoleRsp> | undefined): boolean {
    return proto3.util.equals(ProductBatchEditRoleRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserRoleQueryCSVRsp
 */
export class UserRoleQueryCSVRsp extends Message<UserRoleQueryCSVRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<UserRoleQueryCSVRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserRoleQueryCSVRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRoleQueryCSVRsp {
    return new UserRoleQueryCSVRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRoleQueryCSVRsp {
    return new UserRoleQueryCSVRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRoleQueryCSVRsp {
    return new UserRoleQueryCSVRsp().fromJsonString(jsonString, options);
  }

  static equals(a: UserRoleQueryCSVRsp | PlainMessage<UserRoleQueryCSVRsp> | undefined, b: UserRoleQueryCSVRsp | PlainMessage<UserRoleQueryCSVRsp> | undefined): boolean {
    return proto3.util.equals(UserRoleQueryCSVRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductImportRoleReq
 */
export class ProductImportRoleReq extends Message<ProductImportRoleReq> {
  constructor(data?: PartialMessage<ProductImportRoleReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductImportRoleReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductImportRoleReq {
    return new ProductImportRoleReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductImportRoleReq {
    return new ProductImportRoleReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductImportRoleReq {
    return new ProductImportRoleReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProductImportRoleReq | PlainMessage<ProductImportRoleReq> | undefined, b: ProductImportRoleReq | PlainMessage<ProductImportRoleReq> | undefined): boolean {
    return proto3.util.equals(ProductImportRoleReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductImportRoleRsp
 */
export class ProductImportRoleRsp extends Message<ProductImportRoleRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<ProductImportRoleRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductImportRoleRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductImportRoleRsp {
    return new ProductImportRoleRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductImportRoleRsp {
    return new ProductImportRoleRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductImportRoleRsp {
    return new ProductImportRoleRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ProductImportRoleRsp | PlainMessage<ProductImportRoleRsp> | undefined, b: ProductImportRoleRsp | PlainMessage<ProductImportRoleRsp> | undefined): boolean {
    return proto3.util.equals(ProductImportRoleRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.TemplateListReq
 */
export class TemplateListReq extends Message<TemplateListReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: string ticket_type = 2;
   */
  ticketType = "";

  /**
   * @generated from field: uint32 template_type = 3;
   */
  templateType = 0;

  constructor(data?: PartialMessage<TemplateListReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.TemplateListReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "template_type", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TemplateListReq {
    return new TemplateListReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TemplateListReq {
    return new TemplateListReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TemplateListReq {
    return new TemplateListReq().fromJsonString(jsonString, options);
  }

  static equals(a: TemplateListReq | PlainMessage<TemplateListReq> | undefined, b: TemplateListReq | PlainMessage<TemplateListReq> | undefined): boolean {
    return proto3.util.equals(TemplateListReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.TemplateListRsp
 */
export class TemplateListRsp extends Message<TemplateListRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.TemplateListData data = 3;
   */
  data: TemplateListData[] = [];

  constructor(data?: PartialMessage<TemplateListRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.TemplateListRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: TemplateListData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TemplateListRsp {
    return new TemplateListRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TemplateListRsp {
    return new TemplateListRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TemplateListRsp {
    return new TemplateListRsp().fromJsonString(jsonString, options);
  }

  static equals(a: TemplateListRsp | PlainMessage<TemplateListRsp> | undefined, b: TemplateListRsp | PlainMessage<TemplateListRsp> | undefined): boolean {
    return proto3.util.equals(TemplateListRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.TemplateListData
 */
export class TemplateListData extends Message<TemplateListData> {
  /**
   * @generated from field: uint32 template_id = 1;
   */
  templateId = 0;

  /**
   * @generated from field: string template_type = 2;
   */
  templateType = "";

  /**
   * @generated from field: string template_name = 3;
   */
  templateName = "";

  /**
   * @generated from field: string ticket_type = 4;
   */
  ticketType = "";

  constructor(data?: PartialMessage<TemplateListData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.TemplateListData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "template_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "template_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TemplateListData {
    return new TemplateListData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TemplateListData {
    return new TemplateListData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TemplateListData {
    return new TemplateListData().fromJsonString(jsonString, options);
  }

  static equals(a: TemplateListData | PlainMessage<TemplateListData> | undefined, b: TemplateListData | PlainMessage<TemplateListData> | undefined): boolean {
    return proto3.util.equals(TemplateListData, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.TemplateDetailReq
 */
export class TemplateDetailReq extends Message<TemplateDetailReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 template_id = 2;
   */
  templateId = 0;

  /**
   * @generated from field: string ticket_type = 3;
   */
  ticketType = "";

  constructor(data?: PartialMessage<TemplateDetailReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.TemplateDetailReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "template_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TemplateDetailReq {
    return new TemplateDetailReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TemplateDetailReq {
    return new TemplateDetailReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TemplateDetailReq {
    return new TemplateDetailReq().fromJsonString(jsonString, options);
  }

  static equals(a: TemplateDetailReq | PlainMessage<TemplateDetailReq> | undefined, b: TemplateDetailReq | PlainMessage<TemplateDetailReq> | undefined): boolean {
    return proto3.util.equals(TemplateDetailReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.TemplateDetailRsp
 */
export class TemplateDetailRsp extends Message<TemplateDetailRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.TemplateDetailData data = 3;
   */
  data?: TemplateDetailData;

  constructor(data?: PartialMessage<TemplateDetailRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.TemplateDetailRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: TemplateDetailData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TemplateDetailRsp {
    return new TemplateDetailRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TemplateDetailRsp {
    return new TemplateDetailRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TemplateDetailRsp {
    return new TemplateDetailRsp().fromJsonString(jsonString, options);
  }

  static equals(a: TemplateDetailRsp | PlainMessage<TemplateDetailRsp> | undefined, b: TemplateDetailRsp | PlainMessage<TemplateDetailRsp> | undefined): boolean {
    return proto3.util.equals(TemplateDetailRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.TemplateDetailData
 */
export class TemplateDetailData extends Message<TemplateDetailData> {
  /**
   * @generated from field: uint32 template_id = 1;
   */
  templateId = 0;

  /**
   * @generated from field: string template_type = 2;
   */
  templateType = "";

  /**
   * @generated from field: string template_name = 3;
   */
  templateName = "";

  /**
   * @generated from field: string search_conditions = 4;
   */
  searchConditions = "";

  /**
   * @generated from field: string field_prods = 5;
   */
  fieldProds = "";

  /**
   * @generated from field: uint32 sort_order = 6;
   */
  sortOrder = 0;

  /**
   * @generated from field: string sort_column = 7;
   */
  sortColumn = "";

  /**
   * @generated from field: string search_result_columns = 8;
   */
  searchResultColumns = "";

  /**
   * @generated from field: string ticket_type = 9;
   */
  ticketType = "";

  constructor(data?: PartialMessage<TemplateDetailData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.TemplateDetailData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "template_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "template_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "template_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "search_conditions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "field_prods", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "sort_order", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 7, name: "sort_column", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "search_result_columns", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TemplateDetailData {
    return new TemplateDetailData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TemplateDetailData {
    return new TemplateDetailData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TemplateDetailData {
    return new TemplateDetailData().fromJsonString(jsonString, options);
  }

  static equals(a: TemplateDetailData | PlainMessage<TemplateDetailData> | undefined, b: TemplateDetailData | PlainMessage<TemplateDetailData> | undefined): boolean {
    return proto3.util.equals(TemplateDetailData, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.RenameTemplateReq
 */
export class RenameTemplateReq extends Message<RenameTemplateReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 template_id = 2;
   */
  templateId = 0;

  /**
   * @generated from field: string template_new_name = 3;
   */
  templateNewName = "";

  constructor(data?: PartialMessage<RenameTemplateReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.RenameTemplateReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "template_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "template_new_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RenameTemplateReq {
    return new RenameTemplateReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RenameTemplateReq {
    return new RenameTemplateReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RenameTemplateReq {
    return new RenameTemplateReq().fromJsonString(jsonString, options);
  }

  static equals(a: RenameTemplateReq | PlainMessage<RenameTemplateReq> | undefined, b: RenameTemplateReq | PlainMessage<RenameTemplateReq> | undefined): boolean {
    return proto3.util.equals(RenameTemplateReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.RenameTemplateRsp
 */
export class RenameTemplateRsp extends Message<RenameTemplateRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<RenameTemplateRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.RenameTemplateRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RenameTemplateRsp {
    return new RenameTemplateRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RenameTemplateRsp {
    return new RenameTemplateRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RenameTemplateRsp {
    return new RenameTemplateRsp().fromJsonString(jsonString, options);
  }

  static equals(a: RenameTemplateRsp | PlainMessage<RenameTemplateRsp> | undefined, b: RenameTemplateRsp | PlainMessage<RenameTemplateRsp> | undefined): boolean {
    return proto3.util.equals(RenameTemplateRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ImportItem
 */
export class ImportItem extends Message<ImportItem> {
  /**
   * @generated from field: string prod_name = 1;
   */
  prodName = "";

  /**
   * @generated from field: string role_name = 2;
   */
  roleName = "";

  /**
   * @generated from field: string user_list = 3;
   */
  userList = "";

  constructor(data?: PartialMessage<ImportItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ImportItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "prod_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_list", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportItem {
    return new ImportItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportItem {
    return new ImportItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportItem {
    return new ImportItem().fromJsonString(jsonString, options);
  }

  static equals(a: ImportItem | PlainMessage<ImportItem> | undefined, b: ImportItem | PlainMessage<ImportItem> | undefined): boolean {
    return proto3.util.equals(ImportItem, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ScheduleInfoReq
 */
export class ScheduleInfoReq extends Message<ScheduleInfoReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  constructor(data?: PartialMessage<ScheduleInfoReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ScheduleInfoReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScheduleInfoReq {
    return new ScheduleInfoReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScheduleInfoReq {
    return new ScheduleInfoReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScheduleInfoReq {
    return new ScheduleInfoReq().fromJsonString(jsonString, options);
  }

  static equals(a: ScheduleInfoReq | PlainMessage<ScheduleInfoReq> | undefined, b: ScheduleInfoReq | PlainMessage<ScheduleInfoReq> | undefined): boolean {
    return proto3.util.equals(ScheduleInfoReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ScheduleInfoRsp
 */
export class ScheduleInfoRsp extends Message<ScheduleInfoRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.ScheduleInfoRsp.ScheduleItem data = 3;
   */
  data: ScheduleInfoRsp_ScheduleItem[] = [];

  constructor(data?: PartialMessage<ScheduleInfoRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ScheduleInfoRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: ScheduleInfoRsp_ScheduleItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScheduleInfoRsp {
    return new ScheduleInfoRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScheduleInfoRsp {
    return new ScheduleInfoRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScheduleInfoRsp {
    return new ScheduleInfoRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ScheduleInfoRsp | PlainMessage<ScheduleInfoRsp> | undefined, b: ScheduleInfoRsp | PlainMessage<ScheduleInfoRsp> | undefined): boolean {
    return proto3.util.equals(ScheduleInfoRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ScheduleInfoRsp.ScheduleItem
 */
export class ScheduleInfoRsp_ScheduleItem extends Message<ScheduleInfoRsp_ScheduleItem> {
  /**
   * @generated from field: uint32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string begin_time = 4;
   */
  beginTime = "";

  /**
   * @generated from field: string end_time = 5;
   */
  endTime = "";

  constructor(data?: PartialMessage<ScheduleInfoRsp_ScheduleItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ScheduleInfoRsp.ScheduleItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "begin_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "end_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScheduleInfoRsp_ScheduleItem {
    return new ScheduleInfoRsp_ScheduleItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScheduleInfoRsp_ScheduleItem {
    return new ScheduleInfoRsp_ScheduleItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScheduleInfoRsp_ScheduleItem {
    return new ScheduleInfoRsp_ScheduleItem().fromJsonString(jsonString, options);
  }

  static equals(a: ScheduleInfoRsp_ScheduleItem | PlainMessage<ScheduleInfoRsp_ScheduleItem> | undefined, b: ScheduleInfoRsp_ScheduleItem | PlainMessage<ScheduleInfoRsp_ScheduleItem> | undefined): boolean {
    return proto3.util.equals(ScheduleInfoRsp_ScheduleItem, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ScheduleQueryReq
 */
export class ScheduleQueryReq extends Message<ScheduleQueryReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: repeated string name_list = 3;
   */
  nameList: string[] = [];

  /**
   * @generated from field: uint32 schedule_id = 4;
   */
  scheduleId = 0;

  /**
   * @generated from field: string start_time = 5;
   */
  startTime = "";

  /**
   * @generated from field: string end_time = 6;
   */
  endTime = "";

  /**
   * @generated from field: uint32 page = 7;
   */
  page = 0;

  /**
   * @generated from field: uint32 size = 8;
   */
  size = 0;

  /**
   * @generated from field: string device = 9;
   */
  device = "";

  constructor(data?: PartialMessage<ScheduleQueryReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ScheduleQueryReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "name_list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "schedule_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "start_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "end_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "page", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 8, name: "size", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 9, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScheduleQueryReq {
    return new ScheduleQueryReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScheduleQueryReq {
    return new ScheduleQueryReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScheduleQueryReq {
    return new ScheduleQueryReq().fromJsonString(jsonString, options);
  }

  static equals(a: ScheduleQueryReq | PlainMessage<ScheduleQueryReq> | undefined, b: ScheduleQueryReq | PlainMessage<ScheduleQueryReq> | undefined): boolean {
    return proto3.util.equals(ScheduleQueryReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ScheduleQueryRsp
 */
export class ScheduleQueryRsp extends Message<ScheduleQueryRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.ScheduleQueryRsp.ScheduleQueryItem data = 3;
   */
  data: ScheduleQueryRsp_ScheduleQueryItem[] = [];

  /**
   * @generated from field: int32 total = 4;
   */
  total = 0;

  constructor(data?: PartialMessage<ScheduleQueryRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ScheduleQueryRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: ScheduleQueryRsp_ScheduleQueryItem, repeated: true },
    { no: 4, name: "total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScheduleQueryRsp {
    return new ScheduleQueryRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScheduleQueryRsp {
    return new ScheduleQueryRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScheduleQueryRsp {
    return new ScheduleQueryRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ScheduleQueryRsp | PlainMessage<ScheduleQueryRsp> | undefined, b: ScheduleQueryRsp | PlainMessage<ScheduleQueryRsp> | undefined): boolean {
    return proto3.util.equals(ScheduleQueryRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ScheduleQueryRsp.ScheduleQueryItem
 */
export class ScheduleQueryRsp_ScheduleQueryItem extends Message<ScheduleQueryRsp_ScheduleQueryItem> {
  /**
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.ScheduleQueryRsp.ScheduleQueryItem.DateItem schedules = 2;
   */
  schedules: ScheduleQueryRsp_ScheduleQueryItem_DateItem[] = [];

  constructor(data?: PartialMessage<ScheduleQueryRsp_ScheduleQueryItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ScheduleQueryRsp.ScheduleQueryItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "schedules", kind: "message", T: ScheduleQueryRsp_ScheduleQueryItem_DateItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScheduleQueryRsp_ScheduleQueryItem {
    return new ScheduleQueryRsp_ScheduleQueryItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScheduleQueryRsp_ScheduleQueryItem {
    return new ScheduleQueryRsp_ScheduleQueryItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScheduleQueryRsp_ScheduleQueryItem {
    return new ScheduleQueryRsp_ScheduleQueryItem().fromJsonString(jsonString, options);
  }

  static equals(a: ScheduleQueryRsp_ScheduleQueryItem | PlainMessage<ScheduleQueryRsp_ScheduleQueryItem> | undefined, b: ScheduleQueryRsp_ScheduleQueryItem | PlainMessage<ScheduleQueryRsp_ScheduleQueryItem> | undefined): boolean {
    return proto3.util.equals(ScheduleQueryRsp_ScheduleQueryItem, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ScheduleQueryRsp.ScheduleQueryItem.DateItem
 */
export class ScheduleQueryRsp_ScheduleQueryItem_DateItem extends Message<ScheduleQueryRsp_ScheduleQueryItem_DateItem> {
  /**
   * @generated from field: string schedule_at = 1;
   */
  scheduleAt = "";

  /**
   * @generated from field: uint32 id = 2;
   */
  id = 0;

  /**
   * @generated from field: uint32 schedule_id = 3;
   */
  scheduleId = 0;

  /**
   * @generated from field: string begin_time = 4;
   */
  beginTime = "";

  /**
   * @generated from field: string end_time = 5;
   */
  endTime = "";

  constructor(data?: PartialMessage<ScheduleQueryRsp_ScheduleQueryItem_DateItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ScheduleQueryRsp.ScheduleQueryItem.DateItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "schedule_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "schedule_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "begin_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "end_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScheduleQueryRsp_ScheduleQueryItem_DateItem {
    return new ScheduleQueryRsp_ScheduleQueryItem_DateItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScheduleQueryRsp_ScheduleQueryItem_DateItem {
    return new ScheduleQueryRsp_ScheduleQueryItem_DateItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScheduleQueryRsp_ScheduleQueryItem_DateItem {
    return new ScheduleQueryRsp_ScheduleQueryItem_DateItem().fromJsonString(jsonString, options);
  }

  static equals(a: ScheduleQueryRsp_ScheduleQueryItem_DateItem | PlainMessage<ScheduleQueryRsp_ScheduleQueryItem_DateItem> | undefined, b: ScheduleQueryRsp_ScheduleQueryItem_DateItem | PlainMessage<ScheduleQueryRsp_ScheduleQueryItem_DateItem> | undefined): boolean {
    return proto3.util.equals(ScheduleQueryRsp_ScheduleQueryItem_DateItem, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ScheduleQueryCSVRsp
 */
export class ScheduleQueryCSVRsp extends Message<ScheduleQueryCSVRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<ScheduleQueryCSVRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ScheduleQueryCSVRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScheduleQueryCSVRsp {
    return new ScheduleQueryCSVRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScheduleQueryCSVRsp {
    return new ScheduleQueryCSVRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScheduleQueryCSVRsp {
    return new ScheduleQueryCSVRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ScheduleQueryCSVRsp | PlainMessage<ScheduleQueryCSVRsp> | undefined, b: ScheduleQueryCSVRsp | PlainMessage<ScheduleQueryCSVRsp> | undefined): boolean {
    return proto3.util.equals(ScheduleQueryCSVRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.AddScheduleReq
 */
export class AddScheduleReq extends Message<AddScheduleReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: repeated string name_list = 3;
   */
  nameList: string[] = [];

  /**
   * @generated from field: repeated uint32 schedule_id = 4;
   */
  scheduleId: number[] = [];

  /**
   * @generated from field: string schedule_at = 5;
   */
  scheduleAt = "";

  /**
   * @generated from field: string device = 6;
   */
  device = "";

  constructor(data?: PartialMessage<AddScheduleReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.AddScheduleReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "name_list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "schedule_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 5, name: "schedule_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddScheduleReq {
    return new AddScheduleReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddScheduleReq {
    return new AddScheduleReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddScheduleReq {
    return new AddScheduleReq().fromJsonString(jsonString, options);
  }

  static equals(a: AddScheduleReq | PlainMessage<AddScheduleReq> | undefined, b: AddScheduleReq | PlainMessage<AddScheduleReq> | undefined): boolean {
    return proto3.util.equals(AddScheduleReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.AddScheduleRsp
 */
export class AddScheduleRsp extends Message<AddScheduleRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<AddScheduleRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.AddScheduleRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddScheduleRsp {
    return new AddScheduleRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddScheduleRsp {
    return new AddScheduleRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddScheduleRsp {
    return new AddScheduleRsp().fromJsonString(jsonString, options);
  }

  static equals(a: AddScheduleRsp | PlainMessage<AddScheduleRsp> | undefined, b: AddScheduleRsp | PlainMessage<AddScheduleRsp> | undefined): boolean {
    return proto3.util.equals(AddScheduleRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.NewProductAddRoleReq
 */
export class NewProductAddRoleReq extends Message<NewProductAddRoleReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: repeated string director = 3;
   */
  director: string[] = [];

  /**
   * @generated from field: repeated string gm = 4;
   */
  gm: string[] = [];

  /**
   * @generated from field: repeated string leader = 5;
   */
  leader: string[] = [];

  /**
   * @generated from field: repeated string prod_admin = 6;
   */
  prodAdmin: string[] = [];

  /**
   * @generated from field: repeated string quality_admin = 7;
   */
  qualityAdmin: string[] = [];

  /**
   * @generated from field: repeated string cs = 8;
   */
  cs: string[] = [];

  /**
   * @generated from field: repeated string om = 9;
   */
  om: string[] = [];

  /**
   * @generated from field: repeated string prod = 10;
   */
  prod: string[] = [];

  /**
   * @generated from field: repeated string rd = 11;
   */
  rd: string[] = [];

  /**
   * @generated from field: string device = 12;
   */
  device = "";

  /**
   * @generated from field: bool is_import = 13;
   */
  isImport = false;

  /**
   * @generated from field: string file_add = 14;
   */
  fileAdd = "";

  /**
   * @generated from field: string cs_position = 15;
   */
  csPosition = "";

  /**
   * @generated from field: string om_position = 16;
   */
  omPosition = "";

  /**
   * @generated from field: string prod_position = 17;
   */
  prodPosition = "";

  /**
   * @generated from field: string rd_position = 18;
   */
  rdPosition = "";

  constructor(data?: PartialMessage<NewProductAddRoleReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.NewProductAddRoleReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "director", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "gm", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "leader", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "prod_admin", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "quality_admin", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "cs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "om", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "prod", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "rd", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "is_import", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "file_add", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "cs_position", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "om_position", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "prod_position", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "rd_position", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NewProductAddRoleReq {
    return new NewProductAddRoleReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NewProductAddRoleReq {
    return new NewProductAddRoleReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NewProductAddRoleReq {
    return new NewProductAddRoleReq().fromJsonString(jsonString, options);
  }

  static equals(a: NewProductAddRoleReq | PlainMessage<NewProductAddRoleReq> | undefined, b: NewProductAddRoleReq | PlainMessage<NewProductAddRoleReq> | undefined): boolean {
    return proto3.util.equals(NewProductAddRoleReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.NewProductAddRoleRsp
 */
export class NewProductAddRoleRsp extends Message<NewProductAddRoleRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<NewProductAddRoleRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.NewProductAddRoleRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NewProductAddRoleRsp {
    return new NewProductAddRoleRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NewProductAddRoleRsp {
    return new NewProductAddRoleRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NewProductAddRoleRsp {
    return new NewProductAddRoleRsp().fromJsonString(jsonString, options);
  }

  static equals(a: NewProductAddRoleRsp | PlainMessage<NewProductAddRoleRsp> | undefined, b: NewProductAddRoleRsp | PlainMessage<NewProductAddRoleRsp> | undefined): boolean {
    return proto3.util.equals(NewProductAddRoleRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.AddTemplateRep
 */
export class AddTemplateRep extends Message<AddTemplateRep> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 template_id = 7;
   */
  templateId = 0;

  /**
   * @generated from field: string template_new_name = 2;
   */
  templateNewName = "";

  /**
   * @generated from field: string search_conditions = 3;
   */
  searchConditions = "";

  /**
   * @generated from field: string search_result_columns = 4;
   */
  searchResultColumns = "";

  /**
   * @generated from field: uint32 sort_order = 5;
   */
  sortOrder = 0;

  /**
   * @generated from field: string sort_column = 6;
   */
  sortColumn = "";

  /**
   * @generated from field: string field_prods = 8;
   */
  fieldProds = "";

  /**
   * @generated from field: string ticket_type = 9;
   */
  ticketType = "";

  constructor(data?: PartialMessage<AddTemplateRep>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.AddTemplateRep";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 7, name: "template_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "template_new_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "search_conditions", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "search_result_columns", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "sort_order", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "sort_column", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "field_prods", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddTemplateRep {
    return new AddTemplateRep().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddTemplateRep {
    return new AddTemplateRep().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddTemplateRep {
    return new AddTemplateRep().fromJsonString(jsonString, options);
  }

  static equals(a: AddTemplateRep | PlainMessage<AddTemplateRep> | undefined, b: AddTemplateRep | PlainMessage<AddTemplateRep> | undefined): boolean {
    return proto3.util.equals(AddTemplateRep, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.AddTemplateRsp
 */
export class AddTemplateRsp extends Message<AddTemplateRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<AddTemplateRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.AddTemplateRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddTemplateRsp {
    return new AddTemplateRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddTemplateRsp {
    return new AddTemplateRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddTemplateRsp {
    return new AddTemplateRsp().fromJsonString(jsonString, options);
  }

  static equals(a: AddTemplateRsp | PlainMessage<AddTemplateRsp> | undefined, b: AddTemplateRsp | PlainMessage<AddTemplateRsp> | undefined): boolean {
    return proto3.util.equals(AddTemplateRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.DelTemplateReq
 */
export class DelTemplateReq extends Message<DelTemplateReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 template_id = 2;
   */
  templateId = 0;

  constructor(data?: PartialMessage<DelTemplateReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.DelTemplateReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "template_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DelTemplateReq {
    return new DelTemplateReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DelTemplateReq {
    return new DelTemplateReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DelTemplateReq {
    return new DelTemplateReq().fromJsonString(jsonString, options);
  }

  static equals(a: DelTemplateReq | PlainMessage<DelTemplateReq> | undefined, b: DelTemplateReq | PlainMessage<DelTemplateReq> | undefined): boolean {
    return proto3.util.equals(DelTemplateReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.DelTemplateRsp
 */
export class DelTemplateRsp extends Message<DelTemplateRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<DelTemplateRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.DelTemplateRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DelTemplateRsp {
    return new DelTemplateRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DelTemplateRsp {
    return new DelTemplateRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DelTemplateRsp {
    return new DelTemplateRsp().fromJsonString(jsonString, options);
  }

  static equals(a: DelTemplateRsp | PlainMessage<DelTemplateRsp> | undefined, b: DelTemplateRsp | PlainMessage<DelTemplateRsp> | undefined): boolean {
    return proto3.util.equals(DelTemplateRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UpdateSortOrdersReq
 */
export class UpdateSortOrdersReq extends Message<UpdateSortOrdersReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 template_id = 2;
   */
  templateId = 0;

  /**
   * @generated from field: string sort_column = 3;
   */
  sortColumn = "";

  /**
   * @generated from field: uint32 sort_order = 4;
   */
  sortOrder = 0;

  constructor(data?: PartialMessage<UpdateSortOrdersReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UpdateSortOrdersReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "template_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "sort_column", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sort_order", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSortOrdersReq {
    return new UpdateSortOrdersReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSortOrdersReq {
    return new UpdateSortOrdersReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSortOrdersReq {
    return new UpdateSortOrdersReq().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSortOrdersReq | PlainMessage<UpdateSortOrdersReq> | undefined, b: UpdateSortOrdersReq | PlainMessage<UpdateSortOrdersReq> | undefined): boolean {
    return proto3.util.equals(UpdateSortOrdersReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UpdateSortOrdersRsp
 */
export class UpdateSortOrdersRsp extends Message<UpdateSortOrdersRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<UpdateSortOrdersRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UpdateSortOrdersRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSortOrdersRsp {
    return new UpdateSortOrdersRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSortOrdersRsp {
    return new UpdateSortOrdersRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSortOrdersRsp {
    return new UpdateSortOrdersRsp().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSortOrdersRsp | PlainMessage<UpdateSortOrdersRsp> | undefined, b: UpdateSortOrdersRsp | PlainMessage<UpdateSortOrdersRsp> | undefined): boolean {
    return proto3.util.equals(UpdateSortOrdersRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetCustomFieldByProdsReq
 */
export class GetCustomFieldByProdsReq extends Message<GetCustomFieldByProdsReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: repeated uint32 prods = 2;
   */
  prods: number[] = [];

  constructor(data?: PartialMessage<GetCustomFieldByProdsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetCustomFieldByProdsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prods", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCustomFieldByProdsReq {
    return new GetCustomFieldByProdsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCustomFieldByProdsReq {
    return new GetCustomFieldByProdsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCustomFieldByProdsReq {
    return new GetCustomFieldByProdsReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetCustomFieldByProdsReq | PlainMessage<GetCustomFieldByProdsReq> | undefined, b: GetCustomFieldByProdsReq | PlainMessage<GetCustomFieldByProdsReq> | undefined): boolean {
    return proto3.util.equals(GetCustomFieldByProdsReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetCustomFieldByProdsRsp
 */
export class GetCustomFieldByProdsRsp extends Message<GetCustomFieldByProdsRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.GetCustomFieldByProdsData data = 3;
   */
  data: GetCustomFieldByProdsData[] = [];

  constructor(data?: PartialMessage<GetCustomFieldByProdsRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetCustomFieldByProdsRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: GetCustomFieldByProdsData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCustomFieldByProdsRsp {
    return new GetCustomFieldByProdsRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCustomFieldByProdsRsp {
    return new GetCustomFieldByProdsRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCustomFieldByProdsRsp {
    return new GetCustomFieldByProdsRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetCustomFieldByProdsRsp | PlainMessage<GetCustomFieldByProdsRsp> | undefined, b: GetCustomFieldByProdsRsp | PlainMessage<GetCustomFieldByProdsRsp> | undefined): boolean {
    return proto3.util.equals(GetCustomFieldByProdsRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetCustomFieldByProdsData
 */
export class GetCustomFieldByProdsData extends Message<GetCustomFieldByProdsData> {
  /**
   * @generated from field: string field_eng_name = 1;
   */
  fieldEngName = "";

  /**
   * @generated from field: string field_cnh_name = 2;
   */
  fieldCnhName = "";

  /**
   * @generated from field: uint32 prod_id = 3;
   */
  prodId = 0;

  constructor(data?: PartialMessage<GetCustomFieldByProdsData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetCustomFieldByProdsData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field_eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "field_cnh_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCustomFieldByProdsData {
    return new GetCustomFieldByProdsData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCustomFieldByProdsData {
    return new GetCustomFieldByProdsData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCustomFieldByProdsData {
    return new GetCustomFieldByProdsData().fromJsonString(jsonString, options);
  }

  static equals(a: GetCustomFieldByProdsData | PlainMessage<GetCustomFieldByProdsData> | undefined, b: GetCustomFieldByProdsData | PlainMessage<GetCustomFieldByProdsData> | undefined): boolean {
    return proto3.util.equals(GetCustomFieldByProdsData, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetSearchFieldsReq
 */
export class GetSearchFieldsReq extends Message<GetSearchFieldsReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 template_id = 2;
   */
  templateId = 0;

  /**
   * @generated from field: bool is_search = 3;
   */
  isSearch = false;

  /**
   * @generated from field: repeated string user_selected_field = 4;
   */
  userSelectedField: string[] = [];

  /**
   * 工单类型 问题单/事件单
   *
   * @generated from field: string ticket_type = 5;
   */
  ticketType = "";

  constructor(data?: PartialMessage<GetSearchFieldsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetSearchFieldsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "template_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "is_search", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "user_selected_field", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSearchFieldsReq {
    return new GetSearchFieldsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSearchFieldsReq {
    return new GetSearchFieldsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSearchFieldsReq {
    return new GetSearchFieldsReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetSearchFieldsReq | PlainMessage<GetSearchFieldsReq> | undefined, b: GetSearchFieldsReq | PlainMessage<GetSearchFieldsReq> | undefined): boolean {
    return proto3.util.equals(GetSearchFieldsReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetSearchFieldsRsp
 */
export class GetSearchFieldsRsp extends Message<GetSearchFieldsRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: string data = 3;
   */
  data = "";

  constructor(data?: PartialMessage<GetSearchFieldsRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetSearchFieldsRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSearchFieldsRsp {
    return new GetSearchFieldsRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSearchFieldsRsp {
    return new GetSearchFieldsRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSearchFieldsRsp {
    return new GetSearchFieldsRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetSearchFieldsRsp | PlainMessage<GetSearchFieldsRsp> | undefined, b: GetSearchFieldsRsp | PlainMessage<GetSearchFieldsRsp> | undefined): boolean {
    return proto3.util.equals(GetSearchFieldsRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UpdateSearchFieldsReq
 */
export class UpdateSearchFieldsReq extends Message<UpdateSearchFieldsReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 template_id = 2;
   */
  templateId = 0;

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.SearchField search_fields = 3;
   */
  searchFields: SearchField[] = [];

  constructor(data?: PartialMessage<UpdateSearchFieldsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UpdateSearchFieldsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "template_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "search_fields", kind: "message", T: SearchField, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSearchFieldsReq {
    return new UpdateSearchFieldsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSearchFieldsReq {
    return new UpdateSearchFieldsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSearchFieldsReq {
    return new UpdateSearchFieldsReq().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSearchFieldsReq | PlainMessage<UpdateSearchFieldsReq> | undefined, b: UpdateSearchFieldsReq | PlainMessage<UpdateSearchFieldsReq> | undefined): boolean {
    return proto3.util.equals(UpdateSearchFieldsReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SearchField
 */
export class SearchField extends Message<SearchField> {
  /**
   * @generated from field: string field_eng_name = 1;
   */
  fieldEngName = "";

  /**
   * @generated from field: string field_cnh_name = 2;
   */
  fieldCnhName = "";

  /**
   * @generated from field: uint32 order = 3;
   */
  order = 0;

  constructor(data?: PartialMessage<SearchField>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SearchField";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field_eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "field_cnh_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchField {
    return new SearchField().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchField {
    return new SearchField().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchField {
    return new SearchField().fromJsonString(jsonString, options);
  }

  static equals(a: SearchField | PlainMessage<SearchField> | undefined, b: SearchField | PlainMessage<SearchField> | undefined): boolean {
    return proto3.util.equals(SearchField, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UpdateSearchFieldsRsp
 */
export class UpdateSearchFieldsRsp extends Message<UpdateSearchFieldsRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<UpdateSearchFieldsRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UpdateSearchFieldsRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSearchFieldsRsp {
    return new UpdateSearchFieldsRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSearchFieldsRsp {
    return new UpdateSearchFieldsRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSearchFieldsRsp {
    return new UpdateSearchFieldsRsp().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSearchFieldsRsp | PlainMessage<UpdateSearchFieldsRsp> | undefined, b: UpdateSearchFieldsRsp | PlainMessage<UpdateSearchFieldsRsp> | undefined): boolean {
    return proto3.util.equals(UpdateSearchFieldsRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.EditScheduleReq
 */
export class EditScheduleReq extends Message<EditScheduleReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.EditScheduleReq.EditItem schedule_list = 3;
   */
  scheduleList: EditScheduleReq_EditItem[] = [];

  /**
   * @generated from field: string device = 4;
   */
  device = "";

  constructor(data?: PartialMessage<EditScheduleReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.EditScheduleReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "schedule_list", kind: "message", T: EditScheduleReq_EditItem, repeated: true },
    { no: 4, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditScheduleReq {
    return new EditScheduleReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditScheduleReq {
    return new EditScheduleReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditScheduleReq {
    return new EditScheduleReq().fromJsonString(jsonString, options);
  }

  static equals(a: EditScheduleReq | PlainMessage<EditScheduleReq> | undefined, b: EditScheduleReq | PlainMessage<EditScheduleReq> | undefined): boolean {
    return proto3.util.equals(EditScheduleReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.EditScheduleReq.EditItem
 */
export class EditScheduleReq_EditItem extends Message<EditScheduleReq_EditItem> {
  /**
   * @generated from field: uint32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string schedule_at = 3;
   */
  scheduleAt = "";

  /**
   * @generated from field: uint32 schedule_id = 4;
   */
  scheduleId = 0;

  constructor(data?: PartialMessage<EditScheduleReq_EditItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.EditScheduleReq.EditItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "schedule_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "schedule_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditScheduleReq_EditItem {
    return new EditScheduleReq_EditItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditScheduleReq_EditItem {
    return new EditScheduleReq_EditItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditScheduleReq_EditItem {
    return new EditScheduleReq_EditItem().fromJsonString(jsonString, options);
  }

  static equals(a: EditScheduleReq_EditItem | PlainMessage<EditScheduleReq_EditItem> | undefined, b: EditScheduleReq_EditItem | PlainMessage<EditScheduleReq_EditItem> | undefined): boolean {
    return proto3.util.equals(EditScheduleReq_EditItem, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.EditScheduleRsp
 */
export class EditScheduleRsp extends Message<EditScheduleRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<EditScheduleRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.EditScheduleRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditScheduleRsp {
    return new EditScheduleRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditScheduleRsp {
    return new EditScheduleRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditScheduleRsp {
    return new EditScheduleRsp().fromJsonString(jsonString, options);
  }

  static equals(a: EditScheduleRsp | PlainMessage<EditScheduleRsp> | undefined, b: EditScheduleRsp | PlainMessage<EditScheduleRsp> | undefined): boolean {
    return proto3.util.equals(EditScheduleRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ImportScheduleReq
 */
export class ImportScheduleReq extends Message<ImportScheduleReq> {
  constructor(data?: PartialMessage<ImportScheduleReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ImportScheduleReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportScheduleReq {
    return new ImportScheduleReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportScheduleReq {
    return new ImportScheduleReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportScheduleReq {
    return new ImportScheduleReq().fromJsonString(jsonString, options);
  }

  static equals(a: ImportScheduleReq | PlainMessage<ImportScheduleReq> | undefined, b: ImportScheduleReq | PlainMessage<ImportScheduleReq> | undefined): boolean {
    return proto3.util.equals(ImportScheduleReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ImportScheduleRsp
 */
export class ImportScheduleRsp extends Message<ImportScheduleRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.UserOnJobStatus data = 3;
   */
  data?: UserOnJobStatus;

  constructor(data?: PartialMessage<ImportScheduleRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ImportScheduleRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: UserOnJobStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportScheduleRsp {
    return new ImportScheduleRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportScheduleRsp {
    return new ImportScheduleRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportScheduleRsp {
    return new ImportScheduleRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ImportScheduleRsp | PlainMessage<ImportScheduleRsp> | undefined, b: ImportScheduleRsp | PlainMessage<ImportScheduleRsp> | undefined): boolean {
    return proto3.util.equals(ImportScheduleRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ConfigOpQueryReq
 */
export class ConfigOpQueryReq extends Message<ConfigOpQueryReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: repeated string name_list = 3;
   */
  nameList: string[] = [];

  /**
   * @generated from field: string start_time = 4;
   */
  startTime = "";

  /**
   * @generated from field: string end_time = 5;
   */
  endTime = "";

  /**
   * @generated from field: uint32 page = 6;
   */
  page = 0;

  /**
   * @generated from field: uint32 size = 7;
   */
  size = 0;

  /**
   * 操作配置类型 product-产品/alarm-告警
   *
   * @generated from field: string type = 8;
   */
  type = "";

  /**
   * @generated from field: string type_detail = 9;
   */
  typeDetail = "";

  constructor(data?: PartialMessage<ConfigOpQueryReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ConfigOpQueryReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "name_list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "start_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "end_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "page", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 7, name: "size", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 8, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "type_detail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfigOpQueryReq {
    return new ConfigOpQueryReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfigOpQueryReq {
    return new ConfigOpQueryReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfigOpQueryReq {
    return new ConfigOpQueryReq().fromJsonString(jsonString, options);
  }

  static equals(a: ConfigOpQueryReq | PlainMessage<ConfigOpQueryReq> | undefined, b: ConfigOpQueryReq | PlainMessage<ConfigOpQueryReq> | undefined): boolean {
    return proto3.util.equals(ConfigOpQueryReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ConfigOpQueryRsp
 */
export class ConfigOpQueryRsp extends Message<ConfigOpQueryRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.ConfigOpQueryRsp.OpQueryItem data = 3;
   */
  data: ConfigOpQueryRsp_OpQueryItem[] = [];

  /**
   * @generated from field: int32 total = 4;
   */
  total = 0;

  constructor(data?: PartialMessage<ConfigOpQueryRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ConfigOpQueryRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: ConfigOpQueryRsp_OpQueryItem, repeated: true },
    { no: 4, name: "total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfigOpQueryRsp {
    return new ConfigOpQueryRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfigOpQueryRsp {
    return new ConfigOpQueryRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfigOpQueryRsp {
    return new ConfigOpQueryRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ConfigOpQueryRsp | PlainMessage<ConfigOpQueryRsp> | undefined, b: ConfigOpQueryRsp | PlainMessage<ConfigOpQueryRsp> | undefined): boolean {
    return proto3.util.equals(ConfigOpQueryRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ConfigOpQueryRsp.OpQueryItem
 */
export class ConfigOpQueryRsp_OpQueryItem extends Message<ConfigOpQueryRsp_OpQueryItem> {
  /**
   * @generated from field: uint32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string user = 2;
   */
  user = "";

  /**
   * @generated from field: string create_at = 3;
   */
  createAt = "";

  /**
   * @generated from field: string op_type = 4;
   */
  opType = "";

  /**
   * @generated from field: string remark = 5;
   */
  remark = "";

  /**
   * @generated from field: string device = 6;
   */
  device = "";

  constructor(data?: PartialMessage<ConfigOpQueryRsp_OpQueryItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ConfigOpQueryRsp.OpQueryItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "user", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "create_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "op_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "remark", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfigOpQueryRsp_OpQueryItem {
    return new ConfigOpQueryRsp_OpQueryItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfigOpQueryRsp_OpQueryItem {
    return new ConfigOpQueryRsp_OpQueryItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfigOpQueryRsp_OpQueryItem {
    return new ConfigOpQueryRsp_OpQueryItem().fromJsonString(jsonString, options);
  }

  static equals(a: ConfigOpQueryRsp_OpQueryItem | PlainMessage<ConfigOpQueryRsp_OpQueryItem> | undefined, b: ConfigOpQueryRsp_OpQueryItem | PlainMessage<ConfigOpQueryRsp_OpQueryItem> | undefined): boolean {
    return proto3.util.equals(ConfigOpQueryRsp_OpQueryItem, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ConfigOpQueryCSVRsp
 */
export class ConfigOpQueryCSVRsp extends Message<ConfigOpQueryCSVRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<ConfigOpQueryCSVRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ConfigOpQueryCSVRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfigOpQueryCSVRsp {
    return new ConfigOpQueryCSVRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfigOpQueryCSVRsp {
    return new ConfigOpQueryCSVRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfigOpQueryCSVRsp {
    return new ConfigOpQueryCSVRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ConfigOpQueryCSVRsp | PlainMessage<ConfigOpQueryCSVRsp> | undefined, b: ConfigOpQueryCSVRsp | PlainMessage<ConfigOpQueryCSVRsp> | undefined): boolean {
    return proto3.util.equals(ConfigOpQueryCSVRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ConfigLogExportReq
 */
export class ConfigLogExportReq extends Message<ConfigLogExportReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: string op_type = 2;
   */
  opType = "";

  /**
   * @generated from field: string device = 3;
   */
  device = "";

  /**
   * @generated from field: string file_add = 4;
   */
  fileAdd = "";

  /**
   * @generated from field: string type = 5;
   */
  type = "";

  constructor(data?: PartialMessage<ConfigLogExportReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ConfigLogExportReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "op_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "file_add", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfigLogExportReq {
    return new ConfigLogExportReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfigLogExportReq {
    return new ConfigLogExportReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfigLogExportReq {
    return new ConfigLogExportReq().fromJsonString(jsonString, options);
  }

  static equals(a: ConfigLogExportReq | PlainMessage<ConfigLogExportReq> | undefined, b: ConfigLogExportReq | PlainMessage<ConfigLogExportReq> | undefined): boolean {
    return proto3.util.equals(ConfigLogExportReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ConfigLogExportRsp
 */
export class ConfigLogExportRsp extends Message<ConfigLogExportRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<ConfigLogExportRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ConfigLogExportRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConfigLogExportRsp {
    return new ConfigLogExportRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConfigLogExportRsp {
    return new ConfigLogExportRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConfigLogExportRsp {
    return new ConfigLogExportRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ConfigLogExportRsp | PlainMessage<ConfigLogExportRsp> | undefined, b: ConfigLogExportRsp | PlainMessage<ConfigLogExportRsp> | undefined): boolean {
    return proto3.util.equals(ConfigLogExportRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UpdateSearchColumnsReq
 */
export class UpdateSearchColumnsReq extends Message<UpdateSearchColumnsReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 template_id = 2;
   */
  templateId = 0;

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.SearchResultColumnsData search_result_columns = 3;
   */
  searchResultColumns: SearchResultColumnsData[] = [];

  constructor(data?: PartialMessage<UpdateSearchColumnsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UpdateSearchColumnsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "template_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "search_result_columns", kind: "message", T: SearchResultColumnsData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSearchColumnsReq {
    return new UpdateSearchColumnsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSearchColumnsReq {
    return new UpdateSearchColumnsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSearchColumnsReq {
    return new UpdateSearchColumnsReq().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSearchColumnsReq | PlainMessage<UpdateSearchColumnsReq> | undefined, b: UpdateSearchColumnsReq | PlainMessage<UpdateSearchColumnsReq> | undefined): boolean {
    return proto3.util.equals(UpdateSearchColumnsReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UpdateSearchColumnsRsp
 */
export class UpdateSearchColumnsRsp extends Message<UpdateSearchColumnsRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<UpdateSearchColumnsRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UpdateSearchColumnsRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateSearchColumnsRsp {
    return new UpdateSearchColumnsRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateSearchColumnsRsp {
    return new UpdateSearchColumnsRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateSearchColumnsRsp {
    return new UpdateSearchColumnsRsp().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateSearchColumnsRsp | PlainMessage<UpdateSearchColumnsRsp> | undefined, b: UpdateSearchColumnsRsp | PlainMessage<UpdateSearchColumnsRsp> | undefined): boolean {
    return proto3.util.equals(UpdateSearchColumnsRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SearchResultColumnsData
 */
export class SearchResultColumnsData extends Message<SearchResultColumnsData> {
  /**
   * @generated from field: string field_eng_name = 1;
   */
  fieldEngName = "";

  /**
   * @generated from field: uint32 order = 2;
   */
  order = 0;

  /**
   * @generated from field: bool selected = 3;
   */
  selected = false;

  constructor(data?: PartialMessage<SearchResultColumnsData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SearchResultColumnsData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "field_eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "selected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchResultColumnsData {
    return new SearchResultColumnsData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchResultColumnsData {
    return new SearchResultColumnsData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchResultColumnsData {
    return new SearchResultColumnsData().fromJsonString(jsonString, options);
  }

  static equals(a: SearchResultColumnsData | PlainMessage<SearchResultColumnsData> | undefined, b: SearchResultColumnsData | PlainMessage<SearchResultColumnsData> | undefined): boolean {
    return proto3.util.equals(SearchResultColumnsData, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetFieldsNameRep
 */
export class GetFieldsNameRep extends Message<GetFieldsNameRep> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 field_group = 2;
   */
  fieldGroup = 0;

  /**
   * @generated from field: uint32 template_id = 3;
   */
  templateId = 0;

  /**
   * @generated from field: string keyword = 4;
   */
  keyword = "";

  constructor(data?: PartialMessage<GetFieldsNameRep>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetFieldsNameRep";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "field_group", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "template_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "keyword", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFieldsNameRep {
    return new GetFieldsNameRep().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFieldsNameRep {
    return new GetFieldsNameRep().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFieldsNameRep {
    return new GetFieldsNameRep().fromJsonString(jsonString, options);
  }

  static equals(a: GetFieldsNameRep | PlainMessage<GetFieldsNameRep> | undefined, b: GetFieldsNameRep | PlainMessage<GetFieldsNameRep> | undefined): boolean {
    return proto3.util.equals(GetFieldsNameRep, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetFieldsNameRsp
 */
export class GetFieldsNameRsp extends Message<GetFieldsNameRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: string field_name = 3;
   */
  fieldName = "";

  /**
   * @generated from field: bool selected = 4;
   */
  selected = false;

  constructor(data?: PartialMessage<GetFieldsNameRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetFieldsNameRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "field_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "selected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetFieldsNameRsp {
    return new GetFieldsNameRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetFieldsNameRsp {
    return new GetFieldsNameRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetFieldsNameRsp {
    return new GetFieldsNameRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetFieldsNameRsp | PlainMessage<GetFieldsNameRsp> | undefined, b: GetFieldsNameRsp | PlainMessage<GetFieldsNameRsp> | undefined): boolean {
    return proto3.util.equals(GetFieldsNameRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetAlarmInfoListReq
 */
export class GetAlarmInfoListReq extends Message<GetAlarmInfoListReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 ticket_id = 2;
   */
  ticketId = 0;

  constructor(data?: PartialMessage<GetAlarmInfoListReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetAlarmInfoListReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "ticket_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAlarmInfoListReq {
    return new GetAlarmInfoListReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAlarmInfoListReq {
    return new GetAlarmInfoListReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAlarmInfoListReq {
    return new GetAlarmInfoListReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetAlarmInfoListReq | PlainMessage<GetAlarmInfoListReq> | undefined, b: GetAlarmInfoListReq | PlainMessage<GetAlarmInfoListReq> | undefined): boolean {
    return proto3.util.equals(GetAlarmInfoListReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetAlarmInfoListRsp
 */
export class GetAlarmInfoListRsp extends Message<GetAlarmInfoListRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.GetAlarmInfoListRsp.AlarmInfo data = 3;
   */
  data: GetAlarmInfoListRsp_AlarmInfo[] = [];

  constructor(data?: PartialMessage<GetAlarmInfoListRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetAlarmInfoListRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: GetAlarmInfoListRsp_AlarmInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAlarmInfoListRsp {
    return new GetAlarmInfoListRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAlarmInfoListRsp {
    return new GetAlarmInfoListRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAlarmInfoListRsp {
    return new GetAlarmInfoListRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetAlarmInfoListRsp | PlainMessage<GetAlarmInfoListRsp> | undefined, b: GetAlarmInfoListRsp | PlainMessage<GetAlarmInfoListRsp> | undefined): boolean {
    return proto3.util.equals(GetAlarmInfoListRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetAlarmInfoListRsp.AlarmInfo
 */
export class GetAlarmInfoListRsp_AlarmInfo extends Message<GetAlarmInfoListRsp_AlarmInfo> {
  /**
   * @generated from field: string alarm_level = 1;
   */
  alarmLevel = "";

  /**
   * @generated from field: string alarm_type = 2;
   */
  alarmType = "";

  /**
   * @generated from field: string category_name = 3;
   */
  categoryName = "";

  /**
   * @generated from field: string rule_name = 4;
   */
  ruleName = "";

  /**
   * @generated from field: string create_at = 5;
   */
  createAt = "";

  /**
   * @generated from field: string receiver = 6;
   */
  receiver = "";

  constructor(data?: PartialMessage<GetAlarmInfoListRsp_AlarmInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetAlarmInfoListRsp.AlarmInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "alarm_level", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "alarm_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "category_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "rule_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "create_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "receiver", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAlarmInfoListRsp_AlarmInfo {
    return new GetAlarmInfoListRsp_AlarmInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAlarmInfoListRsp_AlarmInfo {
    return new GetAlarmInfoListRsp_AlarmInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAlarmInfoListRsp_AlarmInfo {
    return new GetAlarmInfoListRsp_AlarmInfo().fromJsonString(jsonString, options);
  }

  static equals(a: GetAlarmInfoListRsp_AlarmInfo | PlainMessage<GetAlarmInfoListRsp_AlarmInfo> | undefined, b: GetAlarmInfoListRsp_AlarmInfo | PlainMessage<GetAlarmInfoListRsp_AlarmInfo> | undefined): boolean {
    return proto3.util.equals(GetAlarmInfoListRsp_AlarmInfo, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetMergeOptionsReq
 */
export class GetMergeOptionsReq extends Message<GetMergeOptionsReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * engname1;engname2
   *
   * @generated from field: string to_merge_field_names = 2;
   */
  toMergeFieldNames = "";

  /**
   * prod1;prod2
   *
   * @generated from field: string prods = 3;
   */
  prods = "";

  /**
   * @generated from field: string ticket_type = 4;
   */
  ticketType = "";

  constructor(data?: PartialMessage<GetMergeOptionsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetMergeOptionsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "to_merge_field_names", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "prods", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMergeOptionsReq {
    return new GetMergeOptionsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMergeOptionsReq {
    return new GetMergeOptionsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMergeOptionsReq {
    return new GetMergeOptionsReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetMergeOptionsReq | PlainMessage<GetMergeOptionsReq> | undefined, b: GetMergeOptionsReq | PlainMessage<GetMergeOptionsReq> | undefined): boolean {
    return proto3.util.equals(GetMergeOptionsReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetMergeOptionsRsp
 */
export class GetMergeOptionsRsp extends Message<GetMergeOptionsRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: string data = 3;
   */
  data = "";

  constructor(data?: PartialMessage<GetMergeOptionsRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetMergeOptionsRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMergeOptionsRsp {
    return new GetMergeOptionsRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMergeOptionsRsp {
    return new GetMergeOptionsRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMergeOptionsRsp {
    return new GetMergeOptionsRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetMergeOptionsRsp | PlainMessage<GetMergeOptionsRsp> | undefined, b: GetMergeOptionsRsp | PlainMessage<GetMergeOptionsRsp> | undefined): boolean {
    return proto3.util.equals(GetMergeOptionsRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetResourceUrlReq
 */
export class GetResourceUrlReq extends Message<GetResourceUrlReq> {
  constructor(data?: PartialMessage<GetResourceUrlReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetResourceUrlReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetResourceUrlReq {
    return new GetResourceUrlReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetResourceUrlReq {
    return new GetResourceUrlReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetResourceUrlReq {
    return new GetResourceUrlReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetResourceUrlReq | PlainMessage<GetResourceUrlReq> | undefined, b: GetResourceUrlReq | PlainMessage<GetResourceUrlReq> | undefined): boolean {
    return proto3.util.equals(GetResourceUrlReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetResourceUrlRsp
 */
export class GetResourceUrlRsp extends Message<GetResourceUrlRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  constructor(data?: PartialMessage<GetResourceUrlRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetResourceUrlRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetResourceUrlRsp {
    return new GetResourceUrlRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetResourceUrlRsp {
    return new GetResourceUrlRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetResourceUrlRsp {
    return new GetResourceUrlRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetResourceUrlRsp | PlainMessage<GetResourceUrlRsp> | undefined, b: GetResourceUrlRsp | PlainMessage<GetResourceUrlRsp> | undefined): boolean {
    return proto3.util.equals(GetResourceUrlRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductGetRoleListReq
 */
export class ProductGetRoleListReq extends Message<ProductGetRoleListReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: uint32 role_type = 3;
   */
  roleType = 0;

  constructor(data?: PartialMessage<ProductGetRoleListReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductGetRoleListReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "role_type", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductGetRoleListReq {
    return new ProductGetRoleListReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductGetRoleListReq {
    return new ProductGetRoleListReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductGetRoleListReq {
    return new ProductGetRoleListReq().fromJsonString(jsonString, options);
  }

  static equals(a: ProductGetRoleListReq | PlainMessage<ProductGetRoleListReq> | undefined, b: ProductGetRoleListReq | PlainMessage<ProductGetRoleListReq> | undefined): boolean {
    return proto3.util.equals(ProductGetRoleListReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ProductGetRoleListRsp
 */
export class ProductGetRoleListRsp extends Message<ProductGetRoleListRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.RoleList data = 3;
   */
  data: RoleList[] = [];

  constructor(data?: PartialMessage<ProductGetRoleListRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ProductGetRoleListRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: RoleList, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductGetRoleListRsp {
    return new ProductGetRoleListRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductGetRoleListRsp {
    return new ProductGetRoleListRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductGetRoleListRsp {
    return new ProductGetRoleListRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ProductGetRoleListRsp | PlainMessage<ProductGetRoleListRsp> | undefined, b: ProductGetRoleListRsp | PlainMessage<ProductGetRoleListRsp> | undefined): boolean {
    return proto3.util.equals(ProductGetRoleListRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.RoleList
 */
export class RoleList extends Message<RoleList> {
  /**
   * @generated from field: uint32 prod_id = 1;
   */
  prodId = 0;

  /**
   * @generated from field: string role_eng_name = 2;
   */
  roleEngName = "";

  /**
   * @generated from field: string role_cnh_name = 3;
   */
  roleCnhName = "";

  /**
   * @generated from field: uint32 role_type = 4;
   */
  roleType = 0;

  /**
   * @generated from field: string ticket_type = 5;
   */
  ticketType = "";

  constructor(data?: PartialMessage<RoleList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.RoleList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "role_eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role_cnh_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "role_type", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RoleList {
    return new RoleList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RoleList {
    return new RoleList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RoleList {
    return new RoleList().fromJsonString(jsonString, options);
  }

  static equals(a: RoleList | PlainMessage<RoleList> | undefined, b: RoleList | PlainMessage<RoleList> | undefined): boolean {
    return proto3.util.equals(RoleList, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetAlarmConfigReq
 */
export class GetAlarmConfigReq extends Message<GetAlarmConfigReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  constructor(data?: PartialMessage<GetAlarmConfigReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetAlarmConfigReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAlarmConfigReq {
    return new GetAlarmConfigReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAlarmConfigReq {
    return new GetAlarmConfigReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAlarmConfigReq {
    return new GetAlarmConfigReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetAlarmConfigReq | PlainMessage<GetAlarmConfigReq> | undefined, b: GetAlarmConfigReq | PlainMessage<GetAlarmConfigReq> | undefined): boolean {
    return proto3.util.equals(GetAlarmConfigReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetAlarmConfigRsp
 */
export class GetAlarmConfigRsp extends Message<GetAlarmConfigRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: string data = 3;
   */
  data = "";

  constructor(data?: PartialMessage<GetAlarmConfigRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetAlarmConfigRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAlarmConfigRsp {
    return new GetAlarmConfigRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAlarmConfigRsp {
    return new GetAlarmConfigRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAlarmConfigRsp {
    return new GetAlarmConfigRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetAlarmConfigRsp | PlainMessage<GetAlarmConfigRsp> | undefined, b: GetAlarmConfigRsp | PlainMessage<GetAlarmConfigRsp> | undefined): boolean {
    return proto3.util.equals(GetAlarmConfigRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SwitchAlarmStatusReq
 */
export class SwitchAlarmStatusReq extends Message<SwitchAlarmStatusReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: bool enabled = 3;
   */
  enabled = false;

  /**
   * @generated from field: string type = 4;
   */
  type = "";

  /**
   * @generated from field: string device = 5;
   */
  device = "";

  constructor(data?: PartialMessage<SwitchAlarmStatusReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SwitchAlarmStatusReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SwitchAlarmStatusReq {
    return new SwitchAlarmStatusReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SwitchAlarmStatusReq {
    return new SwitchAlarmStatusReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SwitchAlarmStatusReq {
    return new SwitchAlarmStatusReq().fromJsonString(jsonString, options);
  }

  static equals(a: SwitchAlarmStatusReq | PlainMessage<SwitchAlarmStatusReq> | undefined, b: SwitchAlarmStatusReq | PlainMessage<SwitchAlarmStatusReq> | undefined): boolean {
    return proto3.util.equals(SwitchAlarmStatusReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SwitchAlarmStatusRsp
 */
export class SwitchAlarmStatusRsp extends Message<SwitchAlarmStatusRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<SwitchAlarmStatusRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SwitchAlarmStatusRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SwitchAlarmStatusRsp {
    return new SwitchAlarmStatusRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SwitchAlarmStatusRsp {
    return new SwitchAlarmStatusRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SwitchAlarmStatusRsp {
    return new SwitchAlarmStatusRsp().fromJsonString(jsonString, options);
  }

  static equals(a: SwitchAlarmStatusRsp | PlainMessage<SwitchAlarmStatusRsp> | undefined, b: SwitchAlarmStatusRsp | PlainMessage<SwitchAlarmStatusRsp> | undefined): boolean {
    return proto3.util.equals(SwitchAlarmStatusRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CheckUserOnJobStatusReq
 */
export class CheckUserOnJobStatusReq extends Message<CheckUserOnJobStatusReq> {
  /**
   * @generated from field: repeated string name_list = 1;
   */
  nameList: string[] = [];

  constructor(data?: PartialMessage<CheckUserOnJobStatusReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CheckUserOnJobStatusReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name_list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckUserOnJobStatusReq {
    return new CheckUserOnJobStatusReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckUserOnJobStatusReq {
    return new CheckUserOnJobStatusReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckUserOnJobStatusReq {
    return new CheckUserOnJobStatusReq().fromJsonString(jsonString, options);
  }

  static equals(a: CheckUserOnJobStatusReq | PlainMessage<CheckUserOnJobStatusReq> | undefined, b: CheckUserOnJobStatusReq | PlainMessage<CheckUserOnJobStatusReq> | undefined): boolean {
    return proto3.util.equals(CheckUserOnJobStatusReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CheckUserOnJobStatusRsp
 */
export class CheckUserOnJobStatusRsp extends Message<CheckUserOnJobStatusRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.UserOnJobStatus data = 3;
   */
  data?: UserOnJobStatus;

  constructor(data?: PartialMessage<CheckUserOnJobStatusRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CheckUserOnJobStatusRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: UserOnJobStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CheckUserOnJobStatusRsp {
    return new CheckUserOnJobStatusRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CheckUserOnJobStatusRsp {
    return new CheckUserOnJobStatusRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CheckUserOnJobStatusRsp {
    return new CheckUserOnJobStatusRsp().fromJsonString(jsonString, options);
  }

  static equals(a: CheckUserOnJobStatusRsp | PlainMessage<CheckUserOnJobStatusRsp> | undefined, b: CheckUserOnJobStatusRsp | PlainMessage<CheckUserOnJobStatusRsp> | undefined): boolean {
    return proto3.util.equals(CheckUserOnJobStatusRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserOnJobStatus
 */
export class UserOnJobStatus extends Message<UserOnJobStatus> {
  /**
   * @generated from field: repeated string on_job_list = 1;
   */
  onJobList: string[] = [];

  /**
   * @generated from field: repeated string resign_list = 2;
   */
  resignList: string[] = [];

  /**
   * @generated from field: repeated string unfind_list = 3;
   */
  unfindList: string[] = [];

  constructor(data?: PartialMessage<UserOnJobStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserOnJobStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "on_job_list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "resign_list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "unfind_list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserOnJobStatus {
    return new UserOnJobStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserOnJobStatus {
    return new UserOnJobStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserOnJobStatus {
    return new UserOnJobStatus().fromJsonString(jsonString, options);
  }

  static equals(a: UserOnJobStatus | PlainMessage<UserOnJobStatus> | undefined, b: UserOnJobStatus | PlainMessage<UserOnJobStatus> | undefined): boolean {
    return proto3.util.equals(UserOnJobStatus, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveUserProdInfoReq
 */
export class SaveUserProdInfoReq extends Message<SaveUserProdInfoReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  constructor(data?: PartialMessage<SaveUserProdInfoReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveUserProdInfoReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveUserProdInfoReq {
    return new SaveUserProdInfoReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveUserProdInfoReq {
    return new SaveUserProdInfoReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveUserProdInfoReq {
    return new SaveUserProdInfoReq().fromJsonString(jsonString, options);
  }

  static equals(a: SaveUserProdInfoReq | PlainMessage<SaveUserProdInfoReq> | undefined, b: SaveUserProdInfoReq | PlainMessage<SaveUserProdInfoReq> | undefined): boolean {
    return proto3.util.equals(SaveUserProdInfoReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveUserProdInfoRsp
 */
export class SaveUserProdInfoRsp extends Message<SaveUserProdInfoRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<SaveUserProdInfoRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveUserProdInfoRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveUserProdInfoRsp {
    return new SaveUserProdInfoRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveUserProdInfoRsp {
    return new SaveUserProdInfoRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveUserProdInfoRsp {
    return new SaveUserProdInfoRsp().fromJsonString(jsonString, options);
  }

  static equals(a: SaveUserProdInfoRsp | PlainMessage<SaveUserProdInfoRsp> | undefined, b: SaveUserProdInfoRsp | PlainMessage<SaveUserProdInfoRsp> | undefined): boolean {
    return proto3.util.equals(SaveUserProdInfoRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetUserProdInfoReq
 */
export class GetUserProdInfoReq extends Message<GetUserProdInfoReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<GetUserProdInfoReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetUserProdInfoReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserProdInfoReq {
    return new GetUserProdInfoReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserProdInfoReq {
    return new GetUserProdInfoReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserProdInfoReq {
    return new GetUserProdInfoReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserProdInfoReq | PlainMessage<GetUserProdInfoReq> | undefined, b: GetUserProdInfoReq | PlainMessage<GetUserProdInfoReq> | undefined): boolean {
    return proto3.util.equals(GetUserProdInfoReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetUserProdInfoRsp
 */
export class GetUserProdInfoRsp extends Message<GetUserProdInfoRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: uint32 prod_id = 3;
   */
  prodId = 0;

  constructor(data?: PartialMessage<GetUserProdInfoRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetUserProdInfoRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserProdInfoRsp {
    return new GetUserProdInfoRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserProdInfoRsp {
    return new GetUserProdInfoRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserProdInfoRsp {
    return new GetUserProdInfoRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserProdInfoRsp | PlainMessage<GetUserProdInfoRsp> | undefined, b: GetUserProdInfoRsp | PlainMessage<GetUserProdInfoRsp> | undefined): boolean {
    return proto3.util.equals(GetUserProdInfoRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetAlarmDimensionalityReq
 */
export class GetAlarmDimensionalityReq extends Message<GetAlarmDimensionalityReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  constructor(data?: PartialMessage<GetAlarmDimensionalityReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetAlarmDimensionalityReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAlarmDimensionalityReq {
    return new GetAlarmDimensionalityReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAlarmDimensionalityReq {
    return new GetAlarmDimensionalityReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAlarmDimensionalityReq {
    return new GetAlarmDimensionalityReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetAlarmDimensionalityReq | PlainMessage<GetAlarmDimensionalityReq> | undefined, b: GetAlarmDimensionalityReq | PlainMessage<GetAlarmDimensionalityReq> | undefined): boolean {
    return proto3.util.equals(GetAlarmDimensionalityReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetAlarmDimensionalityRsp
 */
export class GetAlarmDimensionalityRsp extends Message<GetAlarmDimensionalityRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.GetAlarmDimensionalityRsp.Data data = 3;
   */
  data?: GetAlarmDimensionalityRsp_Data;

  constructor(data?: PartialMessage<GetAlarmDimensionalityRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetAlarmDimensionalityRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: GetAlarmDimensionalityRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAlarmDimensionalityRsp {
    return new GetAlarmDimensionalityRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAlarmDimensionalityRsp {
    return new GetAlarmDimensionalityRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAlarmDimensionalityRsp {
    return new GetAlarmDimensionalityRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetAlarmDimensionalityRsp | PlainMessage<GetAlarmDimensionalityRsp> | undefined, b: GetAlarmDimensionalityRsp | PlainMessage<GetAlarmDimensionalityRsp> | undefined): boolean {
    return proto3.util.equals(GetAlarmDimensionalityRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetAlarmDimensionalityRsp.Data
 */
export class GetAlarmDimensionalityRsp_Data extends Message<GetAlarmDimensionalityRsp_Data> {
  /**
   * @generated from field: string category = 3;
   */
  category = "";

  /**
   * @generated from field: string priority = 4;
   */
  priority = "";

  constructor(data?: PartialMessage<GetAlarmDimensionalityRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetAlarmDimensionalityRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAlarmDimensionalityRsp_Data {
    return new GetAlarmDimensionalityRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAlarmDimensionalityRsp_Data {
    return new GetAlarmDimensionalityRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAlarmDimensionalityRsp_Data {
    return new GetAlarmDimensionalityRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: GetAlarmDimensionalityRsp_Data | PlainMessage<GetAlarmDimensionalityRsp_Data> | undefined, b: GetAlarmDimensionalityRsp_Data | PlainMessage<GetAlarmDimensionalityRsp_Data> | undefined): boolean {
    return proto3.util.equals(GetAlarmDimensionalityRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveAlarmConfigReq
 */
export class SaveAlarmConfigReq extends Message<SaveAlarmConfigReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  /**
   * @generated from field: string config = 4;
   */
  config = "";

  /**
   * @generated from field: string device = 5;
   */
  device = "";

  constructor(data?: PartialMessage<SaveAlarmConfigReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveAlarmConfigReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "config", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveAlarmConfigReq {
    return new SaveAlarmConfigReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveAlarmConfigReq {
    return new SaveAlarmConfigReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveAlarmConfigReq {
    return new SaveAlarmConfigReq().fromJsonString(jsonString, options);
  }

  static equals(a: SaveAlarmConfigReq | PlainMessage<SaveAlarmConfigReq> | undefined, b: SaveAlarmConfigReq | PlainMessage<SaveAlarmConfigReq> | undefined): boolean {
    return proto3.util.equals(SaveAlarmConfigReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CommonOperationRsp
 */
export class CommonOperationRsp extends Message<CommonOperationRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<CommonOperationRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CommonOperationRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CommonOperationRsp {
    return new CommonOperationRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CommonOperationRsp {
    return new CommonOperationRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CommonOperationRsp {
    return new CommonOperationRsp().fromJsonString(jsonString, options);
  }

  static equals(a: CommonOperationRsp | PlainMessage<CommonOperationRsp> | undefined, b: CommonOperationRsp | PlainMessage<CommonOperationRsp> | undefined): boolean {
    return proto3.util.equals(CommonOperationRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CopyAlarmConfigReq
 */
export class CopyAlarmConfigReq extends Message<CopyAlarmConfigReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 from = 2;
   */
  from = 0;

  /**
   * @generated from field: repeated uint32 to = 3;
   */
  to: number[] = [];

  /**
   * @generated from field: string device = 4;
   */
  device = "";

  constructor(data?: PartialMessage<CopyAlarmConfigReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CopyAlarmConfigReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "from", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "to", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 4, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CopyAlarmConfigReq {
    return new CopyAlarmConfigReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CopyAlarmConfigReq {
    return new CopyAlarmConfigReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CopyAlarmConfigReq {
    return new CopyAlarmConfigReq().fromJsonString(jsonString, options);
  }

  static equals(a: CopyAlarmConfigReq | PlainMessage<CopyAlarmConfigReq> | undefined, b: CopyAlarmConfigReq | PlainMessage<CopyAlarmConfigReq> | undefined): boolean {
    return proto3.util.equals(CopyAlarmConfigReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ExportAlarmConfigReq
 */
export class ExportAlarmConfigReq extends Message<ExportAlarmConfigReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: bool is_blank = 3;
   */
  isBlank = false;

  /**
   * @generated from field: string device = 4;
   */
  device = "";

  constructor(data?: PartialMessage<ExportAlarmConfigReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ExportAlarmConfigReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "is_blank", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportAlarmConfigReq {
    return new ExportAlarmConfigReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportAlarmConfigReq {
    return new ExportAlarmConfigReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportAlarmConfigReq {
    return new ExportAlarmConfigReq().fromJsonString(jsonString, options);
  }

  static equals(a: ExportAlarmConfigReq | PlainMessage<ExportAlarmConfigReq> | undefined, b: ExportAlarmConfigReq | PlainMessage<ExportAlarmConfigReq> | undefined): boolean {
    return proto3.util.equals(ExportAlarmConfigReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ImportAlarmConfigReq
 */
export class ImportAlarmConfigReq extends Message<ImportAlarmConfigReq> {
  constructor(data?: PartialMessage<ImportAlarmConfigReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ImportAlarmConfigReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportAlarmConfigReq {
    return new ImportAlarmConfigReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportAlarmConfigReq {
    return new ImportAlarmConfigReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportAlarmConfigReq {
    return new ImportAlarmConfigReq().fromJsonString(jsonString, options);
  }

  static equals(a: ImportAlarmConfigReq | PlainMessage<ImportAlarmConfigReq> | undefined, b: ImportAlarmConfigReq | PlainMessage<ImportAlarmConfigReq> | undefined): boolean {
    return proto3.util.equals(ImportAlarmConfigReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.NewProductParseRoleReq
 */
export class NewProductParseRoleReq extends Message<NewProductParseRoleReq> {
  constructor(data?: PartialMessage<NewProductParseRoleReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.NewProductParseRoleReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NewProductParseRoleReq {
    return new NewProductParseRoleReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NewProductParseRoleReq {
    return new NewProductParseRoleReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NewProductParseRoleReq {
    return new NewProductParseRoleReq().fromJsonString(jsonString, options);
  }

  static equals(a: NewProductParseRoleReq | PlainMessage<NewProductParseRoleReq> | undefined, b: NewProductParseRoleReq | PlainMessage<NewProductParseRoleReq> | undefined): boolean {
    return proto3.util.equals(NewProductParseRoleReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.NewProductParseRoleRsp
 */
export class NewProductParseRoleRsp extends Message<NewProductParseRoleRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.NewProductParseRoleRsp.Data data = 3;
   */
  data?: NewProductParseRoleRsp_Data;

  constructor(data?: PartialMessage<NewProductParseRoleRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.NewProductParseRoleRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: NewProductParseRoleRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NewProductParseRoleRsp {
    return new NewProductParseRoleRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NewProductParseRoleRsp {
    return new NewProductParseRoleRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NewProductParseRoleRsp {
    return new NewProductParseRoleRsp().fromJsonString(jsonString, options);
  }

  static equals(a: NewProductParseRoleRsp | PlainMessage<NewProductParseRoleRsp> | undefined, b: NewProductParseRoleRsp | PlainMessage<NewProductParseRoleRsp> | undefined): boolean {
    return proto3.util.equals(NewProductParseRoleRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.NewProductParseRoleRsp.Data
 */
export class NewProductParseRoleRsp_Data extends Message<NewProductParseRoleRsp_Data> {
  /**
   * @generated from field: repeated string director = 1;
   */
  director: string[] = [];

  /**
   * @generated from field: repeated string gm = 2;
   */
  gm: string[] = [];

  /**
   * @generated from field: repeated string leader = 3;
   */
  leader: string[] = [];

  /**
   * @generated from field: repeated string prod_admin = 4;
   */
  prodAdmin: string[] = [];

  /**
   * @generated from field: repeated string quality_admin = 5;
   */
  qualityAdmin: string[] = [];

  /**
   * @generated from field: repeated string cs = 6;
   */
  cs: string[] = [];

  /**
   * @generated from field: repeated string om = 7;
   */
  om: string[] = [];

  /**
   * @generated from field: repeated string prod = 8;
   */
  prod: string[] = [];

  /**
   * @generated from field: repeated string rd = 9;
   */
  rd: string[] = [];

  /**
   * @generated from field: repeated string resign_list = 10;
   */
  resignList: string[] = [];

  /**
   * @generated from field: repeated string unfind_list = 11;
   */
  unfindList: string[] = [];

  /**
   * @generated from field: string cs_position = 12;
   */
  csPosition = "";

  /**
   * @generated from field: string om_position = 13;
   */
  omPosition = "";

  /**
   * @generated from field: string prod_position = 14;
   */
  prodPosition = "";

  /**
   * @generated from field: string rd_position = 15;
   */
  rdPosition = "";

  constructor(data?: PartialMessage<NewProductParseRoleRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.NewProductParseRoleRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "director", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "gm", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "leader", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "prod_admin", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "quality_admin", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "cs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "om", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "prod", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "rd", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "resign_list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "unfind_list", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "cs_position", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "om_position", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "prod_position", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "rd_position", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NewProductParseRoleRsp_Data {
    return new NewProductParseRoleRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NewProductParseRoleRsp_Data {
    return new NewProductParseRoleRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NewProductParseRoleRsp_Data {
    return new NewProductParseRoleRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: NewProductParseRoleRsp_Data | PlainMessage<NewProductParseRoleRsp_Data> | undefined, b: NewProductParseRoleRsp_Data | PlainMessage<NewProductParseRoleRsp_Data> | undefined): boolean {
    return proto3.util.equals(NewProductParseRoleRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CopyRoleGroupReq
 */
export class CopyRoleGroupReq extends Message<CopyRoleGroupReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 from_prod_id = 2;
   */
  fromProdId = 0;

  /**
   * @generated from field: repeated uint32 to_prod_id = 3;
   */
  toProdId: number[] = [];

  /**
   * @generated from field: string role_name = 4;
   */
  roleName = "";

  /**
   * @generated from field: string device = 5;
   */
  device = "";

  constructor(data?: PartialMessage<CopyRoleGroupReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CopyRoleGroupReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "from_prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "to_prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 4, name: "role_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CopyRoleGroupReq {
    return new CopyRoleGroupReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CopyRoleGroupReq {
    return new CopyRoleGroupReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CopyRoleGroupReq {
    return new CopyRoleGroupReq().fromJsonString(jsonString, options);
  }

  static equals(a: CopyRoleGroupReq | PlainMessage<CopyRoleGroupReq> | undefined, b: CopyRoleGroupReq | PlainMessage<CopyRoleGroupReq> | undefined): boolean {
    return proto3.util.equals(CopyRoleGroupReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CopyRoleGroupRsp
 */
export class CopyRoleGroupRsp extends Message<CopyRoleGroupRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<CopyRoleGroupRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CopyRoleGroupRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CopyRoleGroupRsp {
    return new CopyRoleGroupRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CopyRoleGroupRsp {
    return new CopyRoleGroupRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CopyRoleGroupRsp {
    return new CopyRoleGroupRsp().fromJsonString(jsonString, options);
  }

  static equals(a: CopyRoleGroupRsp | PlainMessage<CopyRoleGroupRsp> | undefined, b: CopyRoleGroupRsp | PlainMessage<CopyRoleGroupRsp> | undefined): boolean {
    return proto3.util.equals(CopyRoleGroupRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.AppendUserRoleReq
 */
export class AppendUserRoleReq extends Message<AppendUserRoleReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: repeated uint32 prod_id = 2;
   */
  prodId: number[] = [];

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.AppendUserRoleReq.UserInfo user_info = 3;
   */
  userInfo: AppendUserRoleReq_UserInfo[] = [];

  /**
   * @generated from field: string device = 4;
   */
  device = "";

  constructor(data?: PartialMessage<AppendUserRoleReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.AppendUserRoleReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 3, name: "user_info", kind: "message", T: AppendUserRoleReq_UserInfo, repeated: true },
    { no: 4, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppendUserRoleReq {
    return new AppendUserRoleReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppendUserRoleReq {
    return new AppendUserRoleReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppendUserRoleReq {
    return new AppendUserRoleReq().fromJsonString(jsonString, options);
  }

  static equals(a: AppendUserRoleReq | PlainMessage<AppendUserRoleReq> | undefined, b: AppendUserRoleReq | PlainMessage<AppendUserRoleReq> | undefined): boolean {
    return proto3.util.equals(AppendUserRoleReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.AppendUserRoleReq.UserInfo
 */
export class AppendUserRoleReq_UserInfo extends Message<AppendUserRoleReq_UserInfo> {
  /**
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * @generated from field: string role_code = 2;
   */
  roleCode = "";

  /**
   * @generated from field: string role_name = 3;
   */
  roleName = "";

  constructor(data?: PartialMessage<AppendUserRoleReq_UserInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.AppendUserRoleReq.UserInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppendUserRoleReq_UserInfo {
    return new AppendUserRoleReq_UserInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppendUserRoleReq_UserInfo {
    return new AppendUserRoleReq_UserInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppendUserRoleReq_UserInfo {
    return new AppendUserRoleReq_UserInfo().fromJsonString(jsonString, options);
  }

  static equals(a: AppendUserRoleReq_UserInfo | PlainMessage<AppendUserRoleReq_UserInfo> | undefined, b: AppendUserRoleReq_UserInfo | PlainMessage<AppendUserRoleReq_UserInfo> | undefined): boolean {
    return proto3.util.equals(AppendUserRoleReq_UserInfo, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.AppendUserRoleRsp
 */
export class AppendUserRoleRsp extends Message<AppendUserRoleRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<AppendUserRoleRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.AppendUserRoleRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppendUserRoleRsp {
    return new AppendUserRoleRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppendUserRoleRsp {
    return new AppendUserRoleRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppendUserRoleRsp {
    return new AppendUserRoleRsp().fromJsonString(jsonString, options);
  }

  static equals(a: AppendUserRoleRsp | PlainMessage<AppendUserRoleRsp> | undefined, b: AppendUserRoleRsp | PlainMessage<AppendUserRoleRsp> | undefined): boolean {
    return proto3.util.equals(AppendUserRoleRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryFieldsByProdsReq
 */
export class QueryFieldsByProdsReq extends Message<QueryFieldsByProdsReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: repeated uint32 prods = 2;
   */
  prods: number[] = [];

  /**
   * @generated from field: bool is_search = 3;
   */
  isSearch = false;

  /**
   * @generated from field: string ticket_type = 4;
   */
  ticketType = "";

  constructor(data?: PartialMessage<QueryFieldsByProdsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryFieldsByProdsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prods", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 3, name: "is_search", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryFieldsByProdsReq {
    return new QueryFieldsByProdsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryFieldsByProdsReq {
    return new QueryFieldsByProdsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryFieldsByProdsReq {
    return new QueryFieldsByProdsReq().fromJsonString(jsonString, options);
  }

  static equals(a: QueryFieldsByProdsReq | PlainMessage<QueryFieldsByProdsReq> | undefined, b: QueryFieldsByProdsReq | PlainMessage<QueryFieldsByProdsReq> | undefined): boolean {
    return proto3.util.equals(QueryFieldsByProdsReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryFieldsByProdsRsp
 */
export class QueryFieldsByProdsRsp extends Message<QueryFieldsByProdsRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.QueryFieldsByProdsRsp.Data data = 3;
   */
  data: QueryFieldsByProdsRsp_Data[] = [];

  constructor(data?: PartialMessage<QueryFieldsByProdsRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryFieldsByProdsRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: QueryFieldsByProdsRsp_Data, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryFieldsByProdsRsp {
    return new QueryFieldsByProdsRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryFieldsByProdsRsp {
    return new QueryFieldsByProdsRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryFieldsByProdsRsp {
    return new QueryFieldsByProdsRsp().fromJsonString(jsonString, options);
  }

  static equals(a: QueryFieldsByProdsRsp | PlainMessage<QueryFieldsByProdsRsp> | undefined, b: QueryFieldsByProdsRsp | PlainMessage<QueryFieldsByProdsRsp> | undefined): boolean {
    return proto3.util.equals(QueryFieldsByProdsRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryFieldsByProdsRsp.Data
 */
export class QueryFieldsByProdsRsp_Data extends Message<QueryFieldsByProdsRsp_Data> {
  /**
   * @generated from field: uint32 group = 1;
   */
  group = 0;

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.QueryFieldsByProdsRsp.Field fields = 2;
   */
  fields: QueryFieldsByProdsRsp_Field[] = [];

  constructor(data?: PartialMessage<QueryFieldsByProdsRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryFieldsByProdsRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "group", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "fields", kind: "message", T: QueryFieldsByProdsRsp_Field, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryFieldsByProdsRsp_Data {
    return new QueryFieldsByProdsRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryFieldsByProdsRsp_Data {
    return new QueryFieldsByProdsRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryFieldsByProdsRsp_Data {
    return new QueryFieldsByProdsRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: QueryFieldsByProdsRsp_Data | PlainMessage<QueryFieldsByProdsRsp_Data> | undefined, b: QueryFieldsByProdsRsp_Data | PlainMessage<QueryFieldsByProdsRsp_Data> | undefined): boolean {
    return proto3.util.equals(QueryFieldsByProdsRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryFieldsByProdsRsp.Field
 */
export class QueryFieldsByProdsRsp_Field extends Message<QueryFieldsByProdsRsp_Field> {
  /**
   * @generated from field: string eng_name = 1;
   */
  engName = "";

  /**
   * @generated from field: string chn_name = 2;
   */
  chnName = "";

  constructor(data?: PartialMessage<QueryFieldsByProdsRsp_Field>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryFieldsByProdsRsp.Field";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "chn_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryFieldsByProdsRsp_Field {
    return new QueryFieldsByProdsRsp_Field().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryFieldsByProdsRsp_Field {
    return new QueryFieldsByProdsRsp_Field().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryFieldsByProdsRsp_Field {
    return new QueryFieldsByProdsRsp_Field().fromJsonString(jsonString, options);
  }

  static equals(a: QueryFieldsByProdsRsp_Field | PlainMessage<QueryFieldsByProdsRsp_Field> | undefined, b: QueryFieldsByProdsRsp_Field | PlainMessage<QueryFieldsByProdsRsp_Field> | undefined): boolean {
    return proto3.util.equals(QueryFieldsByProdsRsp_Field, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SearchFieldsReq
 */
export class SearchFieldsReq extends Message<SearchFieldsReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * 需要查询的字段类型
   *
   * @generated from field: repeated uint32 field_group = 2;
   */
  fieldGroup: number[] = [];

  /**
   * 工单类型
   *
   * @generated from field: string ticket_type = 3;
   */
  ticketType = "";

  constructor(data?: PartialMessage<SearchFieldsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SearchFieldsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "field_group", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 3, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchFieldsReq {
    return new SearchFieldsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchFieldsReq {
    return new SearchFieldsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchFieldsReq {
    return new SearchFieldsReq().fromJsonString(jsonString, options);
  }

  static equals(a: SearchFieldsReq | PlainMessage<SearchFieldsReq> | undefined, b: SearchFieldsReq | PlainMessage<SearchFieldsReq> | undefined): boolean {
    return proto3.util.equals(SearchFieldsReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SearchFieldsRsp
 */
export class SearchFieldsRsp extends Message<SearchFieldsRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.SearchFieldInfo data = 3;
   */
  data: SearchFieldInfo[] = [];

  constructor(data?: PartialMessage<SearchFieldsRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SearchFieldsRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: SearchFieldInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchFieldsRsp {
    return new SearchFieldsRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchFieldsRsp {
    return new SearchFieldsRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchFieldsRsp {
    return new SearchFieldsRsp().fromJsonString(jsonString, options);
  }

  static equals(a: SearchFieldsRsp | PlainMessage<SearchFieldsRsp> | undefined, b: SearchFieldsRsp | PlainMessage<SearchFieldsRsp> | undefined): boolean {
    return proto3.util.equals(SearchFieldsRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SearchFieldInfo
 */
export class SearchFieldInfo extends Message<SearchFieldInfo> {
  /**
   * @generated from field: uint32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string field_eng_name = 2;
   */
  fieldEngName = "";

  /**
   * @generated from field: string field_chn_name = 3;
   */
  fieldChnName = "";

  /**
   * @generated from field: string field_type = 4;
   */
  fieldType = "";

  /**
   * @generated from field: uint32 field_group = 5;
   */
  fieldGroup = 0;

  /**
   * @generated from field: string options = 6;
   */
  options = "";

  constructor(data?: PartialMessage<SearchFieldInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SearchFieldInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "field_eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "field_chn_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "field_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "field_group", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "options", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchFieldInfo {
    return new SearchFieldInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchFieldInfo {
    return new SearchFieldInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchFieldInfo {
    return new SearchFieldInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SearchFieldInfo | PlainMessage<SearchFieldInfo> | undefined, b: SearchFieldInfo | PlainMessage<SearchFieldInfo> | undefined): boolean {
    return proto3.util.equals(SearchFieldInfo, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetGlobalConfigReq
 */
export class GetGlobalConfigReq extends Message<GetGlobalConfigReq> {
  constructor(data?: PartialMessage<GetGlobalConfigReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetGlobalConfigReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGlobalConfigReq {
    return new GetGlobalConfigReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGlobalConfigReq {
    return new GetGlobalConfigReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGlobalConfigReq {
    return new GetGlobalConfigReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetGlobalConfigReq | PlainMessage<GetGlobalConfigReq> | undefined, b: GetGlobalConfigReq | PlainMessage<GetGlobalConfigReq> | undefined): boolean {
    return proto3.util.equals(GetGlobalConfigReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetGlobalConfigRsp
 */
export class GetGlobalConfigRsp extends Message<GetGlobalConfigRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.GetGlobalConfigRsp.Data data = 3;
   */
  data?: GetGlobalConfigRsp_Data;

  constructor(data?: PartialMessage<GetGlobalConfigRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetGlobalConfigRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: GetGlobalConfigRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGlobalConfigRsp {
    return new GetGlobalConfigRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGlobalConfigRsp {
    return new GetGlobalConfigRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGlobalConfigRsp {
    return new GetGlobalConfigRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetGlobalConfigRsp | PlainMessage<GetGlobalConfigRsp> | undefined, b: GetGlobalConfigRsp | PlainMessage<GetGlobalConfigRsp> | undefined): boolean {
    return proto3.util.equals(GetGlobalConfigRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetGlobalConfigRsp.Data
 */
export class GetGlobalConfigRsp_Data extends Message<GetGlobalConfigRsp_Data> {
  /**
   * @generated from field: bool show_ticket_query_menu = 1;
   */
  showTicketQueryMenu = false;

  /**
   * @generated from field: string permission_import_template_file = 2;
   */
  permissionImportTemplateFile = "";

  /**
   * @generated from field: string quality_check_import_template_file = 3;
   */
  qualityCheckImportTemplateFile = "";

  /**
   * @generated from field: repeated uint32 event_ticket_beta_proj = 4;
   */
  eventTicketBetaProj: number[] = [];

  /**
   * @generated from field: repeated uint32 risk_mark_beta_proj = 5;
   */
  riskMarkBetaProj: number[] = [];

  /**
   * @generated from field: bool risk_mark_beta_proj_enabled = 6;
   */
  riskMarkBetaProjEnabled = false;

  /**
   * @generated from field: repeated uint32 yuanfang_helper_tips_gray_proj = 7;
   */
  yuanfangHelperTipsGrayProj: number[] = [];

  /**
   * @generated from field: uint32 ams_tapd_app_proj = 8;
   */
  amsTapdAppProj = 0;

  constructor(data?: PartialMessage<GetGlobalConfigRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetGlobalConfigRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "show_ticket_query_menu", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "permission_import_template_file", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "quality_check_import_template_file", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "event_ticket_beta_proj", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 5, name: "risk_mark_beta_proj", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 6, name: "risk_mark_beta_proj_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "yuanfang_helper_tips_gray_proj", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 8, name: "ams_tapd_app_proj", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGlobalConfigRsp_Data {
    return new GetGlobalConfigRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGlobalConfigRsp_Data {
    return new GetGlobalConfigRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGlobalConfigRsp_Data {
    return new GetGlobalConfigRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: GetGlobalConfigRsp_Data | PlainMessage<GetGlobalConfigRsp_Data> | undefined, b: GetGlobalConfigRsp_Data | PlainMessage<GetGlobalConfigRsp_Data> | undefined): boolean {
    return proto3.util.equals(GetGlobalConfigRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetProjectCommonConfigReq
 */
export class GetProjectCommonConfigReq extends Message<GetProjectCommonConfigReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  constructor(data?: PartialMessage<GetProjectCommonConfigReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetProjectCommonConfigReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProjectCommonConfigReq {
    return new GetProjectCommonConfigReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProjectCommonConfigReq {
    return new GetProjectCommonConfigReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProjectCommonConfigReq {
    return new GetProjectCommonConfigReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetProjectCommonConfigReq | PlainMessage<GetProjectCommonConfigReq> | undefined, b: GetProjectCommonConfigReq | PlainMessage<GetProjectCommonConfigReq> | undefined): boolean {
    return proto3.util.equals(GetProjectCommonConfigReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetProjectCommonConfigRsp
 */
export class GetProjectCommonConfigRsp extends Message<GetProjectCommonConfigRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: string data = 3;
   */
  data = "";

  constructor(data?: PartialMessage<GetProjectCommonConfigRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetProjectCommonConfigRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProjectCommonConfigRsp {
    return new GetProjectCommonConfigRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProjectCommonConfigRsp {
    return new GetProjectCommonConfigRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProjectCommonConfigRsp {
    return new GetProjectCommonConfigRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetProjectCommonConfigRsp | PlainMessage<GetProjectCommonConfigRsp> | undefined, b: GetProjectCommonConfigRsp | PlainMessage<GetProjectCommonConfigRsp> | undefined): boolean {
    return proto3.util.equals(GetProjectCommonConfigRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetBoardConfigReq
 */
export class GetBoardConfigReq extends Message<GetBoardConfigReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  constructor(data?: PartialMessage<GetBoardConfigReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetBoardConfigReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBoardConfigReq {
    return new GetBoardConfigReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBoardConfigReq {
    return new GetBoardConfigReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBoardConfigReq {
    return new GetBoardConfigReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetBoardConfigReq | PlainMessage<GetBoardConfigReq> | undefined, b: GetBoardConfigReq | PlainMessage<GetBoardConfigReq> | undefined): boolean {
    return proto3.util.equals(GetBoardConfigReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetBoardConfigRsp
 */
export class GetBoardConfigRsp extends Message<GetBoardConfigRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.GetBoardConfigRsp.Data data = 3;
   */
  data: GetBoardConfigRsp_Data[] = [];

  constructor(data?: PartialMessage<GetBoardConfigRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetBoardConfigRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: GetBoardConfigRsp_Data, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBoardConfigRsp {
    return new GetBoardConfigRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBoardConfigRsp {
    return new GetBoardConfigRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBoardConfigRsp {
    return new GetBoardConfigRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetBoardConfigRsp | PlainMessage<GetBoardConfigRsp> | undefined, b: GetBoardConfigRsp | PlainMessage<GetBoardConfigRsp> | undefined): boolean {
    return proto3.util.equals(GetBoardConfigRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetBoardConfigRsp.Data
 */
export class GetBoardConfigRsp_Data extends Message<GetBoardConfigRsp_Data> {
  /**
   * @generated from field: string type = 4;
   */
  type = "";

  /**
   * @generated from field: string page = 5;
   */
  page = "";

  constructor(data?: PartialMessage<GetBoardConfigRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetBoardConfigRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 4, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "page", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBoardConfigRsp_Data {
    return new GetBoardConfigRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBoardConfigRsp_Data {
    return new GetBoardConfigRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBoardConfigRsp_Data {
    return new GetBoardConfigRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: GetBoardConfigRsp_Data | PlainMessage<GetBoardConfigRsp_Data> | undefined, b: GetBoardConfigRsp_Data | PlainMessage<GetBoardConfigRsp_Data> | undefined): boolean {
    return proto3.util.equals(GetBoardConfigRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveBoardConfigReq
 */
export class SaveBoardConfigReq extends Message<SaveBoardConfigReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: string page = 2;
   */
  page = "";

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  constructor(data?: PartialMessage<SaveBoardConfigReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveBoardConfigReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "page", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveBoardConfigReq {
    return new SaveBoardConfigReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveBoardConfigReq {
    return new SaveBoardConfigReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveBoardConfigReq {
    return new SaveBoardConfigReq().fromJsonString(jsonString, options);
  }

  static equals(a: SaveBoardConfigReq | PlainMessage<SaveBoardConfigReq> | undefined, b: SaveBoardConfigReq | PlainMessage<SaveBoardConfigReq> | undefined): boolean {
    return proto3.util.equals(SaveBoardConfigReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveBoardConfigRsp
 */
export class SaveBoardConfigRsp extends Message<SaveBoardConfigRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<SaveBoardConfigRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveBoardConfigRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveBoardConfigRsp {
    return new SaveBoardConfigRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveBoardConfigRsp {
    return new SaveBoardConfigRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveBoardConfigRsp {
    return new SaveBoardConfigRsp().fromJsonString(jsonString, options);
  }

  static equals(a: SaveBoardConfigRsp | PlainMessage<SaveBoardConfigRsp> | undefined, b: SaveBoardConfigRsp | PlainMessage<SaveBoardConfigRsp> | undefined): boolean {
    return proto3.util.equals(SaveBoardConfigRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.EditBoardConfigReq
 */
export class EditBoardConfigReq extends Message<EditBoardConfigReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: string type = 2;
   */
  type = "";

  constructor(data?: PartialMessage<EditBoardConfigReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.EditBoardConfigReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditBoardConfigReq {
    return new EditBoardConfigReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditBoardConfigReq {
    return new EditBoardConfigReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditBoardConfigReq {
    return new EditBoardConfigReq().fromJsonString(jsonString, options);
  }

  static equals(a: EditBoardConfigReq | PlainMessage<EditBoardConfigReq> | undefined, b: EditBoardConfigReq | PlainMessage<EditBoardConfigReq> | undefined): boolean {
    return proto3.util.equals(EditBoardConfigReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.EditBoardConfigRsp
 */
export class EditBoardConfigRsp extends Message<EditBoardConfigRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: bool data = 3;
   */
  data = false;

  constructor(data?: PartialMessage<EditBoardConfigRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.EditBoardConfigRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditBoardConfigRsp {
    return new EditBoardConfigRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditBoardConfigRsp {
    return new EditBoardConfigRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditBoardConfigRsp {
    return new EditBoardConfigRsp().fromJsonString(jsonString, options);
  }

  static equals(a: EditBoardConfigRsp | PlainMessage<EditBoardConfigRsp> | undefined, b: EditBoardConfigRsp | PlainMessage<EditBoardConfigRsp> | undefined): boolean {
    return proto3.util.equals(EditBoardConfigRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CancelBoardConfigReq
 */
export class CancelBoardConfigReq extends Message<CancelBoardConfigReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: string type = 2;
   */
  type = "";

  constructor(data?: PartialMessage<CancelBoardConfigReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CancelBoardConfigReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelBoardConfigReq {
    return new CancelBoardConfigReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelBoardConfigReq {
    return new CancelBoardConfigReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelBoardConfigReq {
    return new CancelBoardConfigReq().fromJsonString(jsonString, options);
  }

  static equals(a: CancelBoardConfigReq | PlainMessage<CancelBoardConfigReq> | undefined, b: CancelBoardConfigReq | PlainMessage<CancelBoardConfigReq> | undefined): boolean {
    return proto3.util.equals(CancelBoardConfigReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CancelBoardConfigRsp
 */
export class CancelBoardConfigRsp extends Message<CancelBoardConfigRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<CancelBoardConfigRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CancelBoardConfigRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelBoardConfigRsp {
    return new CancelBoardConfigRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelBoardConfigRsp {
    return new CancelBoardConfigRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelBoardConfigRsp {
    return new CancelBoardConfigRsp().fromJsonString(jsonString, options);
  }

  static equals(a: CancelBoardConfigRsp | PlainMessage<CancelBoardConfigRsp> | undefined, b: CancelBoardConfigRsp | PlainMessage<CancelBoardConfigRsp> | undefined): boolean {
    return proto3.util.equals(CancelBoardConfigRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetProductByWxworkReq
 */
export class GetProductByWxworkReq extends Message<GetProductByWxworkReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  constructor(data?: PartialMessage<GetProductByWxworkReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetProductByWxworkReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProductByWxworkReq {
    return new GetProductByWxworkReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProductByWxworkReq {
    return new GetProductByWxworkReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProductByWxworkReq {
    return new GetProductByWxworkReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetProductByWxworkReq | PlainMessage<GetProductByWxworkReq> | undefined, b: GetProductByWxworkReq | PlainMessage<GetProductByWxworkReq> | undefined): boolean {
    return proto3.util.equals(GetProductByWxworkReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetProductByWxworkRsp
 */
export class GetProductByWxworkRsp extends Message<GetProductByWxworkRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.GetProductByWxworkRsp.Data data = 3;
   */
  data: GetProductByWxworkRsp_Data[] = [];

  constructor(data?: PartialMessage<GetProductByWxworkRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetProductByWxworkRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: GetProductByWxworkRsp_Data, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProductByWxworkRsp {
    return new GetProductByWxworkRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProductByWxworkRsp {
    return new GetProductByWxworkRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProductByWxworkRsp {
    return new GetProductByWxworkRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetProductByWxworkRsp | PlainMessage<GetProductByWxworkRsp> | undefined, b: GetProductByWxworkRsp | PlainMessage<GetProductByWxworkRsp> | undefined): boolean {
    return proto3.util.equals(GetProductByWxworkRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetProductByWxworkRsp.Data
 */
export class GetProductByWxworkRsp_Data extends Message<GetProductByWxworkRsp_Data> {
  /**
   * @generated from field: uint32 prod_id = 1;
   */
  prodId = 0;

  /**
   * @generated from field: string prod_name = 2;
   */
  prodName = "";

  /**
   * @generated from field: string service_mode = 3;
   */
  serviceMode = "";

  /**
   * @generated from field: bool bind_skill_group = 4;
   */
  bindSkillGroup = false;

  constructor(data?: PartialMessage<GetProductByWxworkRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetProductByWxworkRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "service_mode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "bind_skill_group", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetProductByWxworkRsp_Data {
    return new GetProductByWxworkRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetProductByWxworkRsp_Data {
    return new GetProductByWxworkRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetProductByWxworkRsp_Data {
    return new GetProductByWxworkRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: GetProductByWxworkRsp_Data | PlainMessage<GetProductByWxworkRsp_Data> | undefined, b: GetProductByWxworkRsp_Data | PlainMessage<GetProductByWxworkRsp_Data> | undefined): boolean {
    return proto3.util.equals(GetProductByWxworkRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetCustomCategoryReq
 */
export class GetCustomCategoryReq extends Message<GetCustomCategoryReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string ticket_type = 3;
   */
  ticketType = "";

  constructor(data?: PartialMessage<GetCustomCategoryReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetCustomCategoryReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCustomCategoryReq {
    return new GetCustomCategoryReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCustomCategoryReq {
    return new GetCustomCategoryReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCustomCategoryReq {
    return new GetCustomCategoryReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetCustomCategoryReq | PlainMessage<GetCustomCategoryReq> | undefined, b: GetCustomCategoryReq | PlainMessage<GetCustomCategoryReq> | undefined): boolean {
    return proto3.util.equals(GetCustomCategoryReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetCustomCategoryRsp
 */
export class GetCustomCategoryRsp extends Message<GetCustomCategoryRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.GetCustomCategoryRsp.Data data = 3;
   */
  data: GetCustomCategoryRsp_Data[] = [];

  constructor(data?: PartialMessage<GetCustomCategoryRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetCustomCategoryRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: GetCustomCategoryRsp_Data, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCustomCategoryRsp {
    return new GetCustomCategoryRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCustomCategoryRsp {
    return new GetCustomCategoryRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCustomCategoryRsp {
    return new GetCustomCategoryRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetCustomCategoryRsp | PlainMessage<GetCustomCategoryRsp> | undefined, b: GetCustomCategoryRsp | PlainMessage<GetCustomCategoryRsp> | undefined): boolean {
    return proto3.util.equals(GetCustomCategoryRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetCustomCategoryRsp.Data
 */
export class GetCustomCategoryRsp_Data extends Message<GetCustomCategoryRsp_Data> {
  /**
   * @generated from field: uint32 category_id = 1;
   */
  categoryId = 0;

  /**
   * @generated from field: string category_name = 2;
   */
  categoryName = "";

  /**
   * @generated from field: bool editable = 3;
   */
  editable = false;

  /**
   * @generated from field: bool is_pre_set = 4;
   */
  isPreSet = false;

  constructor(data?: PartialMessage<GetCustomCategoryRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetCustomCategoryRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "category_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "editable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "is_pre_set", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCustomCategoryRsp_Data {
    return new GetCustomCategoryRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCustomCategoryRsp_Data {
    return new GetCustomCategoryRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCustomCategoryRsp_Data {
    return new GetCustomCategoryRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: GetCustomCategoryRsp_Data | PlainMessage<GetCustomCategoryRsp_Data> | undefined, b: GetCustomCategoryRsp_Data | PlainMessage<GetCustomCategoryRsp_Data> | undefined): boolean {
    return proto3.util.equals(GetCustomCategoryRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveCommonTabReq
 */
export class SaveCommonTabReq extends Message<SaveCommonTabReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string data = 3;
   */
  data = "";

  /**
   * @generated from field: string type = 4;
   */
  type = "";

  constructor(data?: PartialMessage<SaveCommonTabReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveCommonTabReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveCommonTabReq {
    return new SaveCommonTabReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveCommonTabReq {
    return new SaveCommonTabReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveCommonTabReq {
    return new SaveCommonTabReq().fromJsonString(jsonString, options);
  }

  static equals(a: SaveCommonTabReq | PlainMessage<SaveCommonTabReq> | undefined, b: SaveCommonTabReq | PlainMessage<SaveCommonTabReq> | undefined): boolean {
    return proto3.util.equals(SaveCommonTabReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveCommonTabRsp
 */
export class SaveCommonTabRsp extends Message<SaveCommonTabRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<SaveCommonTabRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveCommonTabRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveCommonTabRsp {
    return new SaveCommonTabRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveCommonTabRsp {
    return new SaveCommonTabRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveCommonTabRsp {
    return new SaveCommonTabRsp().fromJsonString(jsonString, options);
  }

  static equals(a: SaveCommonTabRsp | PlainMessage<SaveCommonTabRsp> | undefined, b: SaveCommonTabRsp | PlainMessage<SaveCommonTabRsp> | undefined): boolean {
    return proto3.util.equals(SaveCommonTabRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetCommonTabReq
 */
export class GetCommonTabReq extends Message<GetCommonTabReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  constructor(data?: PartialMessage<GetCommonTabReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetCommonTabReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCommonTabReq {
    return new GetCommonTabReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCommonTabReq {
    return new GetCommonTabReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCommonTabReq {
    return new GetCommonTabReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetCommonTabReq | PlainMessage<GetCommonTabReq> | undefined, b: GetCommonTabReq | PlainMessage<GetCommonTabReq> | undefined): boolean {
    return proto3.util.equals(GetCommonTabReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetCommonTabRsp
 */
export class GetCommonTabRsp extends Message<GetCommonTabRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.GetCommonTabRsp.Data data = 3;
   */
  data: GetCommonTabRsp_Data[] = [];

  constructor(data?: PartialMessage<GetCommonTabRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetCommonTabRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: GetCommonTabRsp_Data, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCommonTabRsp {
    return new GetCommonTabRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCommonTabRsp {
    return new GetCommonTabRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCommonTabRsp {
    return new GetCommonTabRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetCommonTabRsp | PlainMessage<GetCommonTabRsp> | undefined, b: GetCommonTabRsp | PlainMessage<GetCommonTabRsp> | undefined): boolean {
    return proto3.util.equals(GetCommonTabRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetCommonTabRsp.Data
 */
export class GetCommonTabRsp_Data extends Message<GetCommonTabRsp_Data> {
  /**
   * @generated from field: string type = 1;
   */
  type = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.GetCommonTabRsp.Data.Content content = 2;
   */
  content: GetCommonTabRsp_Data_Content[] = [];

  constructor(data?: PartialMessage<GetCommonTabRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetCommonTabRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "message", T: GetCommonTabRsp_Data_Content, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCommonTabRsp_Data {
    return new GetCommonTabRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCommonTabRsp_Data {
    return new GetCommonTabRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCommonTabRsp_Data {
    return new GetCommonTabRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: GetCommonTabRsp_Data | PlainMessage<GetCommonTabRsp_Data> | undefined, b: GetCommonTabRsp_Data | PlainMessage<GetCommonTabRsp_Data> | undefined): boolean {
    return proto3.util.equals(GetCommonTabRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetCommonTabRsp.Data.Content
 */
export class GetCommonTabRsp_Data_Content extends Message<GetCommonTabRsp_Data_Content> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  /**
   * @generated from field: string sys_type = 4;
   */
  sysType = "";

  /**
   * @generated from field: int32 sort_no = 5;
   */
  sortNo = 0;

  constructor(data?: PartialMessage<GetCommonTabRsp_Data_Content>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetCommonTabRsp.Data.Content";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sys_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "sort_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCommonTabRsp_Data_Content {
    return new GetCommonTabRsp_Data_Content().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCommonTabRsp_Data_Content {
    return new GetCommonTabRsp_Data_Content().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCommonTabRsp_Data_Content {
    return new GetCommonTabRsp_Data_Content().fromJsonString(jsonString, options);
  }

  static equals(a: GetCommonTabRsp_Data_Content | PlainMessage<GetCommonTabRsp_Data_Content> | undefined, b: GetCommonTabRsp_Data_Content | PlainMessage<GetCommonTabRsp_Data_Content> | undefined): boolean {
    return proto3.util.equals(GetCommonTabRsp_Data_Content, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserInProjectReq
 */
export class UserInProjectReq extends Message<UserInProjectReq> {
  /**
   * @generated from field: repeated string users = 1;
   */
  users: string[] = [];

  /**
   * @generated from field: uint32 proj_id = 2;
   */
  projId = 0;

  constructor(data?: PartialMessage<UserInProjectReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserInProjectReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "users", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserInProjectReq {
    return new UserInProjectReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserInProjectReq {
    return new UserInProjectReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserInProjectReq {
    return new UserInProjectReq().fromJsonString(jsonString, options);
  }

  static equals(a: UserInProjectReq | PlainMessage<UserInProjectReq> | undefined, b: UserInProjectReq | PlainMessage<UserInProjectReq> | undefined): boolean {
    return proto3.util.equals(UserInProjectReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserInProjectRsp
 */
export class UserInProjectRsp extends Message<UserInProjectRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.UserInProjectRsp.Data data = 3;
   */
  data?: UserInProjectRsp_Data;

  constructor(data?: PartialMessage<UserInProjectRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserInProjectRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: UserInProjectRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserInProjectRsp {
    return new UserInProjectRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserInProjectRsp {
    return new UserInProjectRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserInProjectRsp {
    return new UserInProjectRsp().fromJsonString(jsonString, options);
  }

  static equals(a: UserInProjectRsp | PlainMessage<UserInProjectRsp> | undefined, b: UserInProjectRsp | PlainMessage<UserInProjectRsp> | undefined): boolean {
    return proto3.util.equals(UserInProjectRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UserInProjectRsp.Data
 */
export class UserInProjectRsp_Data extends Message<UserInProjectRsp_Data> {
  /**
   * @generated from field: repeated string invalid_users = 1;
   */
  invalidUsers: string[] = [];

  constructor(data?: PartialMessage<UserInProjectRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UserInProjectRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invalid_users", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserInProjectRsp_Data {
    return new UserInProjectRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserInProjectRsp_Data {
    return new UserInProjectRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserInProjectRsp_Data {
    return new UserInProjectRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: UserInProjectRsp_Data | PlainMessage<UserInProjectRsp_Data> | undefined, b: UserInProjectRsp_Data | PlainMessage<UserInProjectRsp_Data> | undefined): boolean {
    return proto3.util.equals(UserInProjectRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetLinkageRulesReq
 */
export class GetLinkageRulesReq extends Message<GetLinkageRulesReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string ticket_type = 3;
   */
  ticketType = "";

  constructor(data?: PartialMessage<GetLinkageRulesReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetLinkageRulesReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLinkageRulesReq {
    return new GetLinkageRulesReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLinkageRulesReq {
    return new GetLinkageRulesReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLinkageRulesReq {
    return new GetLinkageRulesReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetLinkageRulesReq | PlainMessage<GetLinkageRulesReq> | undefined, b: GetLinkageRulesReq | PlainMessage<GetLinkageRulesReq> | undefined): boolean {
    return proto3.util.equals(GetLinkageRulesReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetLinkageRulesRsp
 */
export class GetLinkageRulesRsp extends Message<GetLinkageRulesRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.GetLinkageRulesRsp.Data data = 3;
   */
  data: GetLinkageRulesRsp_Data[] = [];

  constructor(data?: PartialMessage<GetLinkageRulesRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetLinkageRulesRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: GetLinkageRulesRsp_Data, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLinkageRulesRsp {
    return new GetLinkageRulesRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLinkageRulesRsp {
    return new GetLinkageRulesRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLinkageRulesRsp {
    return new GetLinkageRulesRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetLinkageRulesRsp | PlainMessage<GetLinkageRulesRsp> | undefined, b: GetLinkageRulesRsp | PlainMessage<GetLinkageRulesRsp> | undefined): boolean {
    return proto3.util.equals(GetLinkageRulesRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetLinkageRulesRsp.Data
 */
export class GetLinkageRulesRsp_Data extends Message<GetLinkageRulesRsp_Data> {
  /**
   * @generated from field: string fulfill_field = 1;
   */
  fulfillField = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.RuleItem rules = 2;
   */
  rules: RuleItem[] = [];

  constructor(data?: PartialMessage<GetLinkageRulesRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetLinkageRulesRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fulfill_field", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rules", kind: "message", T: RuleItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLinkageRulesRsp_Data {
    return new GetLinkageRulesRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLinkageRulesRsp_Data {
    return new GetLinkageRulesRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLinkageRulesRsp_Data {
    return new GetLinkageRulesRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: GetLinkageRulesRsp_Data | PlainMessage<GetLinkageRulesRsp_Data> | undefined, b: GetLinkageRulesRsp_Data | PlainMessage<GetLinkageRulesRsp_Data> | undefined): boolean {
    return proto3.util.equals(GetLinkageRulesRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.RuleItem
 */
export class RuleItem extends Message<RuleItem> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.Condition conditions = 2;
   */
  conditions?: Condition;

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.FulFillItem fulfill = 3;
   */
  fulfill?: FulFillItem;

  constructor(data?: PartialMessage<RuleItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.RuleItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "conditions", kind: "message", T: Condition },
    { no: 3, name: "fulfill", kind: "message", T: FulFillItem },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RuleItem {
    return new RuleItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RuleItem {
    return new RuleItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RuleItem {
    return new RuleItem().fromJsonString(jsonString, options);
  }

  static equals(a: RuleItem | PlainMessage<RuleItem> | undefined, b: RuleItem | PlainMessage<RuleItem> | undefined): boolean {
    return proto3.util.equals(RuleItem, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.Condition
 */
export class Condition extends Message<Condition> {
  /**
   * @generated from field: string relationship = 1;
   */
  relationship = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.ConditionItem condition = 2;
   */
  condition: ConditionItem[] = [];

  constructor(data?: PartialMessage<Condition>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.Condition";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "relationship", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "condition", kind: "message", T: ConditionItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Condition {
    return new Condition().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Condition {
    return new Condition().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Condition {
    return new Condition().fromJsonString(jsonString, options);
  }

  static equals(a: Condition | PlainMessage<Condition> | undefined, b: Condition | PlainMessage<Condition> | undefined): boolean {
    return proto3.util.equals(Condition, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ConditionItem
 */
export class ConditionItem extends Message<ConditionItem> {
  /**
   * @generated from field: string source = 1;
   */
  source = "";

  /**
   * @generated from field: string operation = 2;
   */
  operation = "";

  /**
   * @generated from field: string value = 3;
   */
  value = "";

  /**
   * @generated from field: float lolimit = 4;
   */
  lolimit = 0;

  /**
   * @generated from field: float uplimit = 5;
   */
  uplimit = 0;

  constructor(data?: PartialMessage<ConditionItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ConditionItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "operation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "lolimit", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "uplimit", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConditionItem {
    return new ConditionItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConditionItem {
    return new ConditionItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConditionItem {
    return new ConditionItem().fromJsonString(jsonString, options);
  }

  static equals(a: ConditionItem | PlainMessage<ConditionItem> | undefined, b: ConditionItem | PlainMessage<ConditionItem> | undefined): boolean {
    return proto3.util.equals(ConditionItem, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.FulFillItem
 */
export class FulFillItem extends Message<FulFillItem> {
  /**
   * @generated from field: string display = 1;
   */
  display = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  /**
   * @generated from field: string satisfaction_tag_tips = 3;
   */
  satisfactionTagTips = "";

  /**
   * @generated from field: repeated string value_tips = 4;
   */
  valueTips: string[] = [];

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.OptionItem options = 5;
   */
  options: OptionItem[] = [];

  /**
   * @generated from field: string data_get_type = 6;
   */
  dataGetType = "";

  /**
   * @generated from field: string data_fill_type = 7;
   */
  dataFillType = "";

  /**
   * @generated from field: string url = 8;
   */
  url = "";

  /**
   * @generated from field: string default = 9;
   */
  default = "";

  /**
   * @generated from field: bool forcing_update = 10;
   */
  forcingUpdate = false;

  constructor(data?: PartialMessage<FulFillItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.FulFillItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "display", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "satisfaction_tag_tips", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "value_tips", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "options", kind: "message", T: OptionItem, repeated: true },
    { no: 6, name: "data_get_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "data_fill_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "default", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "forcing_update", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FulFillItem {
    return new FulFillItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FulFillItem {
    return new FulFillItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FulFillItem {
    return new FulFillItem().fromJsonString(jsonString, options);
  }

  static equals(a: FulFillItem | PlainMessage<FulFillItem> | undefined, b: FulFillItem | PlainMessage<FulFillItem> | undefined): boolean {
    return proto3.util.equals(FulFillItem, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.OptionItem
 */
export class OptionItem extends Message<OptionItem> {
  /**
   * @generated from field: string code = 1;
   */
  code = "";

  /**
   * @generated from field: string desc = 2;
   */
  desc = "";

  /**
   * @generated from field: bool enable = 3;
   */
  enable = false;

  /**
   * @generated from field: bool selected = 4;
   */
  selected = false;

  /**
   * @generated from field: string satisfaction_group = 5;
   */
  satisfactionGroup = "";

  /**
   * @generated from field: repeated string satisfaction_tags = 6;
   */
  satisfactionTags: string[] = [];

  constructor(data?: PartialMessage<OptionItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.OptionItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "desc", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "enable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "selected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "satisfaction_group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "satisfaction_tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OptionItem {
    return new OptionItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OptionItem {
    return new OptionItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OptionItem {
    return new OptionItem().fromJsonString(jsonString, options);
  }

  static equals(a: OptionItem | PlainMessage<OptionItem> | undefined, b: OptionItem | PlainMessage<OptionItem> | undefined): boolean {
    return proto3.util.equals(OptionItem, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UploadLinkageRulesReq
 */
export class UploadLinkageRulesReq extends Message<UploadLinkageRulesReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: string ticket_type = 2;
   */
  ticketType = "";

  constructor(data?: PartialMessage<UploadLinkageRulesReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UploadLinkageRulesReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadLinkageRulesReq {
    return new UploadLinkageRulesReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadLinkageRulesReq {
    return new UploadLinkageRulesReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadLinkageRulesReq {
    return new UploadLinkageRulesReq().fromJsonString(jsonString, options);
  }

  static equals(a: UploadLinkageRulesReq | PlainMessage<UploadLinkageRulesReq> | undefined, b: UploadLinkageRulesReq | PlainMessage<UploadLinkageRulesReq> | undefined): boolean {
    return proto3.util.equals(UploadLinkageRulesReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UploadLinkageRulesRsp
 */
export class UploadLinkageRulesRsp extends Message<UploadLinkageRulesRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<UploadLinkageRulesRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UploadLinkageRulesRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadLinkageRulesRsp {
    return new UploadLinkageRulesRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadLinkageRulesRsp {
    return new UploadLinkageRulesRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadLinkageRulesRsp {
    return new UploadLinkageRulesRsp().fromJsonString(jsonString, options);
  }

  static equals(a: UploadLinkageRulesRsp | PlainMessage<UploadLinkageRulesRsp> | undefined, b: UploadLinkageRulesRsp | PlainMessage<UploadLinkageRulesRsp> | undefined): boolean {
    return proto3.util.equals(UploadLinkageRulesRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.AuthAccessReq
 */
export class AuthAccessReq extends Message<AuthAccessReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: string sys_name = 2;
   */
  sysName = "";

  /**
   * @generated from field: string default_event_type_id = 3;
   */
  defaultEventTypeId = "";

  /**
   * @generated from field: uint32 default_archive_prod_id = 4;
   */
  defaultArchiveProdId = 0;

  /**
   * @generated from field: string default_priority = 5;
   */
  defaultPriority = "";

  /**
   * @generated from field: string default_ticket_source = 6;
   */
  defaultTicketSource = "";

  /**
   * @generated from field: string default_create_from = 7;
   */
  defaultCreateFrom = "";

  /**
   * 限频字段，为JSON格式的字符串
   *
   * @generated from field: string frequency = 8;
   */
  frequency = "";

  /**
   * @generated from field: string hook_url = 9;
   */
  hookUrl = "";

  /**
   * @generated from field: string expand = 10;
   */
  expand = "";

  constructor(data?: PartialMessage<AuthAccessReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.AuthAccessReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "sys_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "default_event_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "default_archive_prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "default_priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "default_ticket_source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "default_create_from", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "frequency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "hook_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "expand", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuthAccessReq {
    return new AuthAccessReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuthAccessReq {
    return new AuthAccessReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuthAccessReq {
    return new AuthAccessReq().fromJsonString(jsonString, options);
  }

  static equals(a: AuthAccessReq | PlainMessage<AuthAccessReq> | undefined, b: AuthAccessReq | PlainMessage<AuthAccessReq> | undefined): boolean {
    return proto3.util.equals(AuthAccessReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.AuthAccessRsp
 */
export class AuthAccessRsp extends Message<AuthAccessRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.AuthAccessRsp.Data data = 3;
   */
  data?: AuthAccessRsp_Data;

  constructor(data?: PartialMessage<AuthAccessRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.AuthAccessRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: AuthAccessRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuthAccessRsp {
    return new AuthAccessRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuthAccessRsp {
    return new AuthAccessRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuthAccessRsp {
    return new AuthAccessRsp().fromJsonString(jsonString, options);
  }

  static equals(a: AuthAccessRsp | PlainMessage<AuthAccessRsp> | undefined, b: AuthAccessRsp | PlainMessage<AuthAccessRsp> | undefined): boolean {
    return proto3.util.equals(AuthAccessRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.AuthAccessRsp.Data
 */
export class AuthAccessRsp_Data extends Message<AuthAccessRsp_Data> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: string sys_name = 2;
   */
  sysName = "";

  /**
   * @generated from field: string auth_token = 3;
   */
  authToken = "";

  constructor(data?: PartialMessage<AuthAccessRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.AuthAccessRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "sys_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuthAccessRsp_Data {
    return new AuthAccessRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuthAccessRsp_Data {
    return new AuthAccessRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuthAccessRsp_Data {
    return new AuthAccessRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: AuthAccessRsp_Data | PlainMessage<AuthAccessRsp_Data> | undefined, b: AuthAccessRsp_Data | PlainMessage<AuthAccessRsp_Data> | undefined): boolean {
    return proto3.util.equals(AuthAccessRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UnAuthAccessReq
 */
export class UnAuthAccessReq extends Message<UnAuthAccessReq> {
  /**
   * @generated from field: string sys_name = 1;
   */
  sysName = "";

  constructor(data?: PartialMessage<UnAuthAccessReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UnAuthAccessReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sys_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnAuthAccessReq {
    return new UnAuthAccessReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnAuthAccessReq {
    return new UnAuthAccessReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnAuthAccessReq {
    return new UnAuthAccessReq().fromJsonString(jsonString, options);
  }

  static equals(a: UnAuthAccessReq | PlainMessage<UnAuthAccessReq> | undefined, b: UnAuthAccessReq | PlainMessage<UnAuthAccessReq> | undefined): boolean {
    return proto3.util.equals(UnAuthAccessReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UnAuthAccessRsp
 */
export class UnAuthAccessRsp extends Message<UnAuthAccessRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.UnAuthAccessRsp.Data data = 3;
   */
  data?: UnAuthAccessRsp_Data;

  constructor(data?: PartialMessage<UnAuthAccessRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UnAuthAccessRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: UnAuthAccessRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnAuthAccessRsp {
    return new UnAuthAccessRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnAuthAccessRsp {
    return new UnAuthAccessRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnAuthAccessRsp {
    return new UnAuthAccessRsp().fromJsonString(jsonString, options);
  }

  static equals(a: UnAuthAccessRsp | PlainMessage<UnAuthAccessRsp> | undefined, b: UnAuthAccessRsp | PlainMessage<UnAuthAccessRsp> | undefined): boolean {
    return proto3.util.equals(UnAuthAccessRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UnAuthAccessRsp.Data
 */
export class UnAuthAccessRsp_Data extends Message<UnAuthAccessRsp_Data> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: string sys_name = 2;
   */
  sysName = "";

  /**
   * @generated from field: string auth_token = 3;
   */
  authToken = "";

  constructor(data?: PartialMessage<UnAuthAccessRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UnAuthAccessRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "sys_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "auth_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnAuthAccessRsp_Data {
    return new UnAuthAccessRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnAuthAccessRsp_Data {
    return new UnAuthAccessRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnAuthAccessRsp_Data {
    return new UnAuthAccessRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: UnAuthAccessRsp_Data | PlainMessage<UnAuthAccessRsp_Data> | undefined, b: UnAuthAccessRsp_Data | PlainMessage<UnAuthAccessRsp_Data> | undefined): boolean {
    return proto3.util.equals(UnAuthAccessRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.UpdateAuthAccessRsp
 */
export class UpdateAuthAccessRsp extends Message<UpdateAuthAccessRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<UpdateAuthAccessRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.UpdateAuthAccessRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAuthAccessRsp {
    return new UpdateAuthAccessRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAuthAccessRsp {
    return new UpdateAuthAccessRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAuthAccessRsp {
    return new UpdateAuthAccessRsp().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAuthAccessRsp | PlainMessage<UpdateAuthAccessRsp> | undefined, b: UpdateAuthAccessRsp | PlainMessage<UpdateAuthAccessRsp> | undefined): boolean {
    return proto3.util.equals(UpdateAuthAccessRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ReloadAuthSysUserReq
 */
export class ReloadAuthSysUserReq extends Message<ReloadAuthSysUserReq> {
  constructor(data?: PartialMessage<ReloadAuthSysUserReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ReloadAuthSysUserReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReloadAuthSysUserReq {
    return new ReloadAuthSysUserReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReloadAuthSysUserReq {
    return new ReloadAuthSysUserReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReloadAuthSysUserReq {
    return new ReloadAuthSysUserReq().fromJsonString(jsonString, options);
  }

  static equals(a: ReloadAuthSysUserReq | PlainMessage<ReloadAuthSysUserReq> | undefined, b: ReloadAuthSysUserReq | PlainMessage<ReloadAuthSysUserReq> | undefined): boolean {
    return proto3.util.equals(ReloadAuthSysUserReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ReloadAuthSysUserRsp
 */
export class ReloadAuthSysUserRsp extends Message<ReloadAuthSysUserRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.ReloadAuthSysUserRsp.Data data = 3;
   */
  data?: ReloadAuthSysUserRsp_Data;

  constructor(data?: PartialMessage<ReloadAuthSysUserRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ReloadAuthSysUserRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: ReloadAuthSysUserRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReloadAuthSysUserRsp {
    return new ReloadAuthSysUserRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReloadAuthSysUserRsp {
    return new ReloadAuthSysUserRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReloadAuthSysUserRsp {
    return new ReloadAuthSysUserRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ReloadAuthSysUserRsp | PlainMessage<ReloadAuthSysUserRsp> | undefined, b: ReloadAuthSysUserRsp | PlainMessage<ReloadAuthSysUserRsp> | undefined): boolean {
    return proto3.util.equals(ReloadAuthSysUserRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ReloadAuthSysUserRsp.Data
 */
export class ReloadAuthSysUserRsp_Data extends Message<ReloadAuthSysUserRsp_Data> {
  /**
   * @generated from field: uint32 failed = 1;
   */
  failed = 0;

  /**
   * @generated from field: uint32 success = 2;
   */
  success = 0;

  /**
   * @generated from field: uint32 count = 3;
   */
  count = 0;

  constructor(data?: PartialMessage<ReloadAuthSysUserRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ReloadAuthSysUserRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "failed", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "success", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "count", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReloadAuthSysUserRsp_Data {
    return new ReloadAuthSysUserRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReloadAuthSysUserRsp_Data {
    return new ReloadAuthSysUserRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReloadAuthSysUserRsp_Data {
    return new ReloadAuthSysUserRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: ReloadAuthSysUserRsp_Data | PlainMessage<ReloadAuthSysUserRsp_Data> | undefined, b: ReloadAuthSysUserRsp_Data | PlainMessage<ReloadAuthSysUserRsp_Data> | undefined): boolean {
    return proto3.util.equals(ReloadAuthSysUserRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CopyLinkageRuleConfigReq
 */
export class CopyLinkageRuleConfigReq extends Message<CopyLinkageRuleConfigReq> {
  /**
   * @generated from field: int32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: int32 from_prod_id = 2;
   */
  fromProdId = 0;

  /**
   * @generated from field: repeated int32 to_prod_id = 3;
   */
  toProdId: number[] = [];

  /**
   * @generated from field: string ticket_type = 4;
   */
  ticketType = "";

  /**
   * @generated from field: string eng_name = 5;
   */
  engName = "";

  constructor(data?: PartialMessage<CopyLinkageRuleConfigReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CopyLinkageRuleConfigReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "from_prod_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "to_prod_id", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 4, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CopyLinkageRuleConfigReq {
    return new CopyLinkageRuleConfigReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CopyLinkageRuleConfigReq {
    return new CopyLinkageRuleConfigReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CopyLinkageRuleConfigReq {
    return new CopyLinkageRuleConfigReq().fromJsonString(jsonString, options);
  }

  static equals(a: CopyLinkageRuleConfigReq | PlainMessage<CopyLinkageRuleConfigReq> | undefined, b: CopyLinkageRuleConfigReq | PlainMessage<CopyLinkageRuleConfigReq> | undefined): boolean {
    return proto3.util.equals(CopyLinkageRuleConfigReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CopyLinkageRuleConfigRsp
 */
export class CopyLinkageRuleConfigRsp extends Message<CopyLinkageRuleConfigRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<CopyLinkageRuleConfigRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CopyLinkageRuleConfigRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CopyLinkageRuleConfigRsp {
    return new CopyLinkageRuleConfigRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CopyLinkageRuleConfigRsp {
    return new CopyLinkageRuleConfigRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CopyLinkageRuleConfigRsp {
    return new CopyLinkageRuleConfigRsp().fromJsonString(jsonString, options);
  }

  static equals(a: CopyLinkageRuleConfigRsp | PlainMessage<CopyLinkageRuleConfigRsp> | undefined, b: CopyLinkageRuleConfigRsp | PlainMessage<CopyLinkageRuleConfigRsp> | undefined): boolean {
    return proto3.util.equals(CopyLinkageRuleConfigRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetLinkageRuleConfigReq
 */
export class GetLinkageRuleConfigReq extends Message<GetLinkageRuleConfigReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string ticket_type = 3;
   */
  ticketType = "";

  /**
   * @generated from field: string eng_name = 4;
   */
  engName = "";

  constructor(data?: PartialMessage<GetLinkageRuleConfigReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetLinkageRuleConfigReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLinkageRuleConfigReq {
    return new GetLinkageRuleConfigReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLinkageRuleConfigReq {
    return new GetLinkageRuleConfigReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLinkageRuleConfigReq {
    return new GetLinkageRuleConfigReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetLinkageRuleConfigReq | PlainMessage<GetLinkageRuleConfigReq> | undefined, b: GetLinkageRuleConfigReq | PlainMessage<GetLinkageRuleConfigReq> | undefined): boolean {
    return proto3.util.equals(GetLinkageRuleConfigReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetLinkageRuleConfigRsp
 */
export class GetLinkageRuleConfigRsp extends Message<GetLinkageRuleConfigRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.GetLinkageRuleConfigRsp.Data data = 3;
   */
  data?: GetLinkageRuleConfigRsp_Data;

  constructor(data?: PartialMessage<GetLinkageRuleConfigRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetLinkageRuleConfigRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: GetLinkageRuleConfigRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLinkageRuleConfigRsp {
    return new GetLinkageRuleConfigRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLinkageRuleConfigRsp {
    return new GetLinkageRuleConfigRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLinkageRuleConfigRsp {
    return new GetLinkageRuleConfigRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetLinkageRuleConfigRsp | PlainMessage<GetLinkageRuleConfigRsp> | undefined, b: GetLinkageRuleConfigRsp | PlainMessage<GetLinkageRuleConfigRsp> | undefined): boolean {
    return proto3.util.equals(GetLinkageRuleConfigRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetLinkageRuleConfigRsp.Data
 */
export class GetLinkageRuleConfigRsp_Data extends Message<GetLinkageRuleConfigRsp_Data> {
  /**
   * @generated from field: string fulfill_field = 1;
   */
  fulfillField = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.RuleItem rules = 2;
   */
  rules: RuleItem[] = [];

  constructor(data?: PartialMessage<GetLinkageRuleConfigRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetLinkageRuleConfigRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fulfill_field", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rules", kind: "message", T: RuleItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLinkageRuleConfigRsp_Data {
    return new GetLinkageRuleConfigRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLinkageRuleConfigRsp_Data {
    return new GetLinkageRuleConfigRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLinkageRuleConfigRsp_Data {
    return new GetLinkageRuleConfigRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: GetLinkageRuleConfigRsp_Data | PlainMessage<GetLinkageRuleConfigRsp_Data> | undefined, b: GetLinkageRuleConfigRsp_Data | PlainMessage<GetLinkageRuleConfigRsp_Data> | undefined): boolean {
    return proto3.util.equals(GetLinkageRuleConfigRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveLinkageRuleConfigReq
 */
export class SaveLinkageRuleConfigReq extends Message<SaveLinkageRuleConfigReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string ticket_type = 3;
   */
  ticketType = "";

  /**
   * @generated from field: string eng_name = 4;
   */
  engName = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.SaveLinkageRuleConfigReq.Data rule = 5;
   */
  rule?: SaveLinkageRuleConfigReq_Data;

  constructor(data?: PartialMessage<SaveLinkageRuleConfigReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveLinkageRuleConfigReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "rule", kind: "message", T: SaveLinkageRuleConfigReq_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveLinkageRuleConfigReq {
    return new SaveLinkageRuleConfigReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveLinkageRuleConfigReq {
    return new SaveLinkageRuleConfigReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveLinkageRuleConfigReq {
    return new SaveLinkageRuleConfigReq().fromJsonString(jsonString, options);
  }

  static equals(a: SaveLinkageRuleConfigReq | PlainMessage<SaveLinkageRuleConfigReq> | undefined, b: SaveLinkageRuleConfigReq | PlainMessage<SaveLinkageRuleConfigReq> | undefined): boolean {
    return proto3.util.equals(SaveLinkageRuleConfigReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveLinkageRuleConfigReq.Data
 */
export class SaveLinkageRuleConfigReq_Data extends Message<SaveLinkageRuleConfigReq_Data> {
  /**
   * @generated from field: string fulfill_field = 1;
   */
  fulfillField = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.RuleItem rules = 2;
   */
  rules: RuleItem[] = [];

  constructor(data?: PartialMessage<SaveLinkageRuleConfigReq_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveLinkageRuleConfigReq.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fulfill_field", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "rules", kind: "message", T: RuleItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveLinkageRuleConfigReq_Data {
    return new SaveLinkageRuleConfigReq_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveLinkageRuleConfigReq_Data {
    return new SaveLinkageRuleConfigReq_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveLinkageRuleConfigReq_Data {
    return new SaveLinkageRuleConfigReq_Data().fromJsonString(jsonString, options);
  }

  static equals(a: SaveLinkageRuleConfigReq_Data | PlainMessage<SaveLinkageRuleConfigReq_Data> | undefined, b: SaveLinkageRuleConfigReq_Data | PlainMessage<SaveLinkageRuleConfigReq_Data> | undefined): boolean {
    return proto3.util.equals(SaveLinkageRuleConfigReq_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveLinkageRuleConfigRsp
 */
export class SaveLinkageRuleConfigRsp extends Message<SaveLinkageRuleConfigRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<SaveLinkageRuleConfigRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveLinkageRuleConfigRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveLinkageRuleConfigRsp {
    return new SaveLinkageRuleConfigRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveLinkageRuleConfigRsp {
    return new SaveLinkageRuleConfigRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveLinkageRuleConfigRsp {
    return new SaveLinkageRuleConfigRsp().fromJsonString(jsonString, options);
  }

  static equals(a: SaveLinkageRuleConfigRsp | PlainMessage<SaveLinkageRuleConfigRsp> | undefined, b: SaveLinkageRuleConfigRsp | PlainMessage<SaveLinkageRuleConfigRsp> | undefined): boolean {
    return proto3.util.equals(SaveLinkageRuleConfigRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.EditCommonTabReq
 */
export class EditCommonTabReq extends Message<EditCommonTabReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  constructor(data?: PartialMessage<EditCommonTabReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.EditCommonTabReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditCommonTabReq {
    return new EditCommonTabReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditCommonTabReq {
    return new EditCommonTabReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditCommonTabReq {
    return new EditCommonTabReq().fromJsonString(jsonString, options);
  }

  static equals(a: EditCommonTabReq | PlainMessage<EditCommonTabReq> | undefined, b: EditCommonTabReq | PlainMessage<EditCommonTabReq> | undefined): boolean {
    return proto3.util.equals(EditCommonTabReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.EditCommonTabRsp
 */
export class EditCommonTabRsp extends Message<EditCommonTabRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: bool data = 3;
   */
  data = false;

  constructor(data?: PartialMessage<EditCommonTabRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.EditCommonTabRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditCommonTabRsp {
    return new EditCommonTabRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditCommonTabRsp {
    return new EditCommonTabRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditCommonTabRsp {
    return new EditCommonTabRsp().fromJsonString(jsonString, options);
  }

  static equals(a: EditCommonTabRsp | PlainMessage<EditCommonTabRsp> | undefined, b: EditCommonTabRsp | PlainMessage<EditCommonTabRsp> | undefined): boolean {
    return proto3.util.equals(EditCommonTabRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CancelCommonTabReq
 */
export class CancelCommonTabReq extends Message<CancelCommonTabReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  constructor(data?: PartialMessage<CancelCommonTabReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CancelCommonTabReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelCommonTabReq {
    return new CancelCommonTabReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelCommonTabReq {
    return new CancelCommonTabReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelCommonTabReq {
    return new CancelCommonTabReq().fromJsonString(jsonString, options);
  }

  static equals(a: CancelCommonTabReq | PlainMessage<CancelCommonTabReq> | undefined, b: CancelCommonTabReq | PlainMessage<CancelCommonTabReq> | undefined): boolean {
    return proto3.util.equals(CancelCommonTabReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CancelCommonTabRsp
 */
export class CancelCommonTabRsp extends Message<CancelCommonTabRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<CancelCommonTabRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CancelCommonTabRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CancelCommonTabRsp {
    return new CancelCommonTabRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CancelCommonTabRsp {
    return new CancelCommonTabRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CancelCommonTabRsp {
    return new CancelCommonTabRsp().fromJsonString(jsonString, options);
  }

  static equals(a: CancelCommonTabRsp | PlainMessage<CancelCommonTabRsp> | undefined, b: CancelCommonTabRsp | PlainMessage<CancelCommonTabRsp> | undefined): boolean {
    return proto3.util.equals(CancelCommonTabRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CopyCommonTabReq
 */
export class CopyCommonTabReq extends Message<CopyCommonTabReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 from_prod_id = 2;
   */
  fromProdId = 0;

  /**
   * @generated from field: repeated uint32 to_prod_ids = 3;
   */
  toProdIds: number[] = [];

  /**
   * @generated from field: string type = 4;
   */
  type = "";

  constructor(data?: PartialMessage<CopyCommonTabReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CopyCommonTabReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "from_prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "to_prod_ids", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 4, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CopyCommonTabReq {
    return new CopyCommonTabReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CopyCommonTabReq {
    return new CopyCommonTabReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CopyCommonTabReq {
    return new CopyCommonTabReq().fromJsonString(jsonString, options);
  }

  static equals(a: CopyCommonTabReq | PlainMessage<CopyCommonTabReq> | undefined, b: CopyCommonTabReq | PlainMessage<CopyCommonTabReq> | undefined): boolean {
    return proto3.util.equals(CopyCommonTabReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CopyCommonTabRsp
 */
export class CopyCommonTabRsp extends Message<CopyCommonTabRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<CopyCommonTabRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CopyCommonTabRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CopyCommonTabRsp {
    return new CopyCommonTabRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CopyCommonTabRsp {
    return new CopyCommonTabRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CopyCommonTabRsp {
    return new CopyCommonTabRsp().fromJsonString(jsonString, options);
  }

  static equals(a: CopyCommonTabRsp | PlainMessage<CopyCommonTabRsp> | undefined, b: CopyCommonTabRsp | PlainMessage<CopyCommonTabRsp> | undefined): boolean {
    return proto3.util.equals(CopyCommonTabRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetUserCommonTabReq
 */
export class GetUserCommonTabReq extends Message<GetUserCommonTabReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  constructor(data?: PartialMessage<GetUserCommonTabReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetUserCommonTabReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserCommonTabReq {
    return new GetUserCommonTabReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserCommonTabReq {
    return new GetUserCommonTabReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserCommonTabReq {
    return new GetUserCommonTabReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserCommonTabReq | PlainMessage<GetUserCommonTabReq> | undefined, b: GetUserCommonTabReq | PlainMessage<GetUserCommonTabReq> | undefined): boolean {
    return proto3.util.equals(GetUserCommonTabReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetUserCommonTabRsp
 */
export class GetUserCommonTabRsp extends Message<GetUserCommonTabRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.GetUserCommonTabRsp.Data data = 3;
   */
  data: GetUserCommonTabRsp_Data[] = [];

  constructor(data?: PartialMessage<GetUserCommonTabRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetUserCommonTabRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: GetUserCommonTabRsp_Data, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserCommonTabRsp {
    return new GetUserCommonTabRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserCommonTabRsp {
    return new GetUserCommonTabRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserCommonTabRsp {
    return new GetUserCommonTabRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserCommonTabRsp | PlainMessage<GetUserCommonTabRsp> | undefined, b: GetUserCommonTabRsp | PlainMessage<GetUserCommonTabRsp> | undefined): boolean {
    return proto3.util.equals(GetUserCommonTabRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetUserCommonTabRsp.Data
 */
export class GetUserCommonTabRsp_Data extends Message<GetUserCommonTabRsp_Data> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  /**
   * @generated from field: string sys_type = 4;
   */
  sysType = "";

  /**
   * @generated from field: int32 sort_no = 5;
   */
  sortNo = 0;

  constructor(data?: PartialMessage<GetUserCommonTabRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetUserCommonTabRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sys_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "sort_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserCommonTabRsp_Data {
    return new GetUserCommonTabRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserCommonTabRsp_Data {
    return new GetUserCommonTabRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserCommonTabRsp_Data {
    return new GetUserCommonTabRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserCommonTabRsp_Data | PlainMessage<GetUserCommonTabRsp_Data> | undefined, b: GetUserCommonTabRsp_Data | PlainMessage<GetUserCommonTabRsp_Data> | undefined): boolean {
    return proto3.util.equals(GetUserCommonTabRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveUserCommonTabReq
 */
export class SaveUserCommonTabReq extends Message<SaveUserCommonTabReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  /**
   * @generated from field: repeated int64 tab_ids = 4;
   */
  tabIds: bigint[] = [];

  constructor(data?: PartialMessage<SaveUserCommonTabReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveUserCommonTabReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "tab_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveUserCommonTabReq {
    return new SaveUserCommonTabReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveUserCommonTabReq {
    return new SaveUserCommonTabReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveUserCommonTabReq {
    return new SaveUserCommonTabReq().fromJsonString(jsonString, options);
  }

  static equals(a: SaveUserCommonTabReq | PlainMessage<SaveUserCommonTabReq> | undefined, b: SaveUserCommonTabReq | PlainMessage<SaveUserCommonTabReq> | undefined): boolean {
    return proto3.util.equals(SaveUserCommonTabReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveUserCommonTabRsp
 */
export class SaveUserCommonTabRsp extends Message<SaveUserCommonTabRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<SaveUserCommonTabRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveUserCommonTabRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveUserCommonTabRsp {
    return new SaveUserCommonTabRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveUserCommonTabRsp {
    return new SaveUserCommonTabRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveUserCommonTabRsp {
    return new SaveUserCommonTabRsp().fromJsonString(jsonString, options);
  }

  static equals(a: SaveUserCommonTabRsp | PlainMessage<SaveUserCommonTabRsp> | undefined, b: SaveUserCommonTabRsp | PlainMessage<SaveUserCommonTabRsp> | undefined): boolean {
    return proto3.util.equals(SaveUserCommonTabRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ImportAutoRecommendRuleReq
 */
export class ImportAutoRecommendRuleReq extends Message<ImportAutoRecommendRuleReq> {
  constructor(data?: PartialMessage<ImportAutoRecommendRuleReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ImportAutoRecommendRuleReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportAutoRecommendRuleReq {
    return new ImportAutoRecommendRuleReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportAutoRecommendRuleReq {
    return new ImportAutoRecommendRuleReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportAutoRecommendRuleReq {
    return new ImportAutoRecommendRuleReq().fromJsonString(jsonString, options);
  }

  static equals(a: ImportAutoRecommendRuleReq | PlainMessage<ImportAutoRecommendRuleReq> | undefined, b: ImportAutoRecommendRuleReq | PlainMessage<ImportAutoRecommendRuleReq> | undefined): boolean {
    return proto3.util.equals(ImportAutoRecommendRuleReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ImportAutoRecommendRuleRsp
 */
export class ImportAutoRecommendRuleRsp extends Message<ImportAutoRecommendRuleRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<ImportAutoRecommendRuleRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ImportAutoRecommendRuleRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportAutoRecommendRuleRsp {
    return new ImportAutoRecommendRuleRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportAutoRecommendRuleRsp {
    return new ImportAutoRecommendRuleRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportAutoRecommendRuleRsp {
    return new ImportAutoRecommendRuleRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ImportAutoRecommendRuleRsp | PlainMessage<ImportAutoRecommendRuleRsp> | undefined, b: ImportAutoRecommendRuleRsp | PlainMessage<ImportAutoRecommendRuleRsp> | undefined): boolean {
    return proto3.util.equals(ImportAutoRecommendRuleRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetAutoRecommendRuleReq
 */
export class GetAutoRecommendRuleReq extends Message<GetAutoRecommendRuleReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  constructor(data?: PartialMessage<GetAutoRecommendRuleReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetAutoRecommendRuleReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAutoRecommendRuleReq {
    return new GetAutoRecommendRuleReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAutoRecommendRuleReq {
    return new GetAutoRecommendRuleReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAutoRecommendRuleReq {
    return new GetAutoRecommendRuleReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetAutoRecommendRuleReq | PlainMessage<GetAutoRecommendRuleReq> | undefined, b: GetAutoRecommendRuleReq | PlainMessage<GetAutoRecommendRuleReq> | undefined): boolean {
    return proto3.util.equals(GetAutoRecommendRuleReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetAutoRecommendRuleRsp
 */
export class GetAutoRecommendRuleRsp extends Message<GetAutoRecommendRuleRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.GetAutoRecommendRuleRsp.Data data = 3;
   */
  data: GetAutoRecommendRuleRsp_Data[] = [];

  constructor(data?: PartialMessage<GetAutoRecommendRuleRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetAutoRecommendRuleRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: GetAutoRecommendRuleRsp_Data, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAutoRecommendRuleRsp {
    return new GetAutoRecommendRuleRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAutoRecommendRuleRsp {
    return new GetAutoRecommendRuleRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAutoRecommendRuleRsp {
    return new GetAutoRecommendRuleRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetAutoRecommendRuleRsp | PlainMessage<GetAutoRecommendRuleRsp> | undefined, b: GetAutoRecommendRuleRsp | PlainMessage<GetAutoRecommendRuleRsp> | undefined): boolean {
    return proto3.util.equals(GetAutoRecommendRuleRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetAutoRecommendRuleRsp.Data
 */
export class GetAutoRecommendRuleRsp_Data extends Message<GetAutoRecommendRuleRsp_Data> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: int32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string operate_type = 3;
   */
  operateType = "";

  /**
   * @generated from field: uint32 operate_prod_id = 4;
   */
  operateProdId = 0;

  /**
   * @generated from field: string operate_prod_queue = 5;
   */
  operateProdQueue = "";

  /**
   * @generated from field: uint32 skill_category_id = 6;
   */
  skillCategoryId = 0;

  /**
   * @generated from field: uint32 skill_id = 7;
   */
  skillId = 0;

  constructor(data?: PartialMessage<GetAutoRecommendRuleRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetAutoRecommendRuleRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "operate_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "operate_prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "operate_prod_queue", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "skill_category_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 7, name: "skill_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAutoRecommendRuleRsp_Data {
    return new GetAutoRecommendRuleRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAutoRecommendRuleRsp_Data {
    return new GetAutoRecommendRuleRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAutoRecommendRuleRsp_Data {
    return new GetAutoRecommendRuleRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: GetAutoRecommendRuleRsp_Data | PlainMessage<GetAutoRecommendRuleRsp_Data> | undefined, b: GetAutoRecommendRuleRsp_Data | PlainMessage<GetAutoRecommendRuleRsp_Data> | undefined): boolean {
    return proto3.util.equals(GetAutoRecommendRuleRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetComplaintConfReq
 */
export class GetComplaintConfReq extends Message<GetComplaintConfReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  constructor(data?: PartialMessage<GetComplaintConfReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetComplaintConfReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComplaintConfReq {
    return new GetComplaintConfReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComplaintConfReq {
    return new GetComplaintConfReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComplaintConfReq {
    return new GetComplaintConfReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetComplaintConfReq | PlainMessage<GetComplaintConfReq> | undefined, b: GetComplaintConfReq | PlainMessage<GetComplaintConfReq> | undefined): boolean {
    return proto3.util.equals(GetComplaintConfReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetComplaintConfRsp
 */
export class GetComplaintConfRsp extends Message<GetComplaintConfRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.GetComplaintConfRsp.Data data = 3;
   */
  data?: GetComplaintConfRsp_Data;

  constructor(data?: PartialMessage<GetComplaintConfRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetComplaintConfRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: GetComplaintConfRsp_Data },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComplaintConfRsp {
    return new GetComplaintConfRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComplaintConfRsp {
    return new GetComplaintConfRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComplaintConfRsp {
    return new GetComplaintConfRsp().fromJsonString(jsonString, options);
  }

  static equals(a: GetComplaintConfRsp | PlainMessage<GetComplaintConfRsp> | undefined, b: GetComplaintConfRsp | PlainMessage<GetComplaintConfRsp> | undefined): boolean {
    return proto3.util.equals(GetComplaintConfRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.GetComplaintConfRsp.Data
 */
export class GetComplaintConfRsp_Data extends Message<GetComplaintConfRsp_Data> {
  /**
   * @generated from field: bool mentioned_all = 1;
   */
  mentionedAll = false;

  /**
   * @generated from field: bool enabled = 2;
   */
  enabled = false;

  /**
   * @generated from field: string content = 3;
   */
  content = "";

  constructor(data?: PartialMessage<GetComplaintConfRsp_Data>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.GetComplaintConfRsp.Data";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mentioned_all", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetComplaintConfRsp_Data {
    return new GetComplaintConfRsp_Data().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetComplaintConfRsp_Data {
    return new GetComplaintConfRsp_Data().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetComplaintConfRsp_Data {
    return new GetComplaintConfRsp_Data().fromJsonString(jsonString, options);
  }

  static equals(a: GetComplaintConfRsp_Data | PlainMessage<GetComplaintConfRsp_Data> | undefined, b: GetComplaintConfRsp_Data | PlainMessage<GetComplaintConfRsp_Data> | undefined): boolean {
    return proto3.util.equals(GetComplaintConfRsp_Data, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveComplaintConfReq
 */
export class SaveComplaintConfReq extends Message<SaveComplaintConfReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: bool mentioned_all = 3;
   */
  mentionedAll = false;

  /**
   * @generated from field: string content = 4;
   */
  content = "";

  /**
   * @generated from field: bool enabled = 5;
   */
  enabled = false;

  /**
   * @generated from field: repeated uint32 rule_id = 6;
   */
  ruleId: number[] = [];

  /**
   * @generated from field: string device = 7;
   */
  device = "";

  /**
   * @generated from field: string ticket_type = 8;
   */
  ticketType = "";

  constructor(data?: PartialMessage<SaveComplaintConfReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveComplaintConfReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "mentioned_all", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "rule_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 7, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveComplaintConfReq {
    return new SaveComplaintConfReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveComplaintConfReq {
    return new SaveComplaintConfReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveComplaintConfReq {
    return new SaveComplaintConfReq().fromJsonString(jsonString, options);
  }

  static equals(a: SaveComplaintConfReq | PlainMessage<SaveComplaintConfReq> | undefined, b: SaveComplaintConfReq | PlainMessage<SaveComplaintConfReq> | undefined): boolean {
    return proto3.util.equals(SaveComplaintConfReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SaveComplaintConfRsp
 */
export class SaveComplaintConfRsp extends Message<SaveComplaintConfRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<SaveComplaintConfRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SaveComplaintConfRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveComplaintConfRsp {
    return new SaveComplaintConfRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveComplaintConfRsp {
    return new SaveComplaintConfRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveComplaintConfRsp {
    return new SaveComplaintConfRsp().fromJsonString(jsonString, options);
  }

  static equals(a: SaveComplaintConfRsp | PlainMessage<SaveComplaintConfRsp> | undefined, b: SaveComplaintConfRsp | PlainMessage<SaveComplaintConfRsp> | undefined): boolean {
    return proto3.util.equals(SaveComplaintConfRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CopyComplaintConfReq
 */
export class CopyComplaintConfReq extends Message<CopyComplaintConfReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 from = 2;
   */
  from = 0;

  /**
   * @generated from field: repeated uint32 to = 3;
   */
  to: number[] = [];

  /**
   * @generated from field: string device = 4;
   */
  device = "";

  /**
   * @generated from field: string ticket_type = 5;
   */
  ticketType = "";

  constructor(data?: PartialMessage<CopyComplaintConfReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CopyComplaintConfReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "from", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "to", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 4, name: "device", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CopyComplaintConfReq {
    return new CopyComplaintConfReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CopyComplaintConfReq {
    return new CopyComplaintConfReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CopyComplaintConfReq {
    return new CopyComplaintConfReq().fromJsonString(jsonString, options);
  }

  static equals(a: CopyComplaintConfReq | PlainMessage<CopyComplaintConfReq> | undefined, b: CopyComplaintConfReq | PlainMessage<CopyComplaintConfReq> | undefined): boolean {
    return proto3.util.equals(CopyComplaintConfReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.CopyComplaintConfRsp
 */
export class CopyComplaintConfRsp extends Message<CopyComplaintConfRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  constructor(data?: PartialMessage<CopyComplaintConfRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.CopyComplaintConfRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CopyComplaintConfRsp {
    return new CopyComplaintConfRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CopyComplaintConfRsp {
    return new CopyComplaintConfRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CopyComplaintConfRsp {
    return new CopyComplaintConfRsp().fromJsonString(jsonString, options);
  }

  static equals(a: CopyComplaintConfRsp | PlainMessage<CopyComplaintConfRsp> | undefined, b: CopyComplaintConfRsp | PlainMessage<CopyComplaintConfRsp> | undefined): boolean {
    return proto3.util.equals(CopyComplaintConfRsp, a, b);
  }
}

/**
 * proj_id=2&prod_id=1&eng_name=xxxx&ticket_type=event
 *
 * @generated from message trpc.teg_devops.servicedesk_product.SearchOptionsReq
 */
export class SearchOptionsReq extends Message<SearchOptionsReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: uint32 prod_id = 2;
   */
  prodId = 0;

  /**
   * @generated from field: string eng_name = 3;
   */
  engName = "";

  /**
   * @generated from field: string ticket_type = 4;
   */
  ticketType = "";

  /**
   * @generated from field: string type = 5;
   */
  type = "";

  /**
   * @generated from field: string keyword = 6;
   */
  keyword = "";

  constructor(data?: PartialMessage<SearchOptionsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SearchOptionsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "ticket_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "keyword", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchOptionsReq {
    return new SearchOptionsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchOptionsReq {
    return new SearchOptionsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchOptionsReq {
    return new SearchOptionsReq().fromJsonString(jsonString, options);
  }

  static equals(a: SearchOptionsReq | PlainMessage<SearchOptionsReq> | undefined, b: SearchOptionsReq | PlainMessage<SearchOptionsReq> | undefined): boolean {
    return proto3.util.equals(SearchOptionsReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.SearchOptionsRsp
 */
export class SearchOptionsRsp extends Message<SearchOptionsRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.FieldInfo data = 3;
   */
  data?: FieldInfo;

  constructor(data?: PartialMessage<SearchOptionsRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.SearchOptionsRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: FieldInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchOptionsRsp {
    return new SearchOptionsRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchOptionsRsp {
    return new SearchOptionsRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchOptionsRsp {
    return new SearchOptionsRsp().fromJsonString(jsonString, options);
  }

  static equals(a: SearchOptionsRsp | PlainMessage<SearchOptionsRsp> | undefined, b: SearchOptionsRsp | PlainMessage<SearchOptionsRsp> | undefined): boolean {
    return proto3.util.equals(SearchOptionsRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryRoleOptionsReq
 */
export class QueryRoleOptionsReq extends Message<QueryRoleOptionsReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: repeated uint32 prod_ids = 2;
   */
  prodIds: number[] = [];

  constructor(data?: PartialMessage<QueryRoleOptionsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryRoleOptionsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_ids", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryRoleOptionsReq {
    return new QueryRoleOptionsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryRoleOptionsReq {
    return new QueryRoleOptionsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryRoleOptionsReq {
    return new QueryRoleOptionsReq().fromJsonString(jsonString, options);
  }

  static equals(a: QueryRoleOptionsReq | PlainMessage<QueryRoleOptionsReq> | undefined, b: QueryRoleOptionsReq | PlainMessage<QueryRoleOptionsReq> | undefined): boolean {
    return proto3.util.equals(QueryRoleOptionsReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryRoleOptionsRsp
 */
export class QueryRoleOptionsRsp extends Message<QueryRoleOptionsRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.RoleOptionInfo data = 3;
   */
  data: RoleOptionInfo[] = [];

  constructor(data?: PartialMessage<QueryRoleOptionsRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryRoleOptionsRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: RoleOptionInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryRoleOptionsRsp {
    return new QueryRoleOptionsRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryRoleOptionsRsp {
    return new QueryRoleOptionsRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryRoleOptionsRsp {
    return new QueryRoleOptionsRsp().fromJsonString(jsonString, options);
  }

  static equals(a: QueryRoleOptionsRsp | PlainMessage<QueryRoleOptionsRsp> | undefined, b: QueryRoleOptionsRsp | PlainMessage<QueryRoleOptionsRsp> | undefined): boolean {
    return proto3.util.equals(QueryRoleOptionsRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.RoleOptionInfo
 */
export class RoleOptionInfo extends Message<RoleOptionInfo> {
  /**
   * @generated from field: string role_code = 1;
   */
  roleCode = "";

  /**
   * @generated from field: string role_name = 2;
   */
  roleName = "";

  /**
   * @generated from field: uint32 role_type = 3;
   */
  roleType = 0;

  /**
   * @generated from field: string position = 4;
   */
  position = "";

  /**
   * @generated from field: uint32 prod_id = 5;
   */
  prodId = 0;

  /**
   * @generated from field: string prod_name = 6;
   */
  prodName = "";

  constructor(data?: PartialMessage<RoleOptionInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.RoleOptionInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "role_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role_type", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "position", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "prod_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RoleOptionInfo {
    return new RoleOptionInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RoleOptionInfo {
    return new RoleOptionInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RoleOptionInfo {
    return new RoleOptionInfo().fromJsonString(jsonString, options);
  }

  static equals(a: RoleOptionInfo | PlainMessage<RoleOptionInfo> | undefined, b: RoleOptionInfo | PlainMessage<RoleOptionInfo> | undefined): boolean {
    return proto3.util.equals(RoleOptionInfo, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryUsersReq
 */
export class QueryUsersReq extends Message<QueryUsersReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: repeated uint32 prod_id = 2;
   */
  prodId: number[] = [];

  /**
   * @generated from field: repeated string role_code = 3;
   */
  roleCode: string[] = [];

  constructor(data?: PartialMessage<QueryUsersReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryUsersReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "prod_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */, repeated: true },
    { no: 3, name: "role_code", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryUsersReq {
    return new QueryUsersReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryUsersReq {
    return new QueryUsersReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryUsersReq {
    return new QueryUsersReq().fromJsonString(jsonString, options);
  }

  static equals(a: QueryUsersReq | PlainMessage<QueryUsersReq> | undefined, b: QueryUsersReq | PlainMessage<QueryUsersReq> | undefined): boolean {
    return proto3.util.equals(QueryUsersReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryUsersRsp
 */
export class QueryUsersRsp extends Message<QueryUsersRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: trpc.teg_devops.servicedesk_product.QueryUsersRspInfo data = 3;
   */
  data?: QueryUsersRspInfo;

  constructor(data?: PartialMessage<QueryUsersRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryUsersRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: QueryUsersRspInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryUsersRsp {
    return new QueryUsersRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryUsersRsp {
    return new QueryUsersRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryUsersRsp {
    return new QueryUsersRsp().fromJsonString(jsonString, options);
  }

  static equals(a: QueryUsersRsp | PlainMessage<QueryUsersRsp> | undefined, b: QueryUsersRsp | PlainMessage<QueryUsersRsp> | undefined): boolean {
    return proto3.util.equals(QueryUsersRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryProdQueueReq
 */
export class QueryProdQueueReq extends Message<QueryProdQueueReq> {
  /**
   * @generated from field: uint32 proj_id = 1;
   */
  projId = 0;

  /**
   * @generated from field: string final_handler = 2;
   */
  finalHandler = "";

  constructor(data?: PartialMessage<QueryProdQueueReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryProdQueueReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "proj_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "final_handler", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryProdQueueReq {
    return new QueryProdQueueReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryProdQueueReq {
    return new QueryProdQueueReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryProdQueueReq {
    return new QueryProdQueueReq().fromJsonString(jsonString, options);
  }

  static equals(a: QueryProdQueueReq | PlainMessage<QueryProdQueueReq> | undefined, b: QueryProdQueueReq | PlainMessage<QueryProdQueueReq> | undefined): boolean {
    return proto3.util.equals(QueryProdQueueReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryProdQueueRsp
 */
export class QueryProdQueueRsp extends Message<QueryProdQueueRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.QueryProdQueueData data = 3;
   */
  data: QueryProdQueueData[] = [];

  constructor(data?: PartialMessage<QueryProdQueueRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryProdQueueRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "data", kind: "message", T: QueryProdQueueData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryProdQueueRsp {
    return new QueryProdQueueRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryProdQueueRsp {
    return new QueryProdQueueRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryProdQueueRsp {
    return new QueryProdQueueRsp().fromJsonString(jsonString, options);
  }

  static equals(a: QueryProdQueueRsp | PlainMessage<QueryProdQueueRsp> | undefined, b: QueryProdQueueRsp | PlainMessage<QueryProdQueueRsp> | undefined): boolean {
    return proto3.util.equals(QueryProdQueueRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryProdQueueData
 */
export class QueryProdQueueData extends Message<QueryProdQueueData> {
  /**
   * 产品id
   *
   * @generated from field: uint32 value = 1;
   */
  value = 0;

  /**
   * 产品name
   *
   * @generated from field: string label = 2;
   */
  label = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.QueryProdQueueData.Children children = 3;
   */
  children: QueryProdQueueData_Children[] = [];

  constructor(data?: PartialMessage<QueryProdQueueData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryProdQueueData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "children", kind: "message", T: QueryProdQueueData_Children, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryProdQueueData {
    return new QueryProdQueueData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryProdQueueData {
    return new QueryProdQueueData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryProdQueueData {
    return new QueryProdQueueData().fromJsonString(jsonString, options);
  }

  static equals(a: QueryProdQueueData | PlainMessage<QueryProdQueueData> | undefined, b: QueryProdQueueData | PlainMessage<QueryProdQueueData> | undefined): boolean {
    return proto3.util.equals(QueryProdQueueData, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryProdQueueData.Children
 */
export class QueryProdQueueData_Children extends Message<QueryProdQueueData_Children> {
  /**
   * 角色code
   *
   * @generated from field: string value = 1;
   */
  value = "";

  /**
   * 角色name
   *
   * @generated from field: string label = 2;
   */
  label = "";

  constructor(data?: PartialMessage<QueryProdQueueData_Children>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryProdQueueData.Children";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryProdQueueData_Children {
    return new QueryProdQueueData_Children().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryProdQueueData_Children {
    return new QueryProdQueueData_Children().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryProdQueueData_Children {
    return new QueryProdQueueData_Children().fromJsonString(jsonString, options);
  }

  static equals(a: QueryProdQueueData_Children | PlainMessage<QueryProdQueueData_Children> | undefined, b: QueryProdQueueData_Children | PlainMessage<QueryProdQueueData_Children> | undefined): boolean {
    return proto3.util.equals(QueryProdQueueData_Children, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.QueryUsersRspInfo
 */
export class QueryUsersRspInfo extends Message<QueryUsersRspInfo> {
  /**
   * @generated from field: repeated string users = 1;
   */
  users: string[] = [];

  constructor(data?: PartialMessage<QueryUsersRspInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.QueryUsersRspInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "users", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QueryUsersRspInfo {
    return new QueryUsersRspInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QueryUsersRspInfo {
    return new QueryUsersRspInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QueryUsersRspInfo {
    return new QueryUsersRspInfo().fromJsonString(jsonString, options);
  }

  static equals(a: QueryUsersRspInfo | PlainMessage<QueryUsersRspInfo> | undefined, b: QueryUsersRspInfo | PlainMessage<QueryUsersRspInfo> | undefined): boolean {
    return proto3.util.equals(QueryUsersRspInfo, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ImportOpenSysUserReq
 */
export class ImportOpenSysUserReq extends Message<ImportOpenSysUserReq> {
  constructor(data?: PartialMessage<ImportOpenSysUserReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ImportOpenSysUserReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportOpenSysUserReq {
    return new ImportOpenSysUserReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportOpenSysUserReq {
    return new ImportOpenSysUserReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportOpenSysUserReq {
    return new ImportOpenSysUserReq().fromJsonString(jsonString, options);
  }

  static equals(a: ImportOpenSysUserReq | PlainMessage<ImportOpenSysUserReq> | undefined, b: ImportOpenSysUserReq | PlainMessage<ImportOpenSysUserReq> | undefined): boolean {
    return proto3.util.equals(ImportOpenSysUserReq, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.ImportOpenSysUserRsp
 */
export class ImportOpenSysUserRsp extends Message<ImportOpenSysUserRsp> {
  /**
   * @generated from field: int32 code = 1;
   */
  code = 0;

  /**
   * @generated from field: string msg = 2;
   */
  msg = "";

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.OpenAPIRspData imported_records = 3;
   */
  importedRecords: OpenAPIRspData[] = [];

  /**
   * @generated from field: repeated trpc.teg_devops.servicedesk_product.OpenAPIRspData duplicate_records = 4;
   */
  duplicateRecords: OpenAPIRspData[] = [];

  /**
   * @generated from field: string status = 5;
   */
  status = "";

  constructor(data?: PartialMessage<ImportOpenSysUserRsp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.ImportOpenSysUserRsp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "imported_records", kind: "message", T: OpenAPIRspData, repeated: true },
    { no: 4, name: "duplicate_records", kind: "message", T: OpenAPIRspData, repeated: true },
    { no: 5, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportOpenSysUserRsp {
    return new ImportOpenSysUserRsp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportOpenSysUserRsp {
    return new ImportOpenSysUserRsp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportOpenSysUserRsp {
    return new ImportOpenSysUserRsp().fromJsonString(jsonString, options);
  }

  static equals(a: ImportOpenSysUserRsp | PlainMessage<ImportOpenSysUserRsp> | undefined, b: ImportOpenSysUserRsp | PlainMessage<ImportOpenSysUserRsp> | undefined): boolean {
    return proto3.util.equals(ImportOpenSysUserRsp, a, b);
  }
}

/**
 * @generated from message trpc.teg_devops.servicedesk_product.OpenAPIRspData
 */
export class OpenAPIRspData extends Message<OpenAPIRspData> {
  /**
   * @generated from field: string project_name = 1;
   */
  projectName = "";

  /**
   * @generated from field: string sys_id = 2;
   */
  sysId = "";

  /**
   * @generated from field: string token = 3;
   */
  token = "";

  constructor(data?: PartialMessage<OpenAPIRspData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "trpc.teg_devops.servicedesk_product.OpenAPIRspData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "project_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sys_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OpenAPIRspData {
    return new OpenAPIRspData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OpenAPIRspData {
    return new OpenAPIRspData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OpenAPIRspData {
    return new OpenAPIRspData().fromJsonString(jsonString, options);
  }

  static equals(a: OpenAPIRspData | PlainMessage<OpenAPIRspData> | undefined, b: OpenAPIRspData | PlainMessage<OpenAPIRspData> | undefined): boolean {
    return proto3.util.equals(OpenAPIRspData, a, b);
  }
}

