// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file proxy.proto (package trpc.servicedesk.servicedesk_proxy, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DelKnowledgeSearchReq, DelKnowledgeSearchRsp, DoRequestReq, DoRequestRsp, GetCommonQuestionReq, GetCommonQuestionRsp, GetWebGlobalSessionIdReq, GetWebGlobalSessionIdRsp, KnowledgeSearchHistoryReq, KnowledgeSearchHistoryRsp, KnowledgeSearchReq, KnowledgeSearchRsp, LoginReq, LoginRsp, LogOffReq, LogOffRsp, QueryStaffReq, QueryStaffRsp } from "./proxy_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service trpc.servicedesk.servicedesk_proxy.Proxy
 */
export const Proxy = {
  typeName: "trpc.servicedesk.servicedesk_proxy.Proxy",
  methods: {
    /**
     * @generated from rpc trpc.servicedesk.servicedesk_proxy.Proxy.Login
     */
    login: {
      name: "Login",
      I: LoginReq,
      O: LoginRsp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc trpc.servicedesk.servicedesk_proxy.Proxy.LogOff
     */
    logOff: {
      name: "LogOff",
      I: LogOffReq,
      O: LogOffRsp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc trpc.servicedesk.servicedesk_proxy.Proxy.QueryStaff
     */
    queryStaff: {
      name: "QueryStaff",
      I: QueryStaffReq,
      O: QueryStaffRsp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc trpc.servicedesk.servicedesk_proxy.Proxy.DoRequest
     */
    doRequest: {
      name: "DoRequest",
      I: DoRequestReq,
      O: DoRequestRsp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc trpc.servicedesk.servicedesk_proxy.Proxy.GetWebGlobalSessionId
     */
    getWebGlobalSessionId: {
      name: "GetWebGlobalSessionId",
      I: GetWebGlobalSessionIdReq,
      O: GetWebGlobalSessionIdRsp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc trpc.servicedesk.servicedesk_proxy.Proxy.KnowledgeSearch
     */
    knowledgeSearch: {
      name: "KnowledgeSearch",
      I: KnowledgeSearchReq,
      O: KnowledgeSearchRsp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc trpc.servicedesk.servicedesk_proxy.Proxy.GetCommonQuestion
     */
    getCommonQuestion: {
      name: "GetCommonQuestion",
      I: GetCommonQuestionReq,
      O: GetCommonQuestionRsp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc trpc.servicedesk.servicedesk_proxy.Proxy.KnowledgeSearchHistory
     */
    knowledgeSearchHistory: {
      name: "KnowledgeSearchHistory",
      I: KnowledgeSearchHistoryReq,
      O: KnowledgeSearchHistoryRsp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc trpc.servicedesk.servicedesk_proxy.Proxy.DelKnowledgeSearch
     */
    delKnowledgeSearch: {
      name: "DelKnowledgeSearch",
      I: DelKnowledgeSearchReq,
      O: DelKnowledgeSearchRsp,
      kind: MethodKind.Unary,
    },
  }
} as const;

