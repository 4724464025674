/**
 * 把 string 转换成富文本格式
 * @param str string 文本字符串
 * @returns 
 */
const IMAGE_TEXT = '【图片】';
const LINK_TEXT = '【超链接】';
const ATTACHMENT_TEXT = '【附件】';

export interface Content {
  type: string
  text?: string
  content?: Content | Content[]
}

const dfs = (root, callback) => {
  const node = root;

  if (callback(node) && node.content) {
    node.content.forEach(n => dfs(n, callback));
  }
};

const hasLinkMark = marks => marks.some(mark => mark.type === 'link');

export const contentFromString = (str: string): Content => {
  const rows = str.split('\n');
  const paragraphs = rows.map(row => ({
    type: 'paragraph',
    content: !row.length ? undefined : [{
      type: 'text',
      text: row,
    }],
  }));

  return {
    type: 'doc',
    content: paragraphs,
  };
};

export const contentToString = (content: Content | Content[]): string => {
  let res = '';
  const dfs = (c: Content | Content[]) => {
    if (c instanceof Array) {
      c.forEach(i => dfs(i))
    } else {
      res += c.text;
    }
  }
  dfs(content);
  return res;
}

export const contentJSONToString = (content, withLinkContent = false, filterParagraph = true) => {
  if(!content) {
    return '';
  }
  // 临时兼容旧数据
  if (content[0] !== '{') {
    return content;
  }

  try {
    const root = JSON.parse(content);
    const result = [];
    dfs(root, node => {
      switch (node.type) {
        case 'image':
          result.push(IMAGE_TEXT);
          break;
        case 'attachment':
          result.push(ATTACHMENT_TEXT);
          break;
        case 'text':
          result.push(hasLinkMark(node.marks || []) ? (withLinkContent ? `[${node.text}](超链接)` : LINK_TEXT) : node.text);
          break;
        case 'paragraph':
          if (filterParagraph) {
            break;
          } else {
            result.push('\n');
            break;
          }
      }
      return true;
    });
    if (filterParagraph) {
      return result.join('');
    } else {
      result[0] === '\n' && result.shift();
      return result.join('');
    }
  } catch {
    return content;
  }
};