<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="w-full h-full flex items-center justify-center">
    <RateComp ref="rateCompRef" :handleRejectTicket="handleRejectTicket" :handleResolveTicket="handleResolveTicket" :bordered="false"></RateComp>
  </div>
</template>
<script lang="ts" setup>
const initRuleConfig = {
  '1': { options: [] },
  '2': { options: [] },
  '3': { options: [] },
  '4': { options: [] },
  '5': { options: [] },
};

import RateComp from '@/components/Rate/index.vue';
import { watch, ref } from 'vue';
import { PartialMessage } from '@bufbuild/protobuf';
import { GetLinkageRuleConfigReq } from '@/gen/product_without_validate_pb';
import { MessagePlugin } from 'tdesign-vue-next';
import { getLinkageRules, getTicketDetail, doAction, queryImActionInfo, uploadImActionInfo } from '@/network/requests';
import { contentJSONToString } from '@/components/richText/utils';
import { useRoute } from 'vue-router';


const route = useRoute();
const { ticket_id, proj_id, prod_id, msg_id } = route.query;
const ticketId = ref(Number(ticket_id));
const projId = ref(Number(proj_id));
const prodId = ref(Number(prod_id));
const msgId = ref(String(msg_id));
const errorMsg = ref('')

const ruleConfig = ref(JSON.parse(JSON.stringify(initRuleConfig)));
const ticketStatus = ref('');

const resolveFormData = ref<{ rate: number | null, tags: string[], reason: string }>({
  rate: null,
  tags: [],
  reason: ''
});

const rateCompRef = ref();
const init = async (cfg: PartialMessage<GetLinkageRuleConfigReq> & { msgId: string }) => {
  if (!ticketId.value || !projId.value) {
    MessagePlugin.error('满意度评价组件缺少payload');
    errorMsg.value = '满意度评价组件缺少payload';
    rateCompRef.value.init({
      errorMsg: errorMsg.value
    })
  }
  // const queryImActionInfoRsp = await queryImActionInfo({
  //   msgId: [cfg.msgId],
  //   projId: cfg.projId
  // })
  try {
    // if (queryImActionInfoRsp.data.length === 0) {
    //   uploadImActionInfo({
    //     projId: projId.value,
    //     ticketId: ticketId.value,
    //     action: 'pre_settlement',
    //     ticketType: 'event',
    //   });
    // }
    // const imAction = queryImActionInfoRsp.data[0];
    // if (imAction.msgId === cfg.msgId && imAction.postBody) {
    //   try {
    //     const imActionObj = JSON.parse(imAction.postBody);

    //     const actionFields = JSON.parse(imActionObj?.actionFields);
    //     if (imActionObj.is_auto) {
    //       rateCompRef.value.init({
    //         errorMsg: errorMsg.value,
    //         settlementData: resolveFormData.value,
    //         ticketStatus: 'auto_settlement',
    //         ticketId: ticketId.value,
    //       })
    //     } else if (actionFields.ticket_status === 'settlement') {
    //       resolveFormData.value = {
    //         rate: actionFields.satisfaction_score,
    //         tags: actionFields.satisfaction_tag_self_define,
    //         reason: contentJSONToString(actionFields.satisfaction_content)
    //       };
    //       rateCompRef.value.init({
    //         errorMsg: errorMsg.value,
    //         settlementData: resolveFormData.value,
    //         ticketStatus: 'settlement',
    //         ticketId: ticketId.value,
    //       })
    //       return;
    //     } else {
    //       rateCompRef.value.init({
    //         errorMsg: errorMsg.value,
    //         settlementData: resolveFormData.value,
    //         ticketStatus: actionFields.ticket_status,
    //         ticketId: ticketId.value,
    //       })
    //       return;
    //     }
    //   } catch (error) {
    //     rateCompRef.value.init({
    //       errorMsg: 'IM ACTION 记录错误',
    //       settlementData: resolveFormData.value,
    //       ticketStatus: ticketStatus.value,
    //       ticketId: ticketId.value,
    //     })
    //     return;
    //   }
    // } else {
      try {
        const ticketDetailRsp = await getTicketDetail({ ticketId: ticketId.value, projId: projId.value });
        if (ticketDetailRsp.code !== 0) errorMsg.value = '获取工单信息失败';
        ticketStatus.value = ticketDetailRsp.status;

        const ticketData = JSON.parse(ticketDetailRsp.data);
        const basicInfo = ticketData.find(i => i.category_name === '基本信息');
        const basicCategoryData = basicInfo?.category_data;
        const isAutoValue = basicCategoryData.find(i => i.eng_name === 'auto_settle').value;
        // ticketId.value = payload.value.ticket_id;

        if (ticketStatus.value === 'settlement') {
          rateCompRef.value.init({
            errorMsg: errorMsg.value,
            settlementData: resolveFormData.value,
            ticketStatus: ticketStatus.value,
            ticketId: ticketId.value,
            is_auto: isAutoValue,
          })
          return;
        } else if (ticketStatus.value === 'rejected' || ticketStatus.value === 'reject_settle') {
          rateCompRef.value.init({
            errorMsg: errorMsg.value,
            settlementData: resolveFormData.value,
            ticketStatus: ticketStatus.value,
            ticketId: ticketId.value,
          })
          return;
        } else if (ticketStatus.value === 'pre_settlement') {
          const rsp = await getLinkageRules(cfg)

          const ruleMapInit = {};
          const ruleConfigInit = JSON.parse(JSON.stringify(initRuleConfig));
          const fulfillRule = rsp.data;
          
          if (fulfillRule) fulfillRule.rules.forEach(r => {
            r.conditions.condition.forEach(cond => {
              if (cond.source === 'satisfaction_score' && cond.operation === 'equal') {
                ruleMapInit[`${r.id}`] = r;
                const value = parseInt(cond.value);
                ruleConfigInit[value] = {
                  ...r.fulfill,
                  id: r.id
                };
                ruleConfigInit[value].options = r.fulfill.options.map(o => {
                  return {
                    satisfactionGroup: o.satisfaction_group,
                    satisfactionTags: o.satisfaction_tags.map(k => {
                      return {
                        label: k,
                        value: parseInt(`${new Date().getTime()}${Math.random() * 999}`),
                        chosen: false,
                      };
                    })
                  };
                });
              }
            });
          });
          ruleConfig.value = ruleConfigInit;

          rateCompRef.value.init({
            errorMsg: errorMsg.value,
            settlementData: resolveFormData.value,
            ticketStatus: ticketStatus.value,
            ticketId: ticketId.value,
            ruleConfig: ruleConfig.value,
          })
        } else {
          errorMsg.value = '工单已流转到其他状态，IT Service继续为您服务！'
          rateCompRef.value.init({
            errorMsg: errorMsg.value,
            settlementData: resolveFormData.value,
            ticketStatus: ticketStatus.value,
            ticketId: ticketId.value,
          })
          return;
        }
      } catch (error) {
        console.log('errorr', error)
        rateCompRef.value.init({
          errorMsg: "网络错误，请重试",
          settlementData: resolveFormData.value,
          ticketStatus: ticketStatus.value,
          ticketId: ticketId.value,
          ruleConfig: ruleConfig.value,
        })
      }
    // }
  } catch (error) {
    MessagePlugin.error('满意度存储错误');
    errorMsg.value = '网络错误，请稍后再试';
    rateCompRef.value.init({
      errorMsg: errorMsg.value
    })
  }
}

watch(() => ({ proj_id: projId.value, prod_id: prodId.value, msg_id: msgId.value }), (val, oldVal) => {
  if (val && val.proj_id) {
    init({
      msgId: val.msg_id,
      projId: val.proj_id,
      prodId: val.prod_id,
      engName: 'satisfaction_tag_self_define',
      ticketType: 'event',
    });
  }
}, {
  immediate: true,
  deep: true
})

const handleResolveTicket = async (evaluationRichText: string, satisfactionScore: number, satisfactionTagSelfDefine: string[]) => {
  return doAction({
    projId: projId.value,
    ticketId: ticketId.value,
    action: 'settle_event',
    ticketType: 'event',
    actionFields: JSON.stringify({
      satisfaction_content: evaluationRichText,
      satisfaction_score: satisfactionScore,
      satisfaction_tag_self_define: satisfactionTagSelfDefine
    })
  })
  // if (doActionRsp.code === 0) {
  //   uploadImActionInfo({
  //     projId: projId.value,
  //     ticketId: ticketId.value,
  //     action: 'settle_event',
  //     ticketType: 'event',
  //     actionFields: JSON.stringify({
  //       satisfaction_content: evaluationRichText,
  //       satisfaction_score: satisfactionScore,
  //       satisfaction_tag_self_define: satisfactionTagSelfDefine
  //     })
  //   })
  // }
};

const handleRejectTicket = async (contentRichText: string) => {
  return doAction({
    projId: projId.value,
    ticketId: ticketId.value,
    action: 'reject_settle_event',
    ticketType: 'event',
    actionFields: JSON.stringify({
      reply_description: contentRichText,
    }),
  })
  // if (doActionRsp.code === 0) {
  //   uploadImActionInfo({
  //     projId: projId.value,
  //     ticketId: ticketId.value,
  //     action: 'reject_settle_event',
  //     ticketType: 'event',
  //     actionFields: JSON.stringify({
  //       reply_description: contentRichText,
  //     }),
  //   })
  // }
};
</script>