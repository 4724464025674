<template>
<div>
  <div :class="`${chat.shouldShowRedPoint.value ? 'bg-floating-icon-new-msg h-[112px]' : 'bg-floating-icon h-[96px]'} w-[48px] bg-contain bg-no-repeat cursor-pointer hover:drop-shadow-md`">
  </div>
</div>
</template>
<script lang="ts" setup>
import { useChatStore } from '@/stores/chat';
import { storeToRefs } from 'pinia';

const chat = storeToRefs(useChatStore());
</script>
