<template>
  <div :class="['message-input-button', !isPC && 'message-input-button-h5', 'flex', 'flex-row', 'items-center']" style="position: absolute; z-index: 9999999; right: 20px; bottom: 24px;">
    <FileUploadNew></FileUploadNew>
    <ImageLoaderNew imageSourceType="camera"></ImageLoaderNew>
    <FlightIcon style="margin-left: 4px;" class="w-[24px] h-[24px] text-iconGray hover:text-primary transition-colors" @click="sendMessage" v-if="props.enableSend"></FlightIcon>
  </div>
</template>
<script setup lang="ts">
// import { TUITranslateService } from '@tencentcloud/chat-uikit-engine';
import { isPC } from '../../../utils/env';
import ImageLoaderNew from '../message-input-toolbar/image-upload/ImageLoaderNew.vue';
import FlightIcon from '@/components/icons/Flight.vue';
import FileUploadNew from '../message-input-toolbar/file-upload/FileUploadNew.vue';

const props = defineProps({
  enableSend: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits(['sendMessage']);

const sendMessage = () => {
  emits('sendMessage');
};
</script>
<style scoped lang="scss">
@import "../../../assets/styles/common";

.message-input-button {
  position: absolute;
  bottom: 22px;
  right: 25px;

  &-h5 {
    position: static;
  }

  &-cont {
    padding: 8px 20px;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    font-weight: 400;
    background: #006eff;
    color: #fff;
    letter-spacing: 0;
    cursor: pointer;
  }

  &-hover {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 120%;
    word-break: keep-all;
    height: 30px;
    white-space: nowrap;
    top: 0;
    bottom: 0;
    margin: auto 0;
    padding: 5px 10px;
    border-radius: 3px;
    background: #000;
    color: #fff;
    opacity: 0.3;

    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      right: -20px;
      border: 10px solid transparent;
      border-left: 10px solid #000;
    }
  }

  &:hover {
    .message-input-button-hover {
      display: flex;
    }
  }
}
</style>
