import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { TUILogin } from '@tencentcloud/tui-core';

export const useChatStore = defineStore('chat', () => {

  /**
   * Only exist after TUIKit.vue mounted lifecycle is invoked.
   * */
  const chatInstance = computed(() => TUILogin.getContext()?.chat);
  const shouldShine = ref(false);
  const shouldShowRedPoint = ref(false);
  const isShowingChatPanel = ref(false);

  return {
    chatInstance,
    shouldShine,
    shouldShowRedPoint,
    isShowingChatPanel,
  }
})
