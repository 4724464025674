import { createRouter, createWebHistory } from 'vue-router'
import RpcExample from '@/views/RpcExample.vue'
import TUIKit from '@/views/TUIKit.vue'
import Search from '@/views/search/index.vue';
import searchHome from '@/views/search/searchHome.vue';
import searchResult from '@/views/search/searchResult.vue';
import RatePage from '@/views/RatePage.vue';
import ChatPage from '@/views/chat/chatPage.vue';

const router = createRouter({
  // @ts-ignore
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: to => {
        return { path: '/search' };
      }
    }, {
      path: '/search',
      name: 'Search',
      redirect:'/search/home',
      component: Search,
      children: [
        { path: 'home', name: 'searchHome', component: searchHome },
        { path: 'result', name: 'searchResult', component: searchResult },
      ]
    }, {
      path: '/ratepage',
      name: 'RatePage',
      component: RatePage
    }, {
      path: '/chat',
      name: 'Chat',
      component: ChatPage
    }
  ]
})

router.afterEach(() => {
  const url = new URL(location.href);
  if (url.searchParams.has("code")) {
    url.searchParams.delete("code")
    window.history.replaceState({}, null, `${url.pathname}?${url.searchParams.toString()}`);
  }
})

export default router
