<template>
  <div class="h-dvh flex flex-col">
    <div class="border-b border-b-gy2 py-3 px-6 bg-blueLight0">
      <div class="w-full flex justify-between items-center">
        <div class="flex justify-center items-center text-white font-bold italic text-xl select-none bg-it-service-logo bg-contain bg-no-repeat w-[121px] h-[24px] cursor-pointer" @click="handleClickLogo"></div>
        <div v-if="!skeletonLoading && !errorType" class="flex justify-center items-center space-x-2">
          <t-dropdown trigger="hover" :options="avatorDropdownOptions" @click="handleAvatorClick">
            <t-button theme="default" variant="text">
              {{ user.userName }}
              <template #icon><t-icon name="user-circle" size="20" /></template>
            </t-button>
          </t-dropdown>
        </div>
        <div v-else class="text-primary cursor-pointer select-none" @click="logout">
          登出
        </div>
      </div>
    </div>
    <div v-if="skeletonLoading || errorType" class="p-8 w-full lg:min-w-[480px] min-h-[480px] flex-1">
      <div class="flex flex-col bg-white rounded-lg h-full">

        <t-loading class="h-full" v-if="skeletonLoading" :loading="skeletonLoading" />

        <div v-else-if="errorType" class="h-full w-full flex justify-center content-center flex-wrap">
          {{ errorType === 'lack_info' ? '缺少用户信息，请联系 IT-Service' : '网络错误，请稍后重试' }}
        </div>

        
      </div>
    </div>
    <RouterView v-else />
  </div>
</template>
<script setup lang="ts">
import { RouterView, useRouter } from 'vue-router'
import { ref, onBeforeMount } from 'vue'
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { StoreName, TUIStore, IConversationModel } from '@tencentcloud/chat-uikit-engine';
import { sendMessages } from './TUIKit/components/TUIChat/utils/sendMessage';
import { useChatStore } from './stores/chat';

const currentConversation = ref<IConversationModel>();
const { shouldShine } = storeToRefs(useChatStore());

TUIStore.watch(StoreName.CONV, {
  currentConversation: (conversation: IConversationModel) => {
    currentConversation.value = conversation;
  },
});

const router = useRouter();
const startInterval = () => {
  setInterval(function() {
    if (shouldShine.value === true) {
      if (/新/.test(document.title) == false) {
        document.title = '您有新消息';    
      } else {
        document.title = 'IT Service';
      }
    } else {
      document.title = 'IT Service';
    }
  }, 500);
  
  window.onfocus = function() {
    shouldShine.value = false;
  };
}
startInterval();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const { login, logout } = userStore;

const skeletonLoading = ref(true);
const code = ref('');
const errorType = ref<'lack_info' | 'network_error' | undefined>();

onBeforeMount(async () => {
  const searchParams = new URLSearchParams(window.location.search);
  code.value = searchParams.get('code');
  try {
    const userInfo = await login(code.value);
    console.log('userInfo', userInfo.chnName, userInfo.helperId, userInfo.projId, userInfo.sdkAppid, userInfo.userSig, userInfo.userName);
    if (!userInfo.chnName
      || !userInfo.helperId
      || !userInfo.projId
      || !userInfo.sdkAppid
      || !userInfo.userSig
      || !userInfo.userName
    ) errorType.value = 'lack_info';
  } catch (error) {
    errorType.value = 'network_error'
  } finally {
    skeletonLoading.value = false;
  }
});

const avatorDropdownOptions = [
  { content: '登出', value: 0 },
  // { content: '发送满意度评价(测试用)', value: 1 }
]
const handleAvatorClick = async (data: { content: string, value: number }) => {
  if (data.value === 0) {
    await logout();
  } else if (data.value === 1) {
    sendMessages([{
      type: 'TIMCustomElem',
      payload: {
        // @ts-ignore
        data: JSON.stringify({
          business_id: 'yst_rate',
          notice_type: 'pre_settle',
          ticket_id: 401936,
          proj_id: 4,
          prod_id: 5
        })
      }
    }], currentConversation.value)
  }
}
const handleClickLogo = () => {
  if (router.currentRoute.value.name === 'Chat') return;
  router.replace('home');
}
</script>
