<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <t-loading class="w-full max-w-[540px] mx-3" :loading="isLoading">
    <div class="min-h-[44px] text-black w-full flex flex-col justify-between bg-white rounded border-solid border-gy2 border-[1px]">
      <template v-if="errorMsg">
        <div class="flex flex-col items-start p-5">
          <div class="text-[0.9rem] font-medium mb-1">满意度回访</div>
          <div class="text-left">{{ errorMsg }}</div>
        </div>
      </template>
      <template v-else-if="ticketStatus === 'pre_settlement'">
        <div v-if="currentTab === 'resolve'" class="flex flex-col items-start p-5 border-b-[1px]">
          <div class="text-[0.9rem] font-medium mb-1">满意度回访</div>
          <div class="text-left">您的工单(ID: {{ ticketId || '-' }})处于待确认结单状态，请您对本次的服务进行评价！如有其他意见，请填写相关反馈 或 <span class="text-primary cursor-pointer underline underline-offset-2" @click="() => { currentTab = 'reject' }">拒绝结单</span></div>
        </div>
        <div v-else class="flex flex-col items-start p-5 border-b-[1px]">
          <div class="text-[0.9rem] font-medium mb-1">满意度回访（拒绝结单）</div>
          <div class="text-left">您的工单(ID: {{ ticketId || '-' }})处于待确认结单状态，请您对本次的服务进行评价！如有其他意见，请填写相关反馈 或 <span class="text-primary cursor-pointer underline underline-offset-2" @click="() => { currentTab = 'resolve' }">确认结单</span></div>
        </div>
        <div>
          <div v-if="currentTab === 'resolve'" class="p-5 text-left">
            <div class="mb-6">
              <div class="text-[0.9rem] font-medium mb-2">满意度级别：<span class="font-normal">{{ rateLevelText }}</span></div>
              <t-rate class="mb-3" v-model="resolveFormData.rate" @change="() => { resolveFormData.tags = []; }"></t-rate>
              <div
                v-if="resolveFormData.rate && ruleConfig[`${resolveFormData.rate}`] && ruleConfig[`${resolveFormData.rate}`]?.options?.length"
                class="flex flex-col gap-2"
              >
                <div v-for="(cfgGroup, index) in ruleConfig[`${resolveFormData.rate}`].options" :key="index">
                  <t-tag v-for="(tag, tagIdx) in cfgGroup.satisfactionTags" :key="tagIdx"
                    class="select-none cursor-pointer mr-2" :theme="tag.chosen ? 'primary' : 'default'"
                    @click="handleClickTag(index, tagIdx, cfgGroup.satisfactionGroup, tag.label)">
                    {{ tag.label }}
                  </t-tag>
                </div>
              </div>
            </div>
            <div class="text-[0.9rem] font-medium mb-2">满意度评价内容（选填）：</div>
            <t-textarea class="mb-3" v-model="resolveFormData.reason" :autosize="{ minRows: 3, maxRows: 5 }"></t-textarea>
            <div class="text-[0.9rem] font-medium">
              <t-button @click="handleSubmit">提交</t-button>
            </div>
          </div>
          <div v-else class="p-5 text-left">
            <div class="text-[0.9rem] font-medium mb-2">拒绝结单理由（必填）：</div>
            <t-textarea class="mb-3" v-model="rejectFormData.reason" :autosize="{ minRows: 3, maxRows: 5 }"></t-textarea>
            <div class="text-[0.9rem] font-medium">
              <t-button @click="handleSubmit">提交</t-button>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="ticketStatus === 'settlement'">
        <div class="flex flex-col items-start p-5 border-b-[1px]">
          <div class="text-[0.9rem] font-medium mb-1">满意度回访</div>
          <div class="text-left">您已完成对工单(ID: {{ ticketId || '-' }})的评价，感谢您的支持！</div>
        </div>
        <div class="flex flex-col items-start p-5">
          <div class="text-[0.9rem] font-medium mb-2">满意度级别：<span class="font-normal">{{ rateLevelText }}</span></div>
          <t-rate disabled class="mb-3" v-model="resolveFormData.rate"></t-rate>

          <div class="mb-3">
            <t-tag v-for="(tag, tagIdx) in resolveFormData.tags" :key="tagIdx"
              class="select-none cursor-pointer primary mr-2"
            >
              {{ tag }}
            </t-tag>
          </div>

          <div class="text-[0.9rem] font-medium mb-2">满意度评价内容（选填）：</div>
          <t-textarea disabled v-model="resolveFormData.reason" :autosize="{ minRows: 3, maxRows: 5 }"></t-textarea>
        </div>
      </template>
      <template v-else-if="ticketStatus === 'rejected' || ticketStatus === 'reject_settle'">
        <div class="flex flex-col items-start p-5">
          <div class="text-[0.9rem] font-medium mb-1">满意度回访</div>
          <div class="text-left">您已拒绝结单(ID: {{ ticketId || '-' }})，请继续与我们的问题处理专员沟通您遇到的问题，感谢您的支持！</div>
        </div>
      </template>
      <template v-else-if="ticketStatus === 'auto_settlement'">
        <div class="flex flex-col items-start p-5">
          <div class="text-[0.9rem] font-medium mb-1">满意度回访</div>
          <div class="text-left">您未对工单(ID: {{ ticketId || '-' }})进行评价，已自动结单，如有问题请发起新的咨询。</div>
        </div>
      </template>
    </div>
  </t-loading>
</template>
<script setup lang="ts">
const initRuleConfig = {
  '1': { options: [] },
  '2': { options: [] },
  '3': { options: [] },
  '4': { options: [] },
  '5': { options: [] },
};

interface IinitArgs {
  errorMsg?: string;
  ticketStatus?: string;
  ticketId?: number | null;
  settlementData?: { rate: number | null, tags: string[], reason: string };
  rejectData?: { reason: string };
  ruleConfig?: any[];
}

import { ref, computed } from 'vue';
import { contentFromString } from '../richText/utils';
import { MessagePlugin } from 'tdesign-vue-next';

interface IProps {
  handleResolveTicket: (evaluationRichText: string, satisfactionScore: number, satisfactionTagSelfDefine: string[]) => Promise<any>,
  handleRejectTicket: (contentRichText: string) => Promise<any>
}
const props = defineProps<IProps>()

const isLoading = ref(true);
const btnLoading = ref(false);
const init = (initArgs: IinitArgs) => {
  if (initArgs.errorMsg) {
    errorMsg.value = initArgs.errorMsg;
    isLoading.value = false;
    return;
  }
  if (!initArgs.ticketId || !initArgs.ticketStatus) return;
  ticketStatus.value = initArgs.ticketStatus;
  ticketId.value = initArgs.ticketId;

  if (initArgs.ticketStatus === 'settlement') {
    resolveFormData.value.rate = Number(initArgs.settlementData.rate);
    resolveFormData.value.tags = initArgs.settlementData.tags;
    resolveFormData.value.reason = initArgs.settlementData.reason;
  } else if (initArgs.ticketStatus === 'pre_settlement') {
    ruleConfig.value = initArgs.ruleConfig;
  }
  isLoading.value = false;
};
defineExpose({
  init
})

const currentTab = ref<'resolve' | 'reject'>('resolve');
const ruleConfig = ref(JSON.parse(JSON.stringify(initRuleConfig)));
const ticketStatus = ref('');
const ticketId = ref<number | null>();
const errorMsg = ref('');

// 结单表单
const resolveFormData = ref<{ rate: number | null, tags: string[], reason: string }>({
  rate: null,
  tags: [],
  reason: ''
});
const rateLevelText = computed(() => {
  switch (resolveFormData.value.rate) {
    case 1:
      return '非常不满意';
    case 2:
      return '不满意';
    case 3:
      return '一般';
    case 4:
      return '满意';
    case 5:
      return '非常满意';
  
    default:
      return '';
  }
})

// 拒绝结单表单
const rejectFormData = ref({
  reason: ''
});

const handleClickTag = (index: number, tagIdx: number, groupName: string, tagVal: string) => {
  const targetIdx = resolveFormData.value.tags.findIndex(i => i === `${groupName}|${tagVal}`)
  if (targetIdx !== -1) {
    resolveFormData.value.tags.splice(targetIdx, 1);
    ruleConfig.value[`${resolveFormData.value.rate}`].options[index].satisfactionTags[tagIdx].chosen = false;
  } else {
    resolveFormData.value.tags.push(`${groupName}|${tagVal}`);
    ruleConfig.value[`${resolveFormData.value.rate}`].options[index].satisfactionTags[tagIdx].chosen = true;
  }
}

// 提交表单
const handleSubmit = async () => {
  const validated = currentTab.value === 'resolve'
    ? !!resolveFormData.value.rate
    : !!rejectFormData.value.reason;
  
  if (validated !== true) {
    MessagePlugin.warning('请选择满意度星级或填写理由~');
    return;
  }

  try {
    btnLoading.value = true;
    if (currentTab.value === 'resolve') {
      const rsp = await props.handleResolveTicket(
        JSON.stringify(contentFromString(resolveFormData.value.reason)),
        resolveFormData.value.rate,
        resolveFormData.value.tags,
      );

      if (rsp.code === 0) ticketStatus.value = 'settlement';
    } else if (currentTab.value === 'reject') {
      const rsp =await props.handleRejectTicket(
        JSON.stringify(contentFromString(rejectFormData.value.reason)),
      )
      if (rsp.code === 0) ticketStatus.value = 'rejected';
    }
    btnLoading.value = false;
  } catch (error) {
    btnLoading.value = false;
    console.log('handleSubmit error', error)
  }
}

</script>
<style scoped>
:deep(.t-tabs__nav-wrap) {
  width: 100%;
}

:deep(.t-tabs__nav-item) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

:deep(.t-tabs__nav--card.t-tabs__nav-item.t-is-active) {
  color: white;
  background-color: #0052D9;
}
</style>
