/**
 * Get odc-tai login url with success callback which redirect to 'redirectUrl'
 * @param redirectUrl 
 * @returns string url
 */
import { getWebGlobalSessionId,refreshGlobalSession,uploadFlow } from '@/network/requests';
import { useUserStore } from '@/stores/user';


// 获取会话ID 并刷新会话id
export const GetWebGlobalSessionId = async (projId) => {
  try {
    const res = await getWebGlobalSessionId({projId})
    if (!res.code && res.sessionId) { 
      refreshGlobalSession({projId})
      return res.sessionId
    }
    return ''
  } catch (error) {
    return ''
  }
}

// 上报数据
export const reportData = async (data) => {
  const userStore = useUserStore();
  if (!userStore.user.projId) return 
  const sessionid = await GetWebGlobalSessionId(userStore.user.projId);
  if(!sessionid)  return
  const postBody = {
    flow_type: 'web_session_operation',
    proj_id: userStore.user.projId,
    data: [
      { key: 'session_id', value: sessionid },
      { key: 'creator', value: userStore.user.userName },
      { key: 'event_at', value: String(new Date().getTime()) },
      ...data
    ]
  }
  uploadFlow(postBody)
}