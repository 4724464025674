<template>
 <div class="relative h-[48px]">
  <div id="search-wrap" :class="['m-0 w-full rounded-[8px] border border-[#D6DBE3] hover:shadow-search-focus', showAutoComplete ? 'shadow-search-focus' : 'shadow-none' ]" @click="showAutoComplete=true">
    <t-input :inputClass="['!px-[12px]', '!h-[48px]', 'hover:!bg-white', '!shadow-none', '!border-none','rounded-t-[8px]',
      showAutoComplete ? 'rounded-b-[0px]' : 'rounded-b-[8px]']"
      clearable v-model.trim="searchValue" size="large" placeholder="请描述你的问题" @clear="searchValue=''" style="width: 100%" @focus="refreshHistory">
      <template #prefixIcon>
        <t-icon :class="`cursor-pointer hover:!text-primary transition-all ${props.hasResult && '!text-primary'}`" name="search" size="20px" @click="getSearchResult" />
      </template>
    </t-input>
    <div :class="['absolute left-[0px] z-[4000] shadow-lg bg-white w-full px-[12px] text-[#00000066] text-[14px] transition-[height] border-[1px] border-[#E3E6EB] duration-[1000ms] linear rounded-b-[8px]', showAutoComplete ? 'h-auto' : 'hidden']">
      <div v-if="historyList && historyList.length" class="py-[12px]">
        <div v-for="(item, index) in historyList" :key="index" @click.stop="clickSearch(item.question)"
        class="group h-[36px] cursor-pointer pl-[6px] flex justify-between items-center hover:bg-[#F1F2F5]">
          <div class="flex items-center py-[7px]" id="history-item">
            <t-icon name="search" class="mr-[12px]" size="18"></t-icon>
            <span class="flex-1 max-w-[525px] text-ellipsis overflow-hidden whitespace-nowrap">
              {{item.question}}
            </span>
          </div>
          <div class="px-[10px] h-full leading-[36px]"  @click.stop="DelKnowledgeSearch(item.id)">
            <t-icon name="close-circle-filled"  class="invisible group-hover:visible" size="18"></t-icon>
          </div>
        </div>
        <div class="text-right">
          <t-button variant="text" class="text-[#00000066]" @click="clearHistory">
            <template v-slot:icon>
              <t-icon name="delete" />
            </template>
            清空</t-button>
        </div>
      </div>
      <div v-else class="h-[48px] pl-[6px] leading-[48px]">暂无搜索记录</div>
    </div>
  </div>
 </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted,ref ,watch} from 'vue'; 
import { MessagePlugin } from 'tdesign-vue-next';
import { knowledgeSearchHistory,delKnowledgeSearch } from '@/network/requests';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

const props = defineProps<{
  defaultKeyword: string,
  hasResult: boolean
}>();

interface history {
  question: string;
  id: number;
}

const emit = defineEmits(['search']);

watch(() => props.defaultKeyword, (val) => {
  if(val) hasChangeKeyword.value=true
});

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const SearchwrapElement=ref<any>(null)
const showAutoComplete = ref(false);
const hasChangeKeyword = ref(true);
const searchValue = ref('');

const historyList=ref<history[]>([])

const clickSearch = (history:string) => {
  searchValue.value = history;
  getSearchResult()
};

const refreshHistory = () => {
  showAutoComplete.value = true;
  if(hasChangeKeyword.value) GetKnowledgeSearchHistory()
};

const listenEnter = (event) => { 
  if (event.keyCode === 13) {
    event.preventDefault();
    getSearchResult();
  }
}

// 获取搜索记录
const GetKnowledgeSearchHistory = () => {
  knowledgeSearchHistory( {projId:user.value.projId,pageNumber:1,pageSize:10}).then((res) => {
    if (!res.code && res.data) {
      historyList.value = res.data || [];
    } else historyList.value = [];
  }).finally(() => { hasChangeKeyword.value=false})
}

// 删除/清空搜索记录
const DelKnowledgeSearch = (delQuestionId: number | null, isDelAll = false) => {
  const parmas = { projId: user.value?.projId, delQuestionId, isDelAll }
  if(String(delQuestionId)==='null') delete parmas.delQuestionId;
  delKnowledgeSearch(parmas).then(() => {
    if(!isDelAll) GetKnowledgeSearchHistory()
  })
}

// 处理搜索路由跳转
const getSearchResult = async() => {
  if (!searchValue.value.trim()) { 
    MessagePlugin.warning('搜索内容不能为空');
    return;
  };
  showAutoComplete.value = false;
  emit('search',searchValue.value)
};

const clearHistory = () => {
  historyList.value = [];
  DelKnowledgeSearch(null,true)
  showAutoComplete.value = false;
};

const clickWindow = (e: any) => {
  if (SearchwrapElement.value&&!SearchwrapElement.value.contains(e.target)) { 
    showAutoComplete.value = false; 
  }
};

onMounted(() => {
  if(props.defaultKeyword) searchValue.value = props.defaultKeyword;
  SearchwrapElement.value=document.getElementById('search-wrap')
  document.addEventListener('click', clickWindow);
  SearchwrapElement.value.addEventListener('keydown', listenEnter);
});

onUnmounted(() => { 
  document.removeEventListener('click', clickWindow);
})

</script>
