import axios from "axios";
import * as _ from 'lodash';
import api from "./api";
import { MessagePlugin } from "tdesign-vue-next";
import { useUserStore } from "@/stores/user";

const axiosInstance = axios.create({
  baseURL: '/servicedesk-api'
});

const offShoreServices = ['/trpc.servicedesk.servicedesk_proxy'];
const isOffShoreRequest = (url: string) => offShoreServices.some(i => url.includes(i))

axiosInstance.interceptors.request.use((request) => {
  if (!isOffShoreRequest(request.url)) {
    const [_space, serviceString, method] = request.url.split('/');
    let interfaceName = method;
    request.data = _.mapKeys(request.data, (v, k) => _.snakeCase(k));
    if (!['post', 'POST'].includes(request.method)) {
      // const params = new URLSearchParams(request.params);
      // // @ts-ignore
      // params.keys().forEach(key => {
      //   if (params.get(key) === 'undefined') params.delete(key);
      // });
      // const snakeParams = new URLSearchParams();
      // params.forEach((val, key) => {
      //   snakeParams.append(_.snakeCase(key), val);
      // });
      // const paramString = snakeParams.toString();
     
      // 兼容qq浏览器
      const snakeParams = new URLSearchParams();
      const params = Object.keys(request.params);
      params.forEach((item) => {
        if (item && item !== 'undefined') snakeParams.append(_.snakeCase(item), request.params[item]);
      });
      const paramString = snakeParams.toString();
      // 兼容qq浏览器
      interfaceName = paramString ? `${method}?${paramString}` : method;
    }
   
    request.data = ['post', 'POST'].includes(request.method) ? {
      service: serviceString,
      method: 'POST',
      interface_name: interfaceName,
      post_body: JSON.stringify(request.data),
    } : {
      service: serviceString,
      method: 'GET',
      interface_name: interfaceName,
    };

    request.url = api.proxy;
    request.method = 'POST';
  }
  request.params = { 
    ...request.params,
    // @ts-ignore
    env: import.meta.env.VITE_APP_ENV,
  };
  const userStore = useUserStore();
  if (userStore.user.projId) request.params = { ...request.params, proj_id: userStore.user.projId }
  return request;
}, (error) => {
  console.log('Request Proxy Error: ', error);
});

axiosInstance.interceptors.response.use((response) => {
  if(response.status === 200 && response.data?.code === 15999) {
    window.location.replace(response.data?.data?.url || 'https://test-odc.it.woa.com/odc-login/login');
  }
  if (response.config.url.includes('DoRequest') && response.data.rsp) {
    const outerRsp = JSON.parse(response.data.rsp);
    const parsedData = outerRsp;
    response = parsedData
    // @ts-ignore
    response = _.mapKeys(response, (v, k) => _.camelCase(k));
  }
  // @ts-ignore
  if (response.code && response.code !== 0) MessagePlugin.error(`Network Error: ${response.code}, ${response.msg}`);
  return response;
}, (error) => {
  return Promise.reject(error);
});

export default axiosInstance;