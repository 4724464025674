import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import { transportKey } from './keys'

import TDesign from 'tdesign-vue-next'
import 'tdesign-vue-next/es/style/index.css'
import './style.css'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(TDesign)

/**
 * Provide Transport with baseUrl and interceptors
 */
import defaultTransport from '@/network/services/transport'
app.provide(transportKey, defaultTransport)

app.mount('#app')
