/**
 * Expand needed RPC methods here
 * Use function decorator to catch 401 error then redirect
 * Use transport interceptor to redirect normal yst request to proxy service 
 */
import proxyClient from '@/network/services/proxyService';
import productClient from '@/network/services/productService';

const RpcService = {
  login: proxyClient.login,
  logout: proxyClient.logOff,
  proxyOaRequest: proxyClient.doRequest,
  getLinkageRuleConfig: productClient.getLinkageRuleConfig,
}

export default RpcService;
