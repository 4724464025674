<!-- eslint-disable vue/multi-word-component-names -->
<template>
<div
  style="width: calc(100% - 4px); margin-left: 2px;"
  :class="`rounded-[7px] transition-height duration-500 ease-in ${isSearching || !exactMatch ? 'h-0' : isFakeSearching ? 'myshadow min-h-[96px] p-[24px]' : 'myshadow_without_animation min-h-[96px] p-[24px]'}`"
>
  <div v-if="!isSearching && exactMatch" class="bg-stars w-[64px] h-[64px] absolute top-[16px] right-[16px]"></div>
  <div v-if="!isSearching && exactMatch && isFakeSearching" style="color: transparent;">
    <div class="loading bkg-transport-colorful">小G正在思考中</div>
  </div>
  <div class="w-full" v-else-if="!isSearching && exactMatch && !isFakeSearching">
    <div class="flex flex-col items-start">
      <div class="text-[16px] font-medium mb-[16px]">小G为你智能解答：</div>
      <div :class="`overflow-hidden ${!isExpand && 'max-h-[342px]'}`">
        <div ref="contentRef" class="text-[14px] font-normal" v-html="marked(answerString, { renderer })"></div>
        <div v-if="shouldShowExpandBtn && !isExpand" class="absolute bottom-[81px] right-[0px] h-[72px] w-full" style="background-image: linear-gradient(-180deg,rgba(255,255,255,0) 0%,#fff 100%)"></div>
        <t-button v-if="shouldShowExpandBtn" class="absolute bottom-[85px] right-[28px] text-[#00000099]" shape="round" variant="outline" @click="handleExpandBtn">
          {{ expandBtnText }}
          <template v-slot:suffix>
            <t-icon :name="isExpand ? 'chevron-up' : 'chevron-down'"></t-icon>
          </template>
        </t-button>
      </div>
    </div>
    <t-divider class="my-[16px]"></t-divider>
    <div class="flex flex-row justify-between h-[24px]">
      <div class="flex items-center text-[12px]">
        <div v-if="thumbStatus === ''" class="text-[#00000066]">我们非常期待您的反馈：</div>
        <div v-else-if="thumbStatus === 'up'" class="text-[#00A870] flex flex-row gap-[8px] justify-center items-center"><div class="w-[16px] h-[16px] bg-check-circle-filled bg-contain"></div>感谢您的肯定</div>
        <div v-else-if="thumbStatus === 'down' && hasSubmitDrawbacks" class="text-[#00A870] flex flex-row gap-[8px] justify-center items-center"><div class="w-[16px] h-[16px] bg-check-circle-filled bg-contain"></div>感谢您的帮助让我们变得更好</div>
        <div v-else class="flex flex-row gap-[8px]">
          <t-button v-for="(item, index) in drawbacks" :key="index" theme="default" :style="`${choosenDrawbacks.has(item.label) ? 'color: #0052D9; background-color: #D9E8FF; border-color: #D9E8FF;' : 'color: #00000066; background-color: #E9EBEF;'}`" @click="handleClickDrawbacks(item.label)">
            {{ item.label }}
          </t-button>
        </div>
      </div>
      <div class="flex flex-row">
        <t-button v-if="thumbStatus === 'down' && !hasSubmitDrawbacks" :disabled="choosenDrawbacks.size === 0" style="margin-right: 16px;" @click="handleSubmitDrawbacks" :loading="submitingDrawbacks">
          提交
        </t-button>
        <span class="flex flex-row items-center h-[32px]">
          <ThumbUpFillIcon v-if="thumbStatus === 'up'" class="w-[24px] h-[24px] mr-[8px] text-primary cursor-pointer" @click="handleClickThunb('up')"></ThumbUpFillIcon>
          <ThumbUpIcon v-else class="w-[24px] h-[24px] mr-[8px] text-iconGray hover:text-primary cursor-pointer" @click="handleClickThunb('up')"></ThumbUpIcon>
          <ThumbDownFillIcon v-if="thumbStatus === 'down'" class="w-[24px] h-[24px] text-primary cursor-pointer" @click="handleClickThunb('down')"></ThumbDownFillIcon>
          <ThumbDownIcon v-else class="w-[24px] h-[24px] text-iconGray hover:text-primary cursor-pointer" @click="handleClickThunb('down')"></ThumbDownIcon>
        </span>
      </div>
    </div>
  </div>
</div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { marked } from 'marked';
import { knowledgeChat, qaFeedback } from '@/network/requests';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { reportData } from '@/utils/utils'
import { useRoute } from 'vue-router';
import ThumbUpIcon from '@/components/icons/ThumbUp.vue'
import ThumbUpFillIcon from '@/components/icons/ThumbUpFill.vue'
import ThumbDownIcon from '@/components/icons/ThumbDown.vue'
import ThumbDownFillIcon from '@/components/icons/ThumbDownFill.vue'

const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const route = useRoute();
const isSearching = ref(true);
const isFakeSearching = ref(true);
const exactMatch = ref(false);
const contentRef = ref<HTMLDivElement>();
const contentHeight = computed(() => contentRef.value?.scrollHeight);
const isExpand = ref(false);
const shouldShowExpandBtn = ref(false);
watch(() => contentHeight.value, (val, oldVal) => {
  if (val && val > 342) {
    shouldShowExpandBtn.value = true;
  } else {
    shouldShowExpandBtn.value = false;
  }
});
const event_type = computed(() => (route.query&&route.query.from) ? `search_${route.query.from}_search` : 'search_result_search');
const handleExpandBtn = () => { isExpand.value = !isExpand.value; };
const expandBtnText = computed(() => isExpand.value ? '收起' : '展开')
const answerObj = ref<null | { answerType: string, answer: string, msg_id: string }>(null);
const answerString = ref('');
const renderer = new marked.Renderer();
const linkRenderer = renderer.link;
// @ts-ignore
renderer.link = (href, title, text) => {
  const html = linkRenderer.call(renderer, href, title, text);
  return html.replace(/^<a /, '<a style="color: #0052D9;" target="_blank" ');
};

// 点👍 & 点👎
const thumbStatus = ref<'' | 'up' | 'down'>('');
const handleClickThunb = (val: 'up' | 'down') => {
  if (val === thumbStatus.value) {
    handleSubmitThumbState('');
    thumbStatus.value = '';
  } else {
    handleSubmitThumbState(val);
    thumbStatus.value = val;
  }
  hasSubmitDrawbacks.value = false;
  choosenDrawbacks.value.clear();
};

// 点👎后按钮
const handleClickDrawbacks = (val) => {
  if (choosenDrawbacks.value.has(val)) {
    choosenDrawbacks.value.delete(val);
  } else {
    choosenDrawbacks.value.add(val);
  }
};
const drawbacks = ref([
  {
    label: '不准确',
    value: 1,
  }, {
    label: '答案不全面',
    value: 2,
  }, {
    label: '看不懂',
    value: 3,
  }, {
    label: '与问题无关',
    value: 4,
  }, 
]);
const choosenDrawbacks = ref(new Set<string>());
const hasSubmitDrawbacks = ref(false);
const submitingDrawbacks = ref(false);
const handleSubmitDrawbacks = () => {
  submitingDrawbacks.value = true;
  // TODO 网络请求
  qaFeedback({
    msg_id: answerObj.value.msg_id,
    helpful: 2,
    answer_feedback: Array.from(choosenDrawbacks.value)
  });
  hasSubmitDrawbacks.value = true;
  submitingDrawbacks.value = false;
  // TODO 点踩上报
  reportData( [
    { key: 'event_type', value: 'search_result_not_helpful' },
    { key: 'event_content', value: '搜索结果页-点踩' },
    { key: 'feedback', value: '2' },
    { key: 'question', value: route.query.keyword || '' },
    // 待补充value值
    { key: 'answer', value: answerString.value },
    { key: 'answer_feedback', value: JSON.stringify(Array.from(choosenDrawbacks.value)) }
  ])
};
const handleSubmitThumbState = async (state: 'up' | 'down' | '') => {
  // TODO 点赞上报；
  const stateNumber = state === '' ? 0
    : state === 'up' ? 1
    : 2;
  const stateEvent = state === '' ? 'search_result_helpful'
    : state === 'up' ? 'search_result_helpful'
    : '';
  try {
    await qaFeedback({
      msg_id: answerObj.value.msg_id,
      helpful: stateNumber,
    })
    if (stateEvent !== '') {
      reportData([
        { key: 'event_type', value: stateEvent },
        { key: 'event_content', value: stateEvent === 'search_result_helpful' ? '搜索结果页-点赞' : '搜索结果页-点踩' },
        { key: 'feedback', value: String(stateNumber) },
        { key: 'question', value: route.query.keyword || '' },
        { key: 'answer', value: answerString.value },
      ])
    }
  } catch (error) {
    console.log('error');
  }
}

// Exposed methods
interface IInitCfg {
  question: string;
}
const init = async (cfg: IInitCfg) => {
  // TODO initialize vars
  initState();
  isSearching.value = true;
  await getAnswer(cfg.question);
  isSearching.value = false;
}
const initState = () => {
  isSearching.value = true;
  isFakeSearching.value = true;
  exactMatch.value = false;
  isExpand.value = false;
  answerObj.value = null;
  answerString.value = '';
  thumbStatus.value = '';
  hasSubmitDrawbacks.value = false;
  choosenDrawbacks.value.clear();
}
const getAnswer = async (q: string) => {
  // TODO 网络请求
  const chatRsp = await knowledgeChat({
    user: user.value.userName,
    query: q,
    projId: user.value.projId
  })
  if (chatRsp.data && chatRsp.data.length > 0) {
    exactMatch.value = true;
    // @ts-ignore
    answerObj.value = chatRsp.data[0];
    answerString.value = chatRsp.data[0].answer.replaceAll('\n', '\n\n');
  }
  // TODO 搜索结果上报；
  reportData([
    { key: 'event_type', value: `${event_type.value}_robot` },
    { key: 'event_content', value: `搜索${event_type.value.includes('home') ? '首页' : '结果页'}-进行搜索(机器人)` },
    { key: 'question', value: route.query.keyword || '' },
    // 待补充value值
    { key: 'answer', value: answerString.value },
  ])
  setTimeout(() => {
    isFakeSearching.value = false;
  }, 1000);
}
defineExpose({
  init,
});

</script>
<style lang="css" scoped>
.myshadow_without_animation {
	display: flex;
	justify-content: start;
	position: relative;
	background: white;
  transform-style: preserve-3d;
}
.myshadow_without_animation:before,
.myshadow_without_animation:after {
  border-radius: 8px;
	content: "";
	position: absolute;
	top: -2px;
	left: -2px;
	background: linear-gradient(
		134deg,
		#FE8E03,
    #20E592,
    #358FFC,
    #61ADFF
	);
	background-size: 100%;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	transform: translateZ(-1px);
}

.myshadow {
	display: flex;
	justify-content: start;
	position: relative;
	background: white;
  transform-style: preserve-3d;
}
.myshadow:before,
.myshadow:after {
  border-radius: 8px;
	content: "";
	position: absolute;
	top: -2px;
	left: -2px;
	background: linear-gradient(
		134deg,
    #61ADFF,
    #358FFC,
		#20E592,
		#FE8E03,
    #61ADFF,
		#358FFC,
		#20E592,
		#FE8E03
	);
	background-size: 400%;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	transform: translateZ(-1px);
	animation: animate 20s linear infinite;
}

.myshadow:after {
	filter: blur(6px);
}

@keyframes animate {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}
.colorful-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "PingFang SC";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: rgba(NaN,NaN,NaN,1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.loading::after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: '';
}

@keyframes dotty {
  0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
}

.bkg-transport-colorful {
  background: linear-gradient(
		45deg,
    #61ADFF,
    #358FFC,
		#20E592,
		#FE8E03,
    #61ADFF,
		#358FFC,
		#20E592,
		#FE8E03
	);
  background-size: 400%;
  animation: animate 20s linear infinite;
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0.2);
}
</style>
